import useSWR, { mutate as globalMutate } from 'swr'

import { Property } from '../../types'
import { PropertyDataModel } from '../../models'
import { routes } from '../constants'
import { updateProperty } from '../updateProperty'
import { useUser } from '../useUser'

type PropertyApiLoading = {
  property: undefined
  isLoading: true
  error: undefined
  updateProperty: undefined
}

type PropertyApiError = {
  property: undefined
  isLoading: false
  error: Error
  updateProperty: undefined
}

type PropertyApiResolved = {
  property: PropertyDataModel
  isLoading: false
  error: undefined
  updateProperty: (
    id: number,
    property: Property,
    recalculate_expenditures?: boolean
  ) => Promise<Property | undefined>
}

type PropertyApi = PropertyApiLoading | PropertyApiError | PropertyApiResolved

export const useProperty = (): PropertyApi => {
  const { user, error: errorLoadingUser } = useUser()

  const { data, error, mutate } = useSWR<Property>(
    user?.property_id ? routes.property(user.property_id) : null
  )

  const updatePropertyWrapper = async (
    id: number,
    property: Property,
    recalculate_expenditures?: boolean
  ) => {
    const updatedProperty = await mutate(
      updateProperty(id, property, recalculate_expenditures),
      {
        revalidate: false,
      }
    )

    await globalMutate((key: string) => key.startsWith('/api/projects/'))

    return updatedProperty
  }

  if (data) {
    return {
      property: new PropertyDataModel(data),
      isLoading: false,
      error: undefined,
      updateProperty: updatePropertyWrapper,
    }
  } else if (error || errorLoadingUser) {
    return {
      property: undefined,
      isLoading: false,
      error: error || errorLoadingUser,
      updateProperty: undefined,
    }
  } else {
    return {
      property: undefined,
      isLoading: true,
      error: undefined,
      updateProperty: undefined,
    }
  }
}
