import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { ROUTES } from '../../routes'
import { signIn } from '../../api'
import { useToast } from '../../context'
import { Button, MaxWidth, PrimaryLogo } from '../../components'

import './ActionPlanRequestConfirmationPage.scss'

export const ActionPlanRequestConfirmationPage: React.FC = () => {
  const { setSuccessToast } = useToast()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [email, setEmail] = useState('')

  useEffect(() => {
    setEmail(`${params.get('email')}`)
  }, [params])

  const onReturnToHomePage = () => navigate(ROUTES.root)

  const onResendInstructions = async () => {
    try {
      await signIn(email)
    } catch {
      // We always want to render this toast, even if the email can't be found
      // eslint-disable-next-line no-empty
    } finally {
      setSuccessToast(`Instructions have been resent to ${email}`)
    }
  }

  return (
    <MaxWidth wide>
      <div className="action-plan-request-confirmation-page">
        <PrimaryLogo
          className="action-plan-request-confirmation-page__logo"
          mode="light"
        />

        <div className="action-plan-request-confirmation-page__title-text">
          <h2 className="action-plan-request-confirmation-page__text">
            Check your inbox for the keys to your{' '}
            <span className="action-plan-request-confirmation-page__text action-plan-request-confirmation-page__text--green">
              Action Plan!
            </span>
          </h2>

          <p className="action-plan-request-confirmation-page__text">
            It&apos;s time to power your home, your way! Good for your wallet,
            and great for the environment!
          </p>
        </div>

        <div className="action-plan-request-confirmation-page__instructions">
          <p>
            An email has been sent to:
            <br aria-hidden />
            <span className="action-plan-request-confirmation-page__email-address">
              {email}
            </span>
          </p>

          <p>with login instructions for your account.</p>

          <Button onClick={onReturnToHomePage}>Return to the Homepage</Button>

          <p className="action-plan-request-confirmation-page__resend-instructions">
            Didn&apos;t get an email?{' '}
            <Button onClick={onResendInstructions} variant="transparent" small>
              Resend Instructions
            </Button>
          </p>

          {params.get('helperLink') && (
            <a href={params.get('helperLink') || '#'}>
              View Action Plan (DEV HELPER LINK)
            </a>
          )}
        </div>
      </div>
    </MaxWidth>
  )
}
