import React from 'react'
import { Outlet } from 'react-router-dom'

import { useScrollReset } from '../../../hooks'
import { Footer } from '../../Footer'
import { MaxWidth } from '../../MaxWidth'

import './ContentLayout.scss'

type Props = {
  Header?: React.FC
}

export const ContentLayout: React.FC<Props> = ({ Header }) => {
  useScrollReset()

  return (
    <div className="content-layout">
      {Header && <Header />}

      <MaxWidth>
        <main className="content-layout__body">
          <Outlet />
        </main>
      </MaxWidth>

      <Footer />
    </div>
  )
}
