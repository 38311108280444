import React from 'react'

import {
  GRIDLY_CARBON_IMPACT_DESCRIPTIONS,
  GRIDLY_PROJECT_SLUGS,
} from '../../../constants'
import { formatDollars } from '../../../helpers'
import { useProject, useProperty } from '../../../api'
import { Project } from '../../../components'
import { LinkButton } from '../../../components/LinkButton'

export const EMPORIA_GRIDLY_HOME_USAGE_MONITOR_MARKETPLACE_LINK =
  'https://shop.emporiaenergy.com/discount/GridlyHomeEnergy?redirect=/products/gen-2-emporia-vue-with-16-sensors-bundle'

export const HomeMonitoringPage: React.FC = () => {
  const {
    error: errorLoadingProperty,
    isLoading: isLoadingProperty,
    property,
  } = useProperty()
  const {
    error: errorLoadingProject,
    isLoading: isLoadingProject,
    project,
  } = useProject(GRIDLY_PROJECT_SLUGS.WHOLE_HOME_MONITORING)

  if (errorLoadingProperty || errorLoadingProject) {
    return (
      <>Error {errorLoadingProject?.message || errorLoadingProject?.message}</>
    )
  }

  if (isLoadingProperty || isLoadingProject) {
    return <>Loading...</>
  }

  return (
    <>
      <Project.Header
        title="Whole Home Monitoring"
        recommendationText="Install a simple electricity usage monitor to gain energy management insights."
        whyText="Reduce total electricity usage by 5-7% when utilizing app management features."
      >
        <Project.Highlight
          takeAway="7%"
          description="Reduce your electricity usage by up to 7%.*"
          takeAwayStyle="solid"
        />

        <Project.Highlight
          takeAway={formatDollars(parseFloat(project.annual_savings ?? '0'), {
            fractionDigits: 1,
            format: 'shorthand',
          })}
          description={`Save up to ${formatDollars(
            parseFloat(project.annual_savings ?? '0'),
            { fractionDigits: 0 }
          )} in energy costs per year.*`}
          takeAwayStyle="outline"
        />

        {project.carbon_impact && (
          <Project.Highlight
            description={
              GRIDLY_CARBON_IMPACT_DESCRIPTIONS[project.carbon_impact]
            }
            takeAway={project.carbon_impact}
            takeAwayStyle="outline"
          />
        )}
      </Project.Header>

      <Project.Recommendation
        title="Purchase Top-Brand Electricity Monitor"
        propertyIsEligible={property.isEligibleForWholeHomeMonitoring}
        project={project}
        exploreLink={
          <LinkButton
            to={EMPORIA_GRIDLY_HOME_USAGE_MONITOR_MARKETPLACE_LINK}
            variant="blue"
          >
            Explore Your Options
          </LinkButton>
        }
      />

      <Project.Education project={project} />
    </>
  )
}
