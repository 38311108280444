import React from 'react'
import classnames from 'classnames'
import {
  useAccordion,
  useAccordionProvider as useAccordionProviderValue,
  AccordionProvider,
} from '@szhsin/react-accordion'

import './Wrapper.scss'

interface Props extends React.ComponentProps<'div'> {
  header: string
}

export const Wrapper: React.FC<Props> = ({
  className,
  children,
  header,
  ...props
}) => {
  const accordionProviderValue = useAccordionProviderValue({
    allowMultiple: true,
    transition: true,
    transitionTimeout: 200,
  })

  const { accordionProps } = useAccordion()

  return (
    <AccordionProvider value={accordionProviderValue}>
      <div
        className={classnames(className, 'accordion-wrapper')}
        {...props}
        {...accordionProps}
      >
        <h6 className="accordion-wrapper__header">{header}</h6>

        <div className="accordion-wrapper__content" {...accordionProps}>
          {children}
        </div>
      </div>
    </AccordionProvider>
  )
}
