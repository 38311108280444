import React from 'react'
import classnames from 'classnames'

import './Tile.scss'

interface Props extends React.PropsWithChildren {
  className?: string
  shadow?: boolean
}

export const Tile: React.FC<Props> = ({
  className,
  children,
  shadow = true,
}) => (
  <div
    className={classnames(className, 'tile', { 'tile--with-shadow': shadow })}
  >
    {children}
  </div>
)
