import React from 'react'
import { Outlet } from 'react-router-dom'

import { useProperty } from '../../api'
import { MaxWidth, TabNav } from '../../components'
import './EditPropertyTabNavigator.scss'

export const EditPropertyTabNavigator: React.FC = () => {
  const { error, isLoading, property } = useProperty()

  if (error) return <>Error: {error}</>
  if (isLoading) return <>Loading...</>

  return (
    <MaxWidth>
      <div className="edit-property-tab-navigator">
        <div className="edit-property-tab-navigator__heading-wrapper">
          <h5 className="edit-property-tab-navigator__heading">
            My Home Details
          </h5>

          <h3 className="edit-property-tab-navigator__address-line">
            {property.formattedSingleLineAddress}
          </h3>
        </div>

        <div className="edit-property-tab-navigator__tabs-wrapper">
          <TabNav />
        </div>

        <Outlet />
      </div>
    </MaxWidth>
  )
}
