import React from 'react'

import { PropertyFormValues } from '../../../types'
import { useToast } from '../../../context'
import {
  GRIDLY_CARBON_IMPACT_DESCRIPTIONS,
  GRIDLY_PROJECT_SLUGS,
} from '../../../constants'
import { Project, RenewableEnergyForm, RenewablePie } from '../../../components'
import { useProject, useProperty } from '../../../api'
import { formatDollars } from '../../../helpers'
import { ROUTES } from '../../../routes'
import { LinkButton } from '../../../components/LinkButton'

export const RenewableEnergyPage: React.VFC = () => {
  const { setSuccessToast, setErrorToast } = useToast()
  const {
    error: errorLoadingProperty,
    isLoading: isLoadingProperty,
    property,
    updateProperty,
  } = useProperty()
  const {
    error: errorLoadingProject,
    isLoading: isLoadingProject,
    project,
  } = useProject(GRIDLY_PROJECT_SLUGS.RENEWABLE_ENERGY)

  if (errorLoadingProperty || errorLoadingProject) {
    return (
      <>Error {errorLoadingProject?.message || errorLoadingProject?.message}</>
    )
  }

  if (isLoadingProperty || isLoadingProject) {
    return <>Loading...</>
  }

  const handleSubmit = async (propertyData: PropertyFormValues) => {
    if (property.id) {
      try {
        await updateProperty(property.id, propertyData)

        setSuccessToast()
      } catch (e) {
        setErrorToast()

        throw e
      }
    }
  }

  return (
    <>
      <Project.Header
        title="Purchase Renewable Energy from Supplier"
        recommendationText={`Convert from ${property.electricUtilityVendor} to 100% renewable electricity.`}
        whyText="Eliminate fossil fuels from your electricity supply. No upfront costs to enroll."
      >
        <Project.Highlight
          description="Enroll today. It only takes about 8 minutes."
          takeAway="8min"
          takeAwayStyle="solid"
        />

        {project.annual_savings && (
          <Project.Highlight
            description={`Save up to ${formatDollars(
              parseFloat(project.annual_savings),
              { fractionDigits: 0, format: 'longhand' }
            )} in energy costs per year.*`}
            takeAway={formatDollars(parseFloat(project.annual_savings), {
              fractionDigits: 1,
              format: 'shorthand',
            })}
            takeAwayStyle="outline"
          />
        )}

        {project.carbon_impact && (
          <Project.Highlight
            description={
              GRIDLY_CARBON_IMPACT_DESCRIPTIONS[project.carbon_impact]
            }
            takeAway={project.carbon_impact}
            takeAwayStyle="outline"
          />
        )}

        <Project.Highlight
          description={`Increase your renewable energy from ${property.data.total_utility_percent_renewable}% to ${project.future_total_utility_percent_renewable}%`}
          takeAway={
            <RenewablePie
              percentRenewable={parseInt(
                project.future_total_utility_percent_renewable || '0'
              )}
            />
          }
        />
      </Project.Header>

      <Project.Sandbox title="We'll help you pick the best renewable electricity plan.">
        <RenewableEnergyForm
          onSubmit={handleSubmit}
          initialValues={property.toFormValues()}
        />
      </Project.Sandbox>

      <Project.Recommendation
        title="Gridly Renewable Electricity Marketplace"
        propertyIsEligible={property.isEligibleForRenewableEnergy}
        exploreLink={
          <LinkButton
            to={
              ROUTES.projectExplorePages[GRIDLY_PROJECT_SLUGS.RENEWABLE_ENERGY]
            }
            variant="blue"
          >
            Explore Your Options
          </LinkButton>
        }
        project={project}
      />

      <Project.Education project={project} />
    </>
  )
}
