import { useContext } from 'react'

import { ToastContext } from '../ToastContext'

export const useToast = () => {
  const toast = useContext(ToastContext)

  if (!toast) throw new Error('useToast may only be used within a ToastContext')

  return toast
}
