import React from 'react'

import './ResponsiveText.scss'

type Props = {
  mobile?: string
  desktop?: string
}

export const ResponsiveText: React.VFC<Props> = ({ mobile, desktop }) => (
  <>
    {mobile && <span className="responsive-text-mobile">{mobile}</span>}
    {desktop && <span className="responsive-text-desktop">{desktop}</span>}
  </>
)
