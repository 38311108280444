import React from 'react'

import { RenewableEnergyPlan } from '../../types'
import { Tile } from '../Tile'

import './RenewableEnergyPlanCard.scss'

interface Props {
  plan: RenewableEnergyPlan
  formattedCurrentMonthlyUsage: string
  formattedCurrentMonthlySpend: string
  formattedPlanMonthlySpend: string
  formattedDeltaMonthlySpend: string
}

export const RenewableEnergyPlanCard: React.FC<Props> = ({
  plan,
  formattedCurrentMonthlyUsage,
  formattedCurrentMonthlySpend,
  formattedPlanMonthlySpend,
  formattedDeltaMonthlySpend,
}) => {
  const partnerParams = new URLSearchParams({
    utm_medium: 'partner',
    utm_source: 'gridly',
  })
  const href = `${plan.link}&${partnerParams}`

  return (
    <Tile className="renewable-energy-plan-card__plan">
      <img
        alt="Plan Supplier Logo"
        src={plan.supplier_image_url}
        className="renewable-energy-plan-card__plan-logo"
      />

      <div className="renewable-energy-plan-card__content-left">
        <h3 className="renewable-energy-plan-card__plan-title">{plan.name}</h3>

        <p className="renewable-energy-plan-card__plan-description">
          {plan.description}
        </p>

        <div className="renewable-energy-plan-card__plan-tables">
          <table className="renewable-energy-plan-card__plan-table">
            <tbody>
              <tr>
                <td>Contract Term</td>
                <td>{plan.contract_term} Months</td>
              </tr>
              <tr>
                <td>Plan Type</td>
                <td>{plan.plan_type}</td>
              </tr>
              <tr>
                <td>Cancellation Fee</td>
                <td>{plan.cancellation_fee}</td>
              </tr>
              <tr>
                <td>Distributor</td>
                <td>{plan.distributor.toUpperCase()}</td>
              </tr>
            </tbody>
          </table>

          <table className="renewable-energy-plan-card__plan-table">
            <thead>
              <tr>
                <th>Pricing: Monthly kWh</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>500kWh</td>
                <td>{plan.pricing_kwh_500}&#162;/kWh</td>
              </tr>
              <tr>
                <td>1000kWh</td>
                <td>{plan.pricing_kwh_1000}&#162;/kWh</td>
              </tr>
              <tr>
                <td>2000kWh</td>
                <td>{plan.pricing_kwh_2000}&#162;/kWh</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="renewable-energy-plan-card__content-right">
        <div className="renewable-energy-plan-card__plan-cost-section">
          <div className="renewable-energy-plan-card__plan-cost-section-content">
            <h5>
              Estimated Savings <br />
              {formattedDeltaMonthlySpend}*
            </h5>

            <p className="body-3">
              Your Current Plan {formattedCurrentMonthlySpend}
            </p>

            <p className="body-3">This Plan {formattedPlanMonthlySpend}</p>
          </div>

          <a
            href={href}
            className="renewable-energy-plan-card__plan-cost-section-choose-plan body-1 body-1--bold"
            target="_blank"
            rel="noreferrer"
          >
            Choose Plan
          </a>
        </div>

        <div className="renewable-energy-plan-card__plan-cost-explained">
          <p className="blue-text blue-text--light body-3 body-3--bold">
            Link opens in a new tab.
          </p>

          <p className="blue-text blue-text--light body-3">
            *Pricing is based on your current estimated energy usage of{' '}
            {formattedCurrentMonthlyUsage}. The rates and cost amounts
            represented are for the energy supply only and do not include
            delivery charges which will not vary based on the plan you choose.
          </p>
        </div>
      </div>
    </Tile>
  )
}
