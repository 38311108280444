import React from 'react'

import { useRenewableEnergyPlans } from '../../../../api'
import { MaxWidth, RenewableEnergyPlanCard } from '../../../../components'

import './RenewableEnergyExplorePage.scss'

export const RenewableEnergyExplorePage: React.FC = () => {
  const { renewableEnergyPlans, error, isLoading } = useRenewableEnergyPlans()
  if (error) return <>{`Error: ${error.message}`}</>
  if (isLoading) return <>Loading...</>

  return (
    <div className="renewable-energy-explore-page">
      <MaxWidth backgroundColor="blue" wide>
        <div className="renewable-energy-explore-page__page-header">
          <h1>Purchase Renewable Energy from Supplier</h1>
        </div>
      </MaxWidth>

      <MaxWidth wide>
        {renewableEnergyPlans.map(
          ({
            plan,
            formattedCurrentMonthlyUsage,
            formattedCurrentMonthlySpend,
            formattedPlanMonthlySpend,
            formattedDeltaMonthlySpend,
          }) => {
            return (
              <RenewableEnergyPlanCard
                key={plan.name}
                plan={plan}
                formattedCurrentMonthlyUsage={formattedCurrentMonthlyUsage}
                formattedCurrentMonthlySpend={formattedCurrentMonthlySpend}
                formattedPlanMonthlySpend={formattedPlanMonthlySpend}
                formattedDeltaMonthlySpend={formattedDeltaMonthlySpend}
              />
            )
          }
        )}
      </MaxWidth>
    </div>
  )
}
