import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { SolarPanelsExplorePageV1 } from './V1'
import { SolarPanelsExplorePageV2 } from './V2'

export const SolarPanelsExplorePage: React.FC = () => {
  const { exploreSolarPanelsV2 } = useFlags()

  return exploreSolarPanelsV2 ? (
    <SolarPanelsExplorePageV2 />
  ) : (
    <SolarPanelsExplorePageV1 />
  )
}
