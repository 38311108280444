import React from 'react'

import { NextStepsItem, NextStepsList } from '../../../../../components'
import { GRIDLY_PROJECT_SLUGS } from '../../../../../constants'

export const SolarPanelsExplorePageV1: React.FC = () => {
  return (
    <NextStepsList
      projectSlug={GRIDLY_PROJECT_SLUGS.SOLAR_PANELS}
      imgAltText="Picture of a solar panel array"
      title="Get rooftop solar and say goodbye to big utility bills."
    >
      <NextStepsItem
        step="Check your roof condition & age"
        explainer="An older or damaged roof could require repairs or replacement prior to solar install."
      />

      <NextStepsItem
        step="Compare system choices and sizing"
        explainer="That's panels, inverters and meters."
      />

      <NextStepsItem
        step="Request a detailed proposal"
        explainer="A professional installer can assess and advise about required space and product recommendation."
      />
    </NextStepsList>
  )
}
