// app/javascript/entrypoint.tsx
import axios from 'axios'
import * as React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { getCookie } from './src/cookies'
import { App } from './src/App'

const token = getCookie('jwt')

if (token) {
  ;(axios.defaults.headers as any)['Authorization'] = `Bearer ${token}`
}

document.addEventListener('DOMContentLoaded', () => {
  const rootEl = document.getElementById('app')
  if (rootEl) {
    const root = ReactDOMClient.createRoot(rootEl)
    root.render(<App />)
  }
})
