import React from 'react'
import Cleave from 'cleave.js/react'
import 'cleave.js/dist/addons/cleave-phone.us'
import { CleaveOptions } from 'cleave.js/options'
import { ChangeEventHandler } from 'cleave.js/react/props'

import { withInputWrapper } from '../../../hoc/withInputWrapper'
import './MaskedInput.scss'

interface Props extends React.ComponentPropsWithoutRef<'input'> {
  label: string
  name: string
  options?: CleaveOptions
}

export const BaseMaskedInput: React.FC<Props> = ({
  options = {},
  ...props
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const tempEvent = e
    tempEvent.target.value = e.target.rawValue
    props.onChange?.(tempEvent)
  }

  return (
    <Cleave
      {...props}
      className="masked-input__input"
      options={options}
      placeholder={props.placeholder || props.label}
      onChange={handleChange}
    />
  )
}

const MaskedInput = withInputWrapper(BaseMaskedInput, 'masked')

const ThousandsMaskedInput: React.FC<Props> = (props) => (
  <MaskedInput
    {...props}
    options={{
      ...props.options,
      numeral: true,
    }}
  />
)

ThousandsMaskedInput.displayName = 'ThousandsMaskedInput'

const CurrencyMaskedInput: React.FC<Props> = (props) => (
  <MaskedInput
    {...props}
    options={{
      ...props.options,
      numeral: true,
      prefix: '$',
      noImmediatePrefix: true,
      rawValueTrimPrefix: true,
    }}
  />
)

CurrencyMaskedInput.displayName = 'CurrencyMaskedInput'

const PhoneNumberMaskedInput: React.FC<Props> = (props) => (
  <MaskedInput
    {...props}
    options={{
      phone: true,
      phoneRegionCode: 'US',
      ...props.options,
    }}
  />
)

PhoneNumberMaskedInput.displayName = 'PhoneNumberMaskedInput'

export {
  MaskedInput,
  ThousandsMaskedInput,
  CurrencyMaskedInput,
  PhoneNumberMaskedInput,
}
