import React from 'react'

import { DisplaySettingsContext } from '../DisplaySettingsContext'

export const useDisplaySettings = () => {
  const ctx = React.useContext(DisplaySettingsContext)
  if (ctx === undefined) {
    throw new Error(
      'useDisplaySettings hook may only be used within DisplaySettingsContext'
    )
  }

  const { displaySettings, setDisplaySettings, resetDisplaySettings } = ctx
  const { showFinanced } = displaySettings
  const toggleShowFinanced = () => {
    setDisplaySettings({ ...displaySettings, showFinanced: !showFinanced })
  }
  return {
    showFinanced,
    toggleShowFinanced,
    resetDisplaySettings,
  }
}
