import React from 'react'

import { withInputWrapper } from '../../../hoc/withInputWrapper'
import './NumberInput.scss'

interface Props extends React.ComponentPropsWithRef<'input'> {
  label: string
  name: string
}

const BaseNumberInput: React.FC<Props> = React.forwardRef(
  ({ label, placeholder, ...props }, ref) => {
    return (
      <input
        {...props}
        type="number"
        className="number-input__input"
        placeholder={placeholder || label}
        ref={ref}
      />
    )
  }
)

BaseNumberInput.displayName = 'BaseNumberInput'

const NumberInput = withInputWrapper(BaseNumberInput, 'number')

NumberInput.displayName = 'NumberInput'

export { NumberInput }
