import React from 'react'

import { Field, Form, Formik, FormikHelpers } from 'formik'
import { PropertyFormValues } from '../../../types'
import { AutoSave, SelectInput } from '../../formElements'
import { yesNoSelectOptions } from '../constants'

interface Props {
  initialValues: PropertyFormValues
  onSubmit: (values: PropertyFormValues) => Promise<void>
}
export const CommunitySolarForm: React.VFC<Props> = ({
  initialValues,
  onSubmit,
}) => {
  const handleSubmit = async (
    values: PropertyFormValues,
    helpers: FormikHelpers<PropertyFormValues>
  ) => {
    try {
      await onSubmit(values)
    } catch (e) {
      helpers.resetForm()
    }
  }

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Form role="form">
        <AutoSave />

        <Field
          as={SelectInput}
          name="community_solar_customer"
          label="Current community solar customer?"
          options={yesNoSelectOptions}
        />
      </Form>
    </Formik>
  )
}
