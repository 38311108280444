import React from 'react'
import classnames from 'classnames'
import { useMatch, Link, LinkProps } from 'react-router-dom'

import { ROUTES } from '../../../routes'
import './TabNav.scss'

interface TabLinkProps extends LinkProps {
  to: string
}

const TabLink: React.FC<TabLinkProps> = ({ children, ...props }) => (
  <Link
    className={classnames([
      'tab-nav__tab',
      { 'tab-nav__tab--active': !!useMatch(props.to) },
      props.className,
    ])}
    to={props.to}
  >
    {children}
  </Link>
)

export const TabNav: React.VFC = () => {
  return (
    <div className="tab-nav">
      <TabLink to={ROUTES.myHome}>Basics</TabLink>
      <TabLink to={ROUTES.myHomeUtilities}>Vendors & Spend</TabLink>
    </div>
  )
}
