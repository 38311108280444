import React from 'react'

import { GRIDLY_PROJECT_SLUGS } from '../../../../../constants'
import {
  VendorInfoTile,
  VendorListLayout,
  VendorTileLink,
} from '../../../../../components'
import { useProject } from '../../../../../api'
import { ROUTES } from '../../../../../routes'
import { ProjectModel } from '../../../../../models/ProjectModel'

export const ConvertWaterHeaterExplorePageV2: React.FC = () => {
  const { error, isLoading, project } = useProject(
    GRIDLY_PROJECT_SLUGS.CONVERT_WATER_HEATER
  )

  if (error) {
    return <>Error {error.message}</>
  }

  if (isLoading) {
    return <>Loading...</>
  }

  return (
    <VendorListLayout
      heading="Choose the Hot Water Heater installation service that works best for you"
      subheading="Gridly has partnered with these installers to provide superior quality Hot Water Heater installations in your area."
      projectSlug={project.slug}
      InfoTile={() => (
        <VendorInfoTile
          Title={() => (
            <h4>
              <span className="green-text">
                Get a proposal & quote <br aria-hidden />
                from an experienced <br aria-hidden />
              </span>
              Hot Water Heater installer
            </h4>
          )}
        >
          <p className="body-2 body-2--bold">What you should know</p>

          <p className="body-2">
            Your new heat pump hot water heater does not need to be in a
            conditioned space but it will work most effectively in temperatures
            above thirty-seven degrees. Like all plumbing devices it should be
            protected from below-freezing temperatures. And because it uses an
            air source heat pump, adequate space for air circulation will be
            needed. The rule of thumb is that the room in which it is place be
            at least seven hundred cubic feet, or roughly 9&rsquo; x 9&rsquo; x
            9&rsquo;. Also keep in mind that the heat pump will produce
            condensate so placement near a washing machine drain is often a good
            choice.
          </p>

          <p className="body-2  body-2--bold">
            Expect the installer to confirm the following information
          </p>

          <ol className="body-2">
            <li>
              The dimension of the room in which the water heater will be placed
            </li>
            <li>Your current electrical service</li>
            <li>The location of the nearest drain</li>
          </ol>
        </VendorInfoTile>
      )}
    >
      {ProjectModel.activeSolutions(project).map((solution) => (
        <VendorTileLink
          key={solution.id}
          solution={solution}
          to={ROUTES.requestForProposal(`${solution.id}`)}
          disabled={Boolean(
            project.request_for_proposals.find(
              ({ solution_id }) => solution_id === solution.id
            )
          )}
        />
      ))}{' '}
    </VendorListLayout>
  )
}
