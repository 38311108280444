import React from 'react'

import { useProperty } from '../../../../api'
import { MaxWidth, ProjectRecommendationImage } from '../../../../components'
import { GRIDLY_PROJECT_SLUGS } from '../../../../constants'

import './CommunitySolarExplorePage.scss'

const WIDGET_TAG_NAME = 'es-cdgm-cta'

export const CommunitySolarExplorePage: React.FC = () => {
  const [scriptLoaded, setScriptLoaded] = React.useState(false)
  const pageBody = React.createRef<HTMLDivElement>()
  const {
    error: errorLoadingProperty,
    isLoading: isLoadingProperty,
    property,
  } = useProperty()

  React.useEffect(() => {
    const externalScript = document.createElement('script')
    externalScript.src =
      'https://communitysolar.energysage.com/widgets/es-cdgm-cta/es-cdgm-cta-latest.js'
    externalScript.onload = () => setScriptLoaded(true)
    document.head.append(externalScript)

    return () => {
      externalScript.remove()
    }
  }, [])

  React.useEffect(() => {
    const existingWidgets = document.getElementsByTagName(WIDGET_TAG_NAME)

    if (
      pageBody.current &&
      scriptLoaded &&
      property &&
      !existingWidgets.length
    ) {
      const widget = document.createElement(WIDGET_TAG_NAME)
      widget.classList.add('community-solar-explore-page__widget')
      widget.dataset.partner = ''
      widget.dataset.domain = 'https://communitysolar.energysage.com'
      widget.dataset.state = property.state || ''
      widget.dataset.zipCode = property.postalCode || ''
      widget.dataset.openInNewTab = 'true'

      pageBody.current.appendChild(widget)
    }
  }, [scriptLoaded, property])

  if (errorLoadingProperty) {
    return <>Error: {errorLoadingProperty.message}</>
  }

  if (isLoadingProperty) {
    return <>Loading...</>
  }

  return (
    <div className="community-solar-explore-page">
      <MaxWidth backgroundColor="blue">
        <div className="community-solar-explore-page__header">
          <h3>Become a Community Solar Subscriber</h3>

          <ProjectRecommendationImage
            alt="A picture of a solar panel"
            className="community-solar-explore-page__image"
            projectSlug={GRIDLY_PROJECT_SLUGS.COMMUNITY_SOLAR}
          />
        </div>
      </MaxWidth>

      <MaxWidth>
        <div className="community-solar-explore-page__body">
          <div data-testid="widget" ref={pageBody}></div>
        </div>
      </MaxWidth>
    </div>
  )
}
