export function constantToOptions(
  enumerable: Record<string, string> | string[],
  labels?: Record<string, string>,
) {
  return Object.values(enumerable).map((v) => {
    if (labels) {
      return { value: v, label: labels[v] }
    } else {
      return { value: v, label: v }
    }
  })
}
