import React from 'react'
import { Formik, Form, Field } from 'formik'

import { TextInput, SubmitButton } from '../../formElements'
import { RequestForProposalSchema } from './schema'

import './RequestForProposalForm.scss'

export interface RequestForProposalFormValues {
  street_address: string
  street_address_2: string
  city: string
  state: string
  postal_code: string
  first_name: string
  last_name: string
  phone_number: string
  email: string
}

interface Props {
  initialValues: RequestForProposalFormValues
  onSubmit: (data: RequestForProposalFormValues) => Promise<void>
}

export const RequestForProposalForm: React.VFC<Props> = ({
  initialValues,
  onSubmit,
}) => {
  const handleSubmit = async (values: RequestForProposalFormValues) => {
    await onSubmit(values)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnMount
      validationSchema={RequestForProposalSchema}
    >
      <Form className="request-for-proposal-form" role="form">
        <div className="request-for-proposal-form__property-fields">
          <Field
            readOnly
            as={TextInput}
            className="request-for-proposal-form__street-address-field"
            label="Street Address"
            name="street_address"
          />

          <Field
            as={TextInput}
            className="request-for-proposal-form__street-address-2-field"
            label="Street Address 2"
            name="street_address_2"
          />

          <Field
            readOnly
            as={TextInput}
            className="request-for-proposal-form__city-field"
            label="City"
            name="city"
          />

          <Field
            readOnly
            as={TextInput}
            className="request-for-proposal-form__state-field"
            label="State"
            name="state"
          />

          <Field
            readOnly
            as={TextInput}
            className="request-for-proposal-form__zip-field"
            label="Zip"
            name="postal_code"
          />
        </div>

        <div className="request-for-proposal-form__user-fields">
          <Field as={TextInput} label="First Name*" name="first_name" />

          <Field as={TextInput} label="Last Name*" name="last_name" />

          <Field as={TextInput} label="Phone Number" name="phone_number" />

          <Field readOnly as={TextInput} label="Email Address" name="email" />
        </div>

        <SubmitButton className="request-for-proposal-form__submit-button">
          Confirm, Request Proposal
        </SubmitButton>
      </Form>
    </Formik>
  )
}
