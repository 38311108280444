import React from 'react'
import { Formik, Field } from 'formik'

import { CompareTower, MaxWidth, SelectInput } from '../../components'
import {
  CLEAN_ENERGY_PROJECTS,
  CLEAN_ENERGY_OPTIONS,
  CLEAN_ENERGY_PROJECT_NAMES,
} from './constants'
import './CompareCleanEnergyPage.scss'
import { GRIDLY_PROJECT_SLUGS } from '../../constants'
import { useActionPlan, useProperty, useUtilityData } from '../../api'
import { compareTowerOptionType } from './helpers'

export const CompareCleanEnergyPage: React.VFC = () => {
  const {
    error: errorLoadingProperty,
    isLoading: isLoadingProperty,
    property,
  } = useProperty()
  const {
    error: errorLoadingUtilityData,
    isLoading: isLoadingUtilityData,
    utilityData,
  } = useUtilityData(property?.postalCode)
  const {
    error: errorLoadingActionPlan,
    isLoading: isLoadingActionPlan,
    actionPlan,
  } = useActionPlan()

  if (errorLoadingProperty || errorLoadingUtilityData || errorLoadingActionPlan)
    return (
      <>
        Error:{' '}
        {errorLoadingProperty?.message ||
          errorLoadingUtilityData?.message + 'utilities' ||
          errorLoadingActionPlan?.message + 'action_plan'}
      </>
    )
  if (isLoadingProperty || isLoadingUtilityData || isLoadingActionPlan)
    return <>Loading...</>

  return (
    <div className="compare-clean-energy-page">
      <MaxWidth backgroundColor="blue" wide>
        <div className="compare-clean-energy-page__page-header">
          <h1 className="compare-clean-energy-page__title">
            Compare Clean Energy Options
          </h1>
        </div>
      </MaxWidth>

      <Formik
        initialValues={{ selectedComparison: CLEAN_ENERGY_PROJECT_NAMES[0] }}
        onSubmit={() => undefined}
      >
        {({ values }) => {
          const selectedProject = actionPlan.getProjectBySlug(
            values.selectedComparison as GRIDLY_PROJECT_SLUGS
          )

          return (
            <MaxWidth wide>
              <div className="compare-clean-energy-page__page-content">
                <Field
                  as={SelectInput}
                  className="compare-clean-energy-page__select"
                  label="Select to compare"
                  name="selectedComparison"
                  options={CLEAN_ENERGY_OPTIONS}
                />

                <div className="compare-clean-energy-page__arena">
                  <div className="compare-clean-energy-page__current-provider">
                    <CompareTower
                      title="Traditional Provider"
                      description="Stay with your electric utility company available to all residents in your area."
                      pros="No need to change providers. Your utility is trying to increase its renewable sourcing."
                      cons={`Currently only ${utilityData.electricity_percent_renewable}% renewable, and it will probably take decades for the utility to get to 100%.`}
                      optionType="current"
                      monthlyCost={property.electricUtilityMonthlyExpenditure}
                      unitPrice={`${utilityData.electricity_price}`}
                    />
                  </div>

                  <div className="compare-clean-energy-page__clean-energy-providers compare-clean-energy-page__clean-energy-providers--mobile">
                    <CompareTower
                      key={
                        CLEAN_ENERGY_PROJECTS[values.selectedComparison].title
                      }
                      {...CLEAN_ENERGY_PROJECTS[values.selectedComparison]}
                      monthlyCost={selectedProject.monthly_cost}
                      unitPrice={selectedProject.unit_price}
                      optionType={compareTowerOptionType(
                        selectedProject,
                        values.selectedComparison
                      )}
                    />
                  </div>

                  <div className="compare-clean-energy-page__clean-energy-providers compare-clean-energy-page__clean-energy-providers--desktop">
                    {Object.keys(CLEAN_ENERGY_PROJECTS).map((key) => {
                      const project = actionPlan.getProjectBySlug(
                        key as GRIDLY_PROJECT_SLUGS
                      )
                      const projectConstants = CLEAN_ENERGY_PROJECTS[key]

                      return (
                        <CompareTower
                          key={projectConstants.title}
                          {...projectConstants}
                          monthlyCost={project.monthly_cost}
                          unitPrice={project.unit_price}
                          optionType={compareTowerOptionType(project, key)}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            </MaxWidth>
          )
        }}
      </Formik>
    </div>
  )
}
