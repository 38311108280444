import React from 'react'
import { Formik, Form, Field } from 'formik'

import { ResponsiveText } from '../../ResponsiveText'
import { ToggleFinancedButton } from '../../ToggleFinancedButton'
import { AutoSave, SelectInput } from '../../formElements'
import { sortByOptions } from './constants'

import './ActionPlanControlForm.scss'

type FormValues = {
  sortBy?: string
}

type Props = {
  initialValues: FormValues
  onSubmit: (values: FormValues) => void
}

export const ActionPlanControlForm: React.VFC<Props> = ({
  initialValues,
  onSubmit,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form className="action-plan-control-form">
        <Field
          as={SelectInput}
          className="action-plan-control-form__sort-by"
          name="sortBy"
          label="Sort By"
          options={sortByOptions}
        />

        <div className="action-plan-control-form__toggle-financed">
          <ResponsiveText desktop="Show" />
          <ToggleFinancedButton />
        </div>

        <AutoSave debounceMs={0} />
      </Form>
    </Formik>
  )
}
