import React from 'react'

interface Props {
  step: string
  explainer: string
}

export const NextStepsItem: React.FC<Props> = ({ step, explainer }) => {
  return (
    <li>
      <span>{step}</span>
      <p className="body-2">{explainer}</p>
    </li>
  )
}
