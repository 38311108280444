import React from 'react'

import massSavelogo from './MassSaveLogo.png'
import './InsulationExplorePageMassSaveHPC.scss'

export const InsulationExplorePageMassSaveHPC: React.FC = () => {
  return (
    <div className="insulation-explore-mass-save">
      <a
        className="insulation-explore-mass-save__link"
        href="https://www.masssave.com/learn/find-a-contractor-hpc"
        target="_blank"
        rel="noreferrer"
        role="link"
        aria-labelledby="mass-save-hpc-link-title"
      >
        <h5 id="mass-save-hpc-link-title">
          Choose a Home Performance Contractor serving your area that
          participates in the Mass Save program
        </h5>

        <img
          className="insulation-explore-mass-save__mass-save-logo"
          src={massSavelogo}
          alt="Mass Save Logo"
        ></img>
      </a>
    </div>
  )
}
