import React, { useEffect } from 'react'
import { useNavigate, useMatch } from 'react-router-dom'

import { usePropertyData } from '../../context'
import { ROUTES } from '../../routes/constants' // importing this from '../../routes' SHOULD work, but doesn't in tests - though it does in browser...

export const withPropertyDataGuard = <T extends Record<string, unknown>>(
  Komponent: React.FC<T>
): React.FC<T> => {
  const WrappedKomponent = (props: T) => {
    const navigate = useNavigate()
    const onLandingPage = useMatch(ROUTES.root)
    const { propertyDataHasBeenSet } = usePropertyData()

    useEffect(() => {
      if (!onLandingPage && !propertyDataHasBeenSet) navigate(ROUTES.root)
    })

    return propertyDataHasBeenSet ? <Komponent {...props} /> : <p>Loading</p>
  }

  return WrappedKomponent
}
