import React from 'react'
import { Link } from 'react-router-dom'

import { ROUTES } from '../../../../routes/constants'
import { formatDollars } from '../../../../helpers'
import { useProperty } from '../../../../api'
import { AddressStreetViewImage } from '../../../media'
import './PropertyInfo.scss'

export const PropertyInfo: React.VFC = () => {
  const {
    property,
    isLoading: isLoadingProperty,
    error: errorFetchingProperty,
  } = useProperty()

  if (isLoadingProperty) return <>Loading Property Info</>

  if (errorFetchingProperty) return <>Hmm, somethings not right</>

  return (
    <div className="member-experience-header-property-info">
      <div className="member-experience-header-property-info__image">
        <AddressStreetViewImage propertyData={property.data} fov="60" />
      </div>

      <div className="member-experience-header-property-info__details-mobile-content">
        <Link
          to="/my-home"
          className="member-experience-header-property-info__street-address-edit-link body-3 body-3--bold"
        >
          {property.formattedStreetAddress}
        </Link>
      </div>

      <div className="member-experience-header-property-info__details-desktop-content">
        <p className="body-3 body-3--bold">{property.formattedStreetAddress}</p>

        <p className="body-3 body-3--bold">{property.formattedCityStateZip}</p>
      </div>

      <div className="member-experience-header-property-info__details-desktop-content">
        <p className="body-3">
          Energy:{' '}
          {formatDollars(property.totalUtilityAnnualExpenditure, {
            fractionDigits: 0,
          })}
          /yr
        </p>

        <p className="body-3">
          {property.totalUtilityPercentRenewable}% Renewable
        </p>
      </div>

      <Link
        to={ROUTES.myHome}
        className="member-experience-header-property-info__edit"
      >
        Update
      </Link>
    </div>
  )
}
