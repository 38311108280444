import React from 'react'
import { HashLink } from 'react-router-hash-link'

import { Project } from '../../../../types'
import { formatDollars } from '../../../../helpers'
import { PriceDetail } from '../../../PriceDetail'
import { ToggleFinancedButton } from '../../../ToggleFinancedButton'
import { EstimatesTable } from '../EstimatesTable'

type Props = Pick<
  Project,
  | 'rebate_text'
  | 'upfront_cost'
  | 'upfront_cost_text'
  | 'net_cost'
  | 'net_cost_text'
  | 'has_incentives'
  | 'incentives_total'
> & {
  hasFootnotes: boolean
  hideIncentiveLink: boolean
}

export const UpfrontCostEstimates: React.FC<Props> = ({
  rebate_text,
  upfront_cost,
  upfront_cost_text,
  net_cost,
  net_cost_text,
  has_incentives,
  hasFootnotes,
  hideIncentiveLink,
  incentives_total,
}) => (
  <EstimatesTable
    heading={`Cost Estimates${hasFootnotes && '*'}`}
    headerRightContent={<ToggleFinancedButton />}
  >
    <tr>
      <td className="body-3">Full Cost</td>
      <td className="body-2 body-2--bold">
        {upfront_cost_text ? (
          <>{upfront_cost_text}</>
        ) : (
          <PriceDetail price={upfront_cost} />
        )}
      </td>
    </tr>

    <tr>
      <td className="body-3">
        {has_incentives && !hideIncentiveLink ? (
          <HashLink
            className="body-3 green-text green-text--dark"
            to="#incentives"
            smooth
          >
            Incentives
          </HashLink>
        ) : (
          <>Incentives</>
        )}
      </td>
      <td className="body-2 body-2--bold green-text green-text--dark">
        {rebate_text ||
          `-${formatDollars(incentives_total, {
            fractionDigits: 0,
            format: 'longhand',
          })}`}
      </td>
    </tr>

    <tr>
      <td></td>
      <td>
        <hr />
      </td>
    </tr>

    <tr>
      <td className="body-3">Net Project Cost</td>
      <td className="body-2 body-2--bold">
        {net_cost_text ? (
          <>{net_cost_text}</>
        ) : (
          <PriceDetail price={net_cost} />
        )}
      </td>
    </tr>
  </EstimatesTable>
)
