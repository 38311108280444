import React, { useEffect } from 'react'

import { Link, useNavigate } from 'react-router-dom'

import { useActionPlan } from '../../../../api'
import { MaxWidth, ProjectRecommendationImage } from '../../../../components'
import {
  GRIDLY_INSULATION_PRODUCT_NAMES,
  GRIDLY_PROJECT_SLUGS,
} from '../../../../constants'
import { ROUTES } from '../../../../routes'
import {
  PRODUCTS_WITHOUT_EXPLORE_COMPONENT,
  PRODUCT_EXPLORE_COMPONENTS,
  PRODUCT_EXPLORE_TITLES,
} from './constants'

import './InsulationExplorePage.scss'

export const InsulationExplorePage: React.FC = () => {
  const navigate = useNavigate()

  const {
    error: errorLoadingActionPlan,
    isLoading: isLoadingActionPlan,
    actionPlan,
  } = useActionPlan()

  useEffect(() => {
    if (errorLoadingActionPlan || isLoadingActionPlan) {
      return
    }
    const project = actionPlan.getProjectBySlug(GRIDLY_PROJECT_SLUGS.INSULATION)

    const recommendation =
      project.product_recommendation as GRIDLY_INSULATION_PRODUCT_NAMES

    if (PRODUCTS_WITHOUT_EXPLORE_COMPONENT.includes(recommendation)) {
      navigate(ROUTES.projects[GRIDLY_PROJECT_SLUGS.INSULATION])
    }
  }, [errorLoadingActionPlan, isLoadingActionPlan, actionPlan])

  if (errorLoadingActionPlan) {
    return <>Error: {errorLoadingActionPlan.message}</>
  }

  if (isLoadingActionPlan) {
    return <>Loading...</>
  }

  const project = actionPlan.getProjectBySlug(GRIDLY_PROJECT_SLUGS.INSULATION)

  const recommendation =
    project.product_recommendation as GRIDLY_INSULATION_PRODUCT_NAMES

  const title = PRODUCT_EXPLORE_TITLES[recommendation]
  const RecommendationComponent = PRODUCT_EXPLORE_COMPONENTS[recommendation]

  return (
    <div className="insulation-explore">
      <MaxWidth backgroundColor="blue">
        <div className="insulation-explore__header">
          <h3>{title}</h3>

          <ProjectRecommendationImage
            className="insulation-explore__image"
            alt="A picture of insulation material"
            projectSlug={GRIDLY_PROJECT_SLUGS.INSULATION}
          />
        </div>
      </MaxWidth>

      <MaxWidth
        wide={
          recommendation ===
          GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_NEEECO
        }
      >
        <div className="insulation-explore__body">
          <RecommendationComponent />

          <Link
            className="insulation-explore__back"
            to={ROUTES.projects[GRIDLY_PROJECT_SLUGS.INSULATION]}
          >
            Back
          </Link>
        </div>
      </MaxWidth>
    </div>
  )
}
