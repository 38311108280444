import React, { useState } from 'react'

import { ROUTES } from '../../../routes'
import { useTheme } from '../../../hooks'
import { HeaderLink } from '../../navElements'
import { BurgerMenuIcon, PrimaryLogo } from '../../media'
import { Button } from '../../Button'
import { MobileMenu } from './MobileMenu'
import { PropertyInfo } from './PropertyInfo'
import './MemberExperienceHeader.scss'
import { Link } from 'react-router-dom'
import { MaxWidth } from '../../MaxWidth'

export const MemberExperienceHeader: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useTheme(isMenuOpen ? 'dark' : 'light')

  const toggleMenu = () => setIsMenuOpen((isOpen) => !isOpen)

  return (
    <MaxWidth backgroundColor="white" wide>
      <div className="member-experience-header">
        <div className="member-experience-header__mobile-content member-experience-header__mobile-content--left">
          <Button variant="transparent" onClick={toggleMenu}>
            <BurgerMenuIcon />
          </Button>

          <Link to={ROUTES.root}>
            <PrimaryLogo
              className="member-experience-header__logo"
              mode="dark"
            />
          </Link>

          <MobileMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
        </div>

        <div className="member-experience-header__mobile-content member-experience-header__mobile-content--right">
          <PropertyInfo />
        </div>

        <div className="member-experience-header__desktop-content member-experience-header__desktop-content--left">
          <Link to={ROUTES.root}>
            <PrimaryLogo
              className="member-experience-header__logo"
              mode="dark"
            />
          </Link>

          <HeaderLink to={ROUTES.actionPlan}>Action Plan</HeaderLink>

          <HeaderLink to={ROUTES.myAccount}>My Account</HeaderLink>
        </div>

        <div className="member-experience-header__desktop-content member-experience-header__desktop-content--right">
          <PropertyInfo />
        </div>
      </div>
    </MaxWidth>
  )
}
