import React from 'react'

import { PropertyFormValues } from '../../../types'
import {
  GRIDLY_CARBON_IMPACT_DESCRIPTIONS,
  GRIDLY_PROJECT_SLUGS,
} from '../../../constants'
import { formatDollars } from '../../../helpers'
import { ROUTES } from '../../../routes'
import { useProject, useProperty } from '../../../api'
import { useToast } from '../../../context'
import { HotWaterHeaterForm, Project, RenewablePie } from '../../../components'
import { LinkButton } from '../../../components/LinkButton'

export const ConvertWaterHeaterPage: React.VFC = () => {
  const { setSuccessToast, setErrorToast } = useToast()
  const {
    error: errorLoadingProperty,
    isLoading: isLoadingProperty,
    property,
    updateProperty,
  } = useProperty()
  const {
    error: errorLoadingProject,
    isLoading: isLoadingProject,
    project,
  } = useProject(GRIDLY_PROJECT_SLUGS.CONVERT_WATER_HEATER)

  if (errorLoadingProperty || errorLoadingProject) {
    return (
      <>Error {errorLoadingProject?.message || errorLoadingProject?.message}</>
    )
  }

  if (isLoadingProperty || isLoadingProject) {
    return <>Loading...</>
  }

  const recommendedGallons = () => {
    switch (property.data.num_residents) {
      case 0:
      case 1:
      case 2:
        return 40
      case 3:
        return 50
      case 4:
        return 65
      default:
        return 80
    }
  }

  const handleSubmit = async (propertyData: PropertyFormValues) => {
    if (property.id) {
      try {
        await updateProperty(property.id, propertyData)

        setSuccessToast()
      } catch (e) {
        setErrorToast()

        throw e
      }
    }
  }

  return (
    <>
      <Project.Header
        title="Hot Water Heating"
        recommendationText={`Convert from ${property.hotWaterHeaterFuel} to a hybrid electric heat pump water heater.`}
        whyText="Big impact for little $. Get significant rebates and reduce annual fuel costs."
      >
        <Project.Highlight
          description="Lock in rebates that will cover up to half the cost.*"
          takeAway="50%"
          takeAwayStyle="solid"
        />

        <Project.Highlight
          description={`Save up to ${formatDollars(
            parseFloat(project.annual_savings ?? '0'),
            { fractionDigits: 0, format: 'longhand' }
          )} in energy costs per year.*`}
          takeAway={formatDollars(parseFloat(project.annual_savings ?? '0'), {
            fractionDigits: 1,
            format: 'shorthand',
          })}
          takeAwayStyle="outline"
        />

        {project.carbon_impact && (
          <Project.Highlight
            description={
              GRIDLY_CARBON_IMPACT_DESCRIPTIONS[project.carbon_impact]
            }
            takeAway={project.carbon_impact}
            takeAwayStyle="outline"
          />
        )}

        <Project.Highlight
          description={`Increase your renewable energy from ${property.data.total_utility_percent_renewable}% to ${project.future_total_utility_percent_renewable}%`}
          takeAway={
            <RenewablePie
              percentRenewable={parseInt(
                project.future_total_utility_percent_renewable || '0'
              )}
            />
          }
        />
      </Project.Header>

      <Project.Sandbox title="We'll help you choose the perfect water heater.">
        <HotWaterHeaterForm
          initialValues={property.toFormValues()}
          onSubmit={handleSubmit}
        />
      </Project.Sandbox>

      <Project.Recommendation
        title={`${recommendedGallons()}-Gallon Hybrid Electric Heat Pump Water Heater`}
        propertyIsEligible
        project={project}
        exploreLink={
          <LinkButton
            to={
              ROUTES.projectExplorePages[
                GRIDLY_PROJECT_SLUGS.CONVERT_WATER_HEATER
              ]
            }
            variant="blue"
          >
            Explore Your Options
          </LinkButton>
        }
      />

      <Project.Education project={project} />
    </>
  )
}
