import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { createRFP, useProperty, useSolution, useUser } from '../../api'
import { useToast } from '../../context'
import {
  MaxWidth,
  RequestForProposalForm,
  RequestForProposalFormValues,
} from '../../components'
import { NotFoundPage } from '../NotFoundPage'

import './RequestForProposalPage.scss'
import { ROUTES } from '../../routes'

export const RequestForProposalPage: React.VFC = () => {
  const { solution_id } = useParams()
  const { setErrorToast } = useToast()
  const navigate = useNavigate()
  const { isLoading: isLoadingUser, error: errorLoadingUser, user } = useUser()

  const {
    isLoading: isLoadingProperty,
    error: errorLoadingProperty,
    property,
  } = useProperty()

  const {
    isLoading: isLoadingSolution,
    error: errorLoadingSolution,
    solution,
  } = useSolution(parseInt(solution_id || ''))

  if (!solution_id) {
    return <NotFoundPage />
  }

  if (isLoadingUser || isLoadingSolution || isLoadingProperty) {
    return <>Loading...</>
  }

  if (errorLoadingUser || errorLoadingSolution || errorLoadingProperty) {
    return (
      <>
        Error:{' '}
        {errorLoadingUser?.message ||
          errorLoadingProperty?.message ||
          errorLoadingSolution?.message}
      </>
    )
  }

  const handleSubmit = async (data: RequestForProposalFormValues) => {
    try {
      const rfp = await createRFP(parseInt(solution_id), data)

      navigate(ROUTES.requestForProposalConfirmation(`${rfp.id}`))
    } catch (e) {
      setErrorToast()
    }
  }

  const initialValues = {
    street_address: property.formattedStreetAddress,
    street_address_2: '',
    city: property.data.city || '',
    state: property.data.state || '',
    postal_code: property.data.postal_code || '',
    first_name: user.first_name,
    last_name: user.last_name,
    phone_number: user.phone_number,
    email: user.email,
  }

  return (
    <div className="request-for-proposal-page">
      <div className="request-for-proposal-page__header">
        <MaxWidth backgroundColor="blue">
          <h1>
            Request a proposal & quote <br aria-hidden />
            from <span className="green-text">{solution.vendor.name}</span>
          </h1>
        </MaxWidth>
      </div>

      <MaxWidth>
        <div className="request-for-proposal-page__content">
          <div className="request-for-proposal-page__form-header">
            <h3 className="request-for-proposal-page__form-heading">
              Confirm your Information
            </h3>

            <p className="request-for-proposal-page__form-subheading">
              Your contact information will be given only to the service
              provider requested.
            </p>

            <p className="request-for-proposal-page__form-subheading">
              Gridly will never share your information without your knowledge
              and consent.
            </p>
          </div>

          <RequestForProposalForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
          />
        </div>
      </MaxWidth>
    </div>
  )
}
