import React from 'react'
import classnames from 'classnames'

import { GRIDLY_PROJECT_SLUGS } from '../../../constants'
import { ProjectImageByProjectSlug } from './constants'

import './ProjectTileImage.scss'

interface Props extends React.ComponentProps<'img'> {
  projectSlug: GRIDLY_PROJECT_SLUGS
  projectStatus: string | null
  projectStatusFormatted: string | null
}

export const ProjectTileImage: React.VFC<Props> = ({
  className,
  projectSlug,
  projectStatus,
  projectStatusFormatted,
  ...props
}) => {
  return (
    <div className={classnames(className, 'project-tile-image__wrapper')}>
      {projectStatus && projectStatusFormatted && (
        <>
          <span
            className={classnames('project-tile-image__status-overlay', {
              'project-tile-image__status-overlay--in-progress':
                projectStatus === 'in_progress',
              'project-tile-image__status-overlay--completed':
                projectStatus === 'completed',
            })}
          />
          <span aria-hidden className="project-tile-image__status-text">
            {projectStatusFormatted}
          </span>
        </>
      )}
      <img
        alt=""
        className="project-tile-image__image"
        src={ProjectImageByProjectSlug[projectSlug]}
        {...props}
      />
    </div>
  )
}
