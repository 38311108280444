import React from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { signIn } from '../../api'
import { useToast } from '../../context'
import { MaxWidth, PrimaryLogo, SignInForm } from '../../components'
import { EXTERNAL_ROUTES, ROUTES } from '../../routes'

import './SignInPage.scss'

export const SignInPage: React.FC = () => {
  const { setErrorToast } = useToast()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const handleSubmit = async ({ email }: { email: string }) => {
    try {
      const data = await signIn(email)
      const params = new URLSearchParams({ email })
      if (window.env.DEV_HELPER_LINK_FLAG && data.dev_helper_link) {
        params.append('helperLink', data.dev_helper_link || '#')
      }

      navigate(`${ROUTES.actionPlanRequestConfirmation}?${params}`)
    } catch (e) {
      setErrorToast()

      throw e
    }
  }

  return (
    <MaxWidth wide>
      <div className="sign-in-page">
        <a className="sign-in-page__logo-wrapper" href={EXTERNAL_ROUTES.home}>
          <PrimaryLogo className="sign-in-page__logo" mode="light" />
        </a>

        <div className="sign-in-page__sign-in-card">
          <h3>Sign In</h3>
          {searchParams.get('invalidToken') === 'true' && (
            <p>
              That link has already been used or is expired. Try signing in
              again.
            </p>
          )}
          <p>
            We&apos;ll email you a magic link for a password-free sign in to
            your account.
          </p>

          <SignInForm initialValues={{ email: '' }} onSubmit={handleSubmit} />

          <div>
            <p>Don&apos;t have an account?</p>
            <p>
              <Link to={ROUTES.root}>Enter your address to get started!</Link>
            </p>
          </div>
        </div>

        <div className="sign-in-page__title-text">
          <h2 className="sign-in-page__text">
            Your{' '}
            <span className="sign-in-page__text sign-in-page__text--green">
              key to energy independence{' '}
            </span>
            is moments away.
          </h2>
          <p className="sign-in-page__text">
            Gridly&apos;s innovative platform unlocks the mystery of home energy
            transition—guiding your journey off of fossil fuels and towards a
            net-zero home. Your home energy action plan is a step-by-step guide
            and an interactive tool-all tailored to focus on your home&apos;s
            specific requirements. Get yours today!
          </p>
        </div>
      </div>
    </MaxWidth>
  )
}
