import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ROUTES } from '../../routes'
import { PropertyDataModel } from '../../models'
import { usePropertyData, useToast } from '../../context'
import { createProperty } from '../../api'
import {
  MaxWidth,
  RequestActionPlanForm,
  RequestActionPlanFormValues,
  RenewableReadout,
  HotStepper,
  Tile,
} from '../../components'

import './RequestActionPlanPage.scss'

export const RequestActionPlanPage: React.FC = () => {
  const navigate = useNavigate()
  const { setErrorToast } = useToast()
  const { propertyData } = usePropertyData()

  const handleSubmit = async ({
    email,
    subscribe_to_email_updates,
  }: RequestActionPlanFormValues) => {
    try {
      const { dev_helper_link } = await createProperty({
        ...propertyData,
        email,
        subscribe_to_email_updates,
      })
      const params = new URLSearchParams({ email })

      if (window.env.DEV_HELPER_LINK_FLAG) {
        params.append('helperLink', dev_helper_link || '#')
      }

      navigate(`${ROUTES.actionPlanRequestConfirmation}?${params}`)
    } catch (e) {
      setErrorToast()

      throw e
    }
  }

  const propertyDataModel = new PropertyDataModel(propertyData)
  const percentRenewable = propertyDataModel.totalUtilityPercentRenewable

  return (
    <MaxWidth>
      <div className="request-action-plan-page">
        <div className="request-action-plan-page__page-heading-wrapper">
          <div className="request-action-plan-page__hot-stepper">
            <HotStepper currentStep={3} />
          </div>

          <p className="request-action-plan-page__address-line">
            {propertyDataModel.formattedSingleLineAddress}
          </p>

          <h2 className="request-action-plan-page__page-heading">
            That&apos;s it! A customized Action Plan for your home is ready.
          </h2>
        </div>

        <div className="request-action-plan-page__renewable-energy-tile-wrapper">
          <h6 className="request-action-plan-page__page-sub-heading">
            Here is a preview
          </h6>

          <Tile className="request-action-plan-page__renewable-energy-tile">
            <RenewableReadout
              className="request-action-plan-page__renewable-readout"
              percentRenewable={percentRenewable}
            />

            <div className="request-action-plan-page__renewable-percentage-summary">
              <div className="body-2">
                Based on your details, your home uses
              </div>

              <div className="request-action-plan-page__percent-renewable">
                {percentRenewable}% Renewable Energy
              </div>

              <span className="body-2">
                But don&apos;t worry, we have a plan to{' '}
              </span>

              <span className="body-2 body-2--bold">get you to 100%.</span>
            </div>
          </Tile>
        </div>

        <RequestActionPlanForm onSubmit={handleSubmit} />
      </div>
    </MaxWidth>
  )
}
