import React from 'react'

import {
  DisplaySettings,
  DisplaySettingsContext,
} from '../DisplaySettingsContext'

export const defaultDisplaySettingsContextState: DisplaySettings = {
  showFinanced: false,
}

export const DisplaySettingsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [displaySettings, _setDisplaySettings] =
    React.useState<DisplaySettings>(defaultDisplaySettingsContextState)

  const setDisplaySettings = (displaySettings: DisplaySettings) => {
    _setDisplaySettings(displaySettings)
  }

  const resetDisplaySettings = () => {
    _setDisplaySettings(defaultDisplaySettingsContextState)
  }

  return (
    <DisplaySettingsContext.Provider
      value={{
        displaySettings,
        setDisplaySettings,
        resetDisplaySettings,
      }}
    >
      {children}
    </DisplaySettingsContext.Provider>
  )
}
