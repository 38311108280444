import React from 'react'

import { PropertyFormValues } from '../../../types'
import { ROUTES } from '../../../routes'
import {
  GRIDLY_CARBON_IMPACT_DESCRIPTIONS,
  GRIDLY_HVAC_PRODUCT_NAMES,
  GRIDLY_HVAC_PRODUCT_TITLES,
  GRIDLY_PROJECT_SLUGS,
  GRIDLY_PROJECT_NAMES,
} from '../../../constants'
import { useToast } from '../../../context'
import { formatDollars } from '../../../helpers'
import { useProject, useProperty } from '../../../api'
import { Project, HvacForm, RenewablePie } from '../../../components'
import { LinkButton } from '../../../components/LinkButton'

export const HVACPage: React.FC = () => {
  const { setSuccessToast, setErrorToast } = useToast()
  const {
    error: errorLoadingProperty,
    isLoading: isLoadingProperty,
    property,
    updateProperty,
  } = useProperty()
  const {
    error: errorLoadingProject,
    isLoading: isLoadingProject,
    project,
  } = useProject(GRIDLY_PROJECT_SLUGS.HVAC)

  if (errorLoadingProperty || errorLoadingProject) {
    return (
      <>Error {errorLoadingProject?.message || errorLoadingProject?.message}</>
    )
  }

  if (isLoadingProperty || isLoadingProject) {
    return <>Loading...</>
  }

  const handleSubmit = async (propertyData: PropertyFormValues) => {
    if (property.id) {
      try {
        await updateProperty(property.id, propertyData)

        setSuccessToast()
      } catch (e) {
        setErrorToast()

        throw e
      }
    }
  }

  return (
    <>
      <Project.Header
        title={GRIDLY_PROJECT_NAMES.HVAC}
        recommendationText={`Convert from a ${property.data.heating_fuel} ${property.data.heating_method} to an electric heat pump HVAC system.`}
        whyText="Eliminate your biggest use of fossil fuels and related costs. Leverage rebates/incentives."
      >
        <Project.Highlight
          description={`Lock in available rebates up to ${formatDollars(
            parseFloat(project.rebate ?? '0'),
            { fractionDigits: 0, format: 'longhand' }
          )}.*`}
          takeAway={formatDollars(parseFloat(project.rebate ?? '0'), {
            fractionDigits: 1,
            format: 'shorthand',
          })}
          takeAwayStyle="solid"
        />

        <Project.Highlight
          description={`Save up to ${formatDollars(
            parseFloat(project.annual_savings || '0'),
            { fractionDigits: 0, format: 'longhand' }
          )} in energy costs per year.*`}
          takeAway={formatDollars(parseFloat(project.annual_savings || '0'), {
            fractionDigits: 1,
            format: 'shorthand',
          })}
          takeAwayStyle="outline"
        />

        {project.carbon_impact && (
          <Project.Highlight
            description={
              GRIDLY_CARBON_IMPACT_DESCRIPTIONS[project.carbon_impact]
            }
            takeAway={project.carbon_impact}
            takeAwayStyle="outline"
          />
        )}

        <Project.Highlight
          description={`Increase your renewable energy from ${property.totalUtilityPercentRenewable}% to ${project.future_total_utility_percent_renewable}%`}
          takeAway={
            <RenewablePie
              percentRenewable={parseFloat(
                project.future_total_utility_percent_renewable || '0'
              )}
            />
          }
        />
      </Project.Header>

      <Project.Sandbox
        title="Do these values look correct to you?"
        subtitle="We use this information to recommend the best heating & cooling system for you home."
      >
        <HvacForm
          initialValues={property.toFormValues()}
          onSubmit={handleSubmit}
        />
      </Project.Sandbox>

      {project.product_recommendation && (
        <Project.Recommendation
          title={
            GRIDLY_HVAC_PRODUCT_TITLES[
              project.product_recommendation as GRIDLY_HVAC_PRODUCT_NAMES
            ]
          }
          propertyIsEligible
          project={project}
          exploreLink={
            <LinkButton
              to={ROUTES.projectExplorePages[GRIDLY_PROJECT_SLUGS.HVAC]}
              variant="blue"
            >
              Explore Your Options
            </LinkButton>
          }
        />
      )}

      <Project.Education project={project} />
    </>
  )
}
