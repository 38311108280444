import React from 'react'
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik'

import {
  ElectricUtility,
  PropertyFormValues,
  UtilityData,
} from '../../../types'
import { PropertyDataModel } from '../../../models'
import {
  CurrencyMaskedInput,
  UtilityUsageFieldset,
  ReadOnlyField,
  SelectInput,
  SubmitButton,
  TextInput,
  ThousandsMaskedInput,
  AutoSave,
} from '../../formElements'
import './UtilityUsageForm.scss'
import { formatDollars, constantToOptions } from '../../../helpers'
import { GRIDLY_NATURAL_GAS_PROVIDERS } from '../../../constants'
import { estimateUsageFromExpenditure } from './helpers'
import { buildSchema } from './schema'

const communitySolarOption = {
  value: 'communitysolar',
  label: 'Community Solar',
}

const municipalOption = {
  value: 'municipal',
  label: 'Municipal/Town Managed',
}

const naturalGasProviderOptions = [
  ...constantToOptions(GRIDLY_NATURAL_GAS_PROVIDERS),
  municipalOption,
]

export interface Props {
  initialValues: PropertyFormValues
  onSubmit: (data: PropertyFormValues) => Promise<void>
  electricUtilities: ElectricUtility[]
  utilityData: UtilityData
  autoSave?: boolean
}

export const UtilityUsageForm: React.VFC<Props> = ({
  initialValues,
  onSubmit,
  electricUtilities,
  utilityData,
  autoSave = false,
}) => {
  const propertyDataModel = new PropertyDataModel(initialValues)

  const electricUtilityOptions = [
    ...electricUtilities.map(({ external_id, name }) => ({
      value: external_id,
      label: name,
    })),
    communitySolarOption,
    municipalOption,
  ]

  const handleSubmit = async (
    data: PropertyFormValues,
    helpers: FormikHelpers<PropertyFormValues>
  ) => {
    const electricUtilityOption =
      electricUtilityOptions.find(
        (option) => option.value === data.electric_utility_external_id
      ) || municipalOption

    try {
      await onSubmit({
        ...data,
        electric_utility_vendor: electricUtilityOption.label,
      })
    } catch (e) {
      helpers.resetForm()
    }
  }

  const sumUtilityValues = (values: PropertyFormValues) => {
    return Math.round(
      parseFloat(values.electric_utility_annual_expenditure) +
        parseFloat(values.gas_utility_annual_expenditure) +
        parseFloat(values.oil_utility_annual_expenditure) +
        parseFloat(values.propane_utility_annual_expenditure)
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={true}
      onSubmit={handleSubmit}
      validationSchema={buildSchema(propertyDataModel)}
    >
      {({ values, setFieldValue }) => (
        <Form
          aria-label="Utility Usage Form"
          className="utility-usage-form"
          role="form"
        >
          <ErrorMessage name="electric_utility_external_id" />
          <UtilityUsageFieldset
            legend="Electricity"
            explainer={
              values.community_solar_customer
                ? '100% Renewable'
                : `${utilityData.electricity_percent_renewable}% Renewable, ${utilityData.electricity_percent_nonrenewable}% Non-renewable`
            }
          >
            <Field
              as={SelectInput}
              name="electric_utility_external_id"
              label="Electricity Vendor"
              options={electricUtilityOptions}
              onChangeEffect={({
                value,
              }: {
                label: string
                value: string | boolean
              }) => {
                setFieldValue(
                  'community_solar_customer',
                  value === communitySolarOption.value,
                  false
                )
              }}
            />

            <ReadOnlyField
              label="Price Per kWh"
              value={formatDollars(utilityData.electricity_price, {
                fractionDigits: 4,
              })}
            />

            <Field
              as={ThousandsMaskedInput}
              readOnly={true}
              label="kWh Per Year"
              name="electric_utility_annual_usage"
              value={estimateUsageFromExpenditure(
                values.electric_utility_annual_expenditure,
                utilityData.electricity_price
              )}
            />

            <Field
              as={CurrencyMaskedInput}
              name="electric_utility_annual_expenditure"
              label="Estimated Yearly Bill"
              options={{
                numeralDecimalMark: '',
                numeralDecimalScale: 0,
              }}
            />
          </UtilityUsageFieldset>

          {propertyDataModel.hasNaturalGas && (
            <UtilityUsageFieldset
              legend="Natural Gas"
              explainer="100% Non-renewable"
            >
              <Field
                as={SelectInput}
                name="gas_utility_vendor"
                label="Natural Gas Vendor"
                options={naturalGasProviderOptions}
              />

              <ReadOnlyField
                label="Price Per Therm"
                value={formatDollars(utilityData.natural_gas_price, {
                  fractionDigits: 2,
                })}
              />

              <Field
                as={ThousandsMaskedInput}
                readOnly={true}
                label="Therms Per Year"
                name="gas_utility_annual_usage"
                value={estimateUsageFromExpenditure(
                  values.gas_utility_annual_expenditure,
                  utilityData.natural_gas_price
                )}
              />

              <Field
                as={CurrencyMaskedInput}
                name="gas_utility_annual_expenditure"
                label="Estimated Yearly Bill"
                options={{
                  numeralDecimalMark: '',
                  numeralDecimalScale: 0,
                }}
              />
            </UtilityUsageFieldset>
          )}

          {propertyDataModel.hasHeatingOil && (
            <UtilityUsageFieldset
              legend="Home Heating Oil"
              explainer="100% Non-renewable"
            >
              <Field
                as={TextInput}
                name="oil_utility_vendor"
                label="Heating Oil Vendor (Optional)"
              />

              <ReadOnlyField
                label="Dollars per Gallon"
                value={formatDollars(utilityData.heating_oil_price, {
                  fractionDigits: 2,
                })}
              />

              <Field
                as={ThousandsMaskedInput}
                readOnly={true}
                label="Gallons Per Year"
                name="oil_utility_annual_usage"
                value={estimateUsageFromExpenditure(
                  values.oil_utility_annual_expenditure,
                  utilityData.heating_oil_price
                )}
              />

              <Field
                as={CurrencyMaskedInput}
                name="oil_utility_annual_expenditure"
                label="Estimated Yearly Bill"
                options={{
                  numeralDecimalMark: '',
                  numeralDecimalScale: 0,
                }}
              />
            </UtilityUsageFieldset>
          )}

          {propertyDataModel.hasPropane && (
            <UtilityUsageFieldset
              legend="Propane"
              explainer="100% Non-renewable"
            >
              <Field
                as={TextInput}
                name="propane_utility_vendor"
                label="Propane Vendor (Optional)"
              />

              <ReadOnlyField
                label="Dollars per Gallon"
                value={formatDollars(utilityData.propane_price, {
                  fractionDigits: 2,
                })}
              />

              <Field
                as={ThousandsMaskedInput}
                readOnly={true}
                label="Gallons Per Year"
                name="propane_utility_annual_usage"
                value={estimateUsageFromExpenditure(
                  values.propane_utility_annual_expenditure,
                  utilityData.propane_price
                )}
              />

              <Field
                as={CurrencyMaskedInput}
                name="propane_utility_annual_expenditure"
                label="Estimated Yearly Bill"
                options={{
                  numeralDecimalMark: '',
                  numeralDecimalScale: 0,
                }}
              />
            </UtilityUsageFieldset>
          )}

          <div className="utility-usage-form__summary-section">
            <dl className="utility-usage-form__total-expenditure">
              <dt className="utility-usage-form__total-expenditure-label">
                Total Yearly Utility Spend
              </dt>

              <dd className="utility-usage-form__total-expenditure-amount">
                {formatDollars(sumUtilityValues(values), {
                  fractionDigits: 0,
                  format: 'longhand',
                })}
              </dd>
            </dl>
          </div>

          {autoSave ? (
            <AutoSave />
          ) : (
            <SubmitButton className="utility-usage-form__submit-button">
              Let&apos;s Keep Going!
            </SubmitButton>
          )}
        </Form>
      )}
    </Formik>
  )
}
