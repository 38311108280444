import React from 'react'

import { Project } from '../../../types'
import { formatDollars } from '../../../helpers'
import { Accordion } from '../../Accordion'
import { MaxWidth } from '../../MaxWidth'
import { LabeledList } from '../../LabeledList'
import { Markdown } from '../../Markdown'

import './Education.scss'

type Props = {
  project: Project
  hideIncentives?: boolean
}

export const Education: React.VFC<Props> = ({ hideIncentives, project }) => {
  const incentiveListLabel = project.incentives_total
    ? `You may be eligible for up to ${formatDollars(project.incentives_total, {
        fractionDigits: 0,
        format: 'longhand',
      })} of incentives.`
    : 'You may eligible for rebates from your energy provider'

  const showIncentives = !hideIncentives && project.has_incentives

  return (
    <MaxWidth backgroundColor="blue">
      <div className="education">
        {showIncentives && (
          <Accordion.Wrapper header={incentiveListLabel} id="incentives">
            {project.incentives.map((incentive) => (
              <Accordion.Item
                key={incentive.heading}
                header={incentive.heading}
                subheader={incentive.subheading}
              >
                {Boolean(incentive.eligibility_criteria.length) && (
                  <LabeledList
                    label="Eligibility"
                    ordered={incentive.eligibility_criteria.length > 1}
                  >
                    {incentive.eligibility_criteria.map((criteria) => (
                      <li key={criteria}>
                        <Markdown>{criteria}</Markdown>
                      </li>
                    ))}
                  </LabeledList>
                )}

                {Boolean(incentive.you_will_need.length) && (
                  <LabeledList label="You/Your Contractor Will Need">
                    {incentive.you_will_need.map((need) => (
                      <li key={need}>
                        <Markdown>{need}</Markdown>
                      </li>
                    ))}
                  </LabeledList>
                )}

                {Boolean(incentive.how_to_collect.length) && (
                  <LabeledList label="How to Collect">
                    {incentive.how_to_collect.map((step) => (
                      <li key={step}>
                        <Markdown>{step}</Markdown>
                      </li>
                    ))}
                  </LabeledList>
                )}
              </Accordion.Item>
            ))}
          </Accordion.Wrapper>
        )}

        {project.has_frequently_asked_questions && (
          <Accordion.Wrapper header="Frequently Asked Questions">
            {project.frequently_asked_questions.map(({ question, answer }) => (
              <Accordion.Item key={question} header={question}>
                <Markdown>{answer}</Markdown>
              </Accordion.Item>
            ))}
          </Accordion.Wrapper>
        )}
      </div>
    </MaxWidth>
  )
}
