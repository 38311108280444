import React from 'react'

import { PrimaryLogo } from '../../media'
import { StartOverButton } from '../../StartOverButton'
import './HomeDetailsHeader.scss'

export const HomeDetailsHeader: React.FC = () => (
  <div className="home-details-header">
    <PrimaryLogo className="home-details-header__logo" mode="dark" />
    <StartOverButton />
  </div>
)
