import {
  GRIDLY_HEATING_FUEL_CODES,
  GRIDLY_INSULATION_LEVELS,
} from '../../constants'
import { GRIDLY_QUALITIES } from '../../constants/GridlyQualities'
import { formatDollars } from '../../helpers'
import { Property, PropertyFormValues } from '../../types'

export class PropertyDataModel {
  constructor(public data: Property) {}

  get id(): number | undefined {
    if (Object.hasOwn(this.data, 'id')) {
      return (this.data as Property).id
    }

    return undefined
  }

  get postalCode(): string | null {
    return this.data.postal_code
  }

  get state(): string | null {
    return this.data.state
  }

  get formattedStreetAddress(): string {
    const { street_number, street_name } = this.data
    return `${street_number} ${street_name}`
  }

  get formattedCityStateZip(): string {
    const { city, state, postal_code } = this.data
    return `${city}, ${state} ${postal_code}`
  }

  get formattedSingleLineAddress(): string {
    return `${this.formattedStreetAddress}, ${this.formattedCityStateZip}`
  }

  get formattedYearBuilt(): string {
    return `Built in ${this.data.year_built}`
  }

  get formattedSqft(): string {
    return `${this.data.total_sqft} sq ft.`
  }

  get formattedNumBedrooms(): string {
    const count = this.data.num_bedrooms ?? 0

    return `${count} Bedroom${count > 1 ? 's' : ''}`
  }

  get formattedNumTotalBathrooms(): string {
    return `${this.data.num_full_bathrooms} Full, ${this.data.num_partial_bathrooms} Partial Bath`
  }

  get formattedNumStories(): string {
    return `${this.data.num_stories} Stories`
  }

  get formattedBasement(): string {
    return this.data.basement_type || ''
  }

  get formattedHomeHeating(): string {
    return `${this.data.heating_fuel} - ${this.data.heating_method}`
  }

  get formattedHomeCooling(): string {
    return `${this.data.cooling_fuel} - ${this.data.cooling_method}`
  }

  get formattedWaterHeating(): string {
    return `${this.data.hot_water_fuel} - ${this.data.hot_water_type}`
  }

  get formattedAnnualSpendElectric(): string {
    if (
      !this.data.electric_utility_vendor ||
      !this.data.electric_utility_annual_expenditure
    ) {
      return 'N/A'
    }

    return `${this.data.electric_utility_vendor}: ${formatDollars(
      parseFloat(this.data.electric_utility_annual_expenditure),
      { fractionDigits: 0 }
    )}/year`
  }

  get formattedAnnualSpendHeatingOil(): string {
    if (
      !this.data.oil_utility_vendor ||
      !this.data.oil_utility_annual_expenditure
    ) {
      return 'N/A'
    }

    return `${this.data.oil_utility_vendor}: ${formatDollars(
      parseFloat(this.data.oil_utility_annual_expenditure),
      { fractionDigits: 0 }
    )}/year`
  }

  get formattedAnnualSpendNaturalGas(): string {
    if (
      !this.data.gas_utility_vendor ||
      !this.data.gas_utility_annual_expenditure
    ) {
      return 'N/A'
    }

    return `${this.data.gas_utility_vendor}: ${formatDollars(
      parseFloat(this.data.gas_utility_annual_expenditure),
      { fractionDigits: 0 }
    )}/year`
  }

  get formattedAnnualSpendPropane(): string {
    if (
      !this.data.propane_utility_vendor ||
      !this.data.propane_utility_annual_expenditure
    ) {
      return 'N/A'
    }

    return `${this.data.propane_utility_vendor}: ${formatDollars(
      parseFloat(this.data.propane_utility_annual_expenditure),
      { fractionDigits: 0 }
    )}/year`
  }

  get hotWaterHeaterFuel(): string {
    if (!this.data.hot_water_fuel) {
      return 'N/A'
    }

    return this.data.hot_water_fuel
  }

  get electricUtilityVendor(): string {
    return this.data.electric_utility_vendor
  }

  get hasNaturalGas(): boolean {
    const { cooling_fuel, heating_fuel, hot_water_fuel } = this.data

    return [cooling_fuel, heating_fuel, hot_water_fuel].includes(
      GRIDLY_HEATING_FUEL_CODES.NATURAL_GAS
    )
  }

  get hasHeatingOil(): boolean {
    const { heating_fuel, hot_water_fuel } = this.data

    return [heating_fuel, hot_water_fuel].includes(
      GRIDLY_HEATING_FUEL_CODES.HEATING_OIL
    )
  }

  get hasPropane(): boolean {
    const { cooling_fuel, heating_fuel, hot_water_fuel } = this.data

    return [cooling_fuel, heating_fuel, hot_water_fuel].includes(
      GRIDLY_HEATING_FUEL_CODES.PROPANE
    )
  }

  get electricUtilityAnnualExpenditure(): `${number}` {
    return this.data.electric_utility_annual_expenditure || '0.0'
  }

  get electricUtilityMonthlyExpenditure(): `${number}` {
    return `${parseFloat(this.electricUtilityAnnualExpenditure) / 12}`
  }

  get electricUtilityAnnualUsage(): `${number}` {
    return this.data.electric_utility_annual_usage || '0'
  }

  get electricUtilityMonthlyUsage(): `${number}` {
    return `${parseFloat(this.electricUtilityAnnualUsage) / 12}`
  }

  get gasUtilityAnnualExpenditure(): `${number}` {
    return this.data.gas_utility_annual_expenditure || '0.0'
  }

  get gasUtilityAnnualUsage(): `${number}` {
    return this.data.gas_utility_annual_usage || '0'
  }

  get oilUtilityAnnualExpenditure(): `${number}` {
    return this.data.oil_utility_annual_expenditure || '0.0'
  }

  get oilUtilityAnnualUsage(): `${number}` {
    return this.data.oil_utility_annual_usage || '0'
  }

  get propaneUtilityAnnualExpenditure(): `${number}` {
    return this.data.propane_utility_annual_expenditure || '0.0'
  }

  get propaneUtilityAnnualUsage(): `${number}` {
    return this.data.propane_utility_annual_usage || '0'
  }

  get totalUtilityAnnualExpenditure(): number {
    const totalYearlySpend =
      Math.floor(parseFloat(this.electricUtilityAnnualExpenditure)) +
      Math.floor(parseFloat(this.gasUtilityAnnualExpenditure)) +
      Math.floor(parseFloat(this.oilUtilityAnnualExpenditure)) +
      Math.floor(parseFloat(this.propaneUtilityAnnualExpenditure))

    return totalYearlySpend
  }

  get totalUtilityPercentRenewable(): number {
    return parseFloat(this.data.total_utility_percent_renewable || '0')
  }

  get hasSolarPanels(): boolean {
    return this.data.solar_panels
  }

  get hasCommunitySolar(): boolean {
    return this.data.community_solar_customer
  }

  get hasRenewableEnergy(): boolean {
    return this.data.wattbuy_customer
  }

  get hasAlternativeEnergy(): boolean {
    return [
      this.hasSolarPanels,
      this.hasCommunitySolar,
      this.hasRenewableEnergy,
    ].some(Boolean)
  }

  get isEligibleForSolarPanels(): boolean {
    return !this.hasAlternativeEnergy
  }

  get isEligibleForCommunitySolar(): boolean {
    return !this.hasAlternativeEnergy
  }

  get isEligibleForRenewableEnergy(): boolean {
    return !this.hasAlternativeEnergy
  }

  get isEligibleForSmartThermostats(): boolean {
    return !this.data.smart_thermostat
  }

  get isEligibleForWholeHomeMonitoring(): boolean {
    return true
  }

  get isEligibleForInsulation(): boolean {
    return true
  }

  toFormValues(): PropertyFormValues {
    return {
      street_number: this.data.street_number || 0,
      street_name: this.data.street_name || '',
      city: this.data.city || '',
      state: this.data.state || '',
      postal_code: this.data.postal_code || '',
      external_year_built: this.data.external_year_built,
      year_built: this.data.year_built || 0,
      external_num_full_bathrooms: this.data.external_num_full_bathrooms,
      num_full_bathrooms: this.data.num_full_bathrooms || 0,
      external_num_partial_bathrooms: this.data.external_num_partial_bathrooms,
      num_partial_bathrooms: this.data.num_partial_bathrooms || 0,
      external_num_bedrooms: this.data.external_num_bedrooms,
      num_bedrooms: this.data.num_bedrooms || 0,
      num_residents: this.data.num_residents || 0,
      external_num_stories: this.data.external_num_stories,
      num_stories: this.data.num_stories || 0,
      external_total_sqft: this.data.external_total_sqft,
      total_sqft: this.data.total_sqft || 0,
      external_basement_type: this.data.external_basement_type,
      basement_type: this.data.basement_type || '',
      external_basement_sqft: this.data.external_basement_sqft,
      basement_sqft: this.data.basement_sqft || 0,
      external_heating_type: this.data.external_heating_type,
      heating_method: this.data.heating_method || '',
      heating_space: this.data.heating_space || '',
      heating_distribution: this.data.heating_distribution || '',
      external_heating_fuel: this.data.external_heating_fuel,
      heating_fuel: this.data.heating_fuel || '',
      external_cooling_type: this.data.external_cooling_type,
      cooling_method: this.data.cooling_method || '',
      cooling_distribution: this.data.cooling_distribution || '',
      external_cooling_fuel: this.data.external_cooling_fuel,
      cooling_fuel: this.data.cooling_fuel || '',
      external_energy_type: this.data.external_energy_type,
      energy_type: this.data.energy_type || '',
      external_hot_water_fuel: this.data.external_hot_water_fuel,
      hot_water_fuel: this.data.hot_water_fuel || '',
      external_hot_water_type: this.data.external_hot_water_type,
      hot_water_type: this.data.hot_water_type || '',
      smart_thermostat: this.data.smart_thermostat || false,
      solar_panels: this.data.solar_panels || false,
      electric_utility_external_id:
        this.data.electric_utility_external_id || '',
      electric_utility_vendor: this.data.electric_utility_vendor || '',
      electric_utility_external_data_source:
        this.data.electric_utility_external_data_source,
      electric_utility_annual_expenditure:
        this.electricUtilityAnnualExpenditure,
      electric_utility_annual_usage: this.electricUtilityAnnualUsage,
      gas_utility_annual_expenditure: this.gasUtilityAnnualExpenditure,
      gas_utility_vendor: this.data.gas_utility_vendor || '',
      gas_utility_annual_usage: this.gasUtilityAnnualUsage,
      oil_utility_annual_expenditure: this.oilUtilityAnnualExpenditure,
      oil_utility_vendor: this.data.oil_utility_vendor || '',
      oil_utility_annual_usage: this.oilUtilityAnnualUsage,
      propane_utility_vendor: this.data.propane_utility_vendor || '',
      propane_utility_annual_expenditure: this.propaneUtilityAnnualExpenditure,
      propane_utility_annual_usage: this.propaneUtilityAnnualUsage,
      attic_insulation_level:
        this.data.attic_insulation_level || GRIDLY_INSULATION_LEVELS.AVERAGE,
      wall_insulation_level:
        this.data.wall_insulation_level || GRIDLY_INSULATION_LEVELS.AVERAGE,
      basement_insulation_level:
        this.data.basement_insulation_level || GRIDLY_INSULATION_LEVELS.AVERAGE,
      insulation_type: this.data.insulation_type || '',
      window_type: this.data.window_type || '',
      community_solar_customer: this.data.community_solar_customer,
      wattbuy_customer: this.data.wattbuy_customer,
      c_wire: this.data.c_wire,
      num_thermostats: this.data.num_thermostats || 0,
      hot_water_tank_location: this.data.hot_water_tank_location || '',
      hot_water_tank_size: this.data.hot_water_tank_size || '',
      back_to_back_showers: this.data.back_to_back_showers || 0,
      washing_machine_style: this.data.washing_machine_style || '',
      dishwasher: this.data.dishwasher || false,
      total_utility_percent_renewable:
        this.data.total_utility_percent_renewable || '0',
      ceiling_height: this.data.ceiling_height || '12.0',
      ducting: this.data.ducting || false,
      num_rooms: this.data.num_rooms || 0,
      home_assessment: this.data.home_assessment || false,
      roof_condition: this.data.roof_condition || GRIDLY_QUALITIES.AVERAGE,
      roof_exposure: this.data.roof_exposure || GRIDLY_QUALITIES.EXCELLENT,
      solar_kwh_need: this.data.solar_kwh_need || 'current',
    }
  }
}
