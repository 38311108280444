import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  RequestForProposalForm,
  RequestForProposalFormValues,
} from '../../../../components'
import { createRFP, useProject, useProperty, useUser } from '../../../../api'
import { GRIDLY_PROJECT_SLUGS } from '../../../../constants'
import { ROUTES } from '../../../../routes'
import { useToast } from '../../../../context'

export const InsulationExplorePageCapeCod: React.FC = () => {
  const { isLoading: isLoadingUser, error: errorLoadingUser, user } = useUser()
  const {
    isLoading: isLoadingProperty,
    error: errorLoadingProperty,
    property,
  } = useProperty()
  const {
    error: errorLoadingProject,
    isLoading: isLoadingProject,
    project,
  } = useProject(GRIDLY_PROJECT_SLUGS.INSULATION)
  const navigate = useNavigate()
  const { setErrorToast } = useToast()

  if (isLoadingUser || isLoadingProperty || isLoadingProject) {
    return <>Loading...</>
  }

  if (errorLoadingUser || errorLoadingProperty || errorLoadingProject) {
    return (
      <>
        Error:{' '}
        {errorLoadingUser?.message ||
          errorLoadingProperty?.message ||
          errorLoadingProject?.message}
      </>
    )
  }
  const solution = project.solutions.find(
    (s) => s.project_slug === GRIDLY_PROJECT_SLUGS.INSULATION
  )

  const handleSubmit = async (data: RequestForProposalFormValues) => {
    try {
      if (!solution) {
        throw new Error('solution not available')
      }
      const rfp = await createRFP(solution.id, data)

      navigate(ROUTES.requestForProposalConfirmation(`${rfp.id}`))
    } catch (e) {
      setErrorToast()
    }
  }

  const initialValues = {
    street_address: property.formattedStreetAddress,
    street_address_2: '',
    city: property.data.city || '',
    state: property.data.state || '',
    postal_code: property.data.postal_code || '',
    first_name: user.first_name,
    last_name: user.last_name,
    phone_number: user.phone_number,
    email: user.email,
  }

  return (
    <div className="insulation-explore">
      <RequestForProposalForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </div>
  )
}
