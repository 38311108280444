import React from 'react'

import { FacebookIcon } from '../../media/FacebookIcon'
import { InstagramIcon } from '../../media/InstagramIcon'
import { LinkedInIcon } from '../../media/LinkedInIcon'
import { TwitterIcon } from '../../media/TwitterIcon'
import './SocialNav.scss'

export const SocialNav: React.FC = () => {
  return (
    <nav className="social-nav">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/GoGridly"
      >
        <FacebookIcon />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.twitter.com/gogridly"
      >
        <TwitterIcon />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.linkedin.com/company/gogridly/about"
      >
        <LinkedInIcon />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/gogridly"
      >
        <InstagramIcon />
      </a>
    </nav>
  )
}
