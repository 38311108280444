import { GRIDLY_PROJECT_SLUGS } from '../constants'

export const routes = {
  newProperty: '/api/properties/new.json',
  electricUtilities: '/api/electric_utilities.json',
  properties: '/api/properties.json',
  projects: (projectSlug: GRIDLY_PROJECT_SLUGS) =>
    `/api/projects${`/${projectSlug}`}.json`,
  solution: (id: number) => `/api/solutions/${id}.json`,
  projectSolutions: (id?: number) =>
    `/api/project_solutions${id ? `/${id}` : ''}.json`,
  property: (id: number) => `/api/properties/${id}.json`,
  actionPlan: (id: number) => `/api/properties/${id}/action_plan.json`,
  renewableEnergyPlans: (id: number) =>
    `/api/properties/${id}/renewable_energy_plans.json`,
  utilities: '/api/utilities.json',
  currentUser: '/api/current_user.json',
  signIn: '/users/sign_in.json',
  signOut: '/users/sign_out.json',
}
