import React from 'react'

import './EstimatesTable.scss'

interface Props extends React.PropsWithChildren {
  heading: string
  headerRightContent?: React.ReactNode
}

export const EstimatesTable: React.FC<Props> = ({
  children,
  heading,
  headerRightContent,
}) => (
  <table>
    <thead>
      <tr>
        <td>
          <h6>{heading}</h6>
        </td>

        <td>{headerRightContent}</td>
      </tr>
    </thead>

    <tbody>{children}</tbody>
  </table>
)
