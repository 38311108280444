import React, { useState } from 'react'

import { ToastContext } from '../ToastContext'
import { ToastStatus } from '../../../types'
import { Toast } from '../../../components/Toast'

export const ToastProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [hidden, setHidden] = useState(true)
  const [_message, setMessage] = useState('')
  const [status, setStatus] = useState(ToastStatus.SUCCESS)

  const setToast = (message: string, status: ToastStatus) => {
    setStatus(status)
    setMessage(message)
    setHidden(false)
    setTimeout(() => setHidden(true), 3000)
  }

  const setSuccessToast = (message = 'Your changes were saved.') =>
    setToast(message, ToastStatus.SUCCESS)

  const setErrorToast = (message = 'Your changes could not be saved.') =>
    setToast(message, ToastStatus.ERROR)

  const toastProps = { hidden, message: _message, status }

  return (
    <ToastContext.Provider value={{ setSuccessToast, setErrorToast }}>
      {children}

      <Toast {...toastProps} />
    </ToastContext.Provider>
  )
}
