import React from 'react'
import { Link } from 'react-router-dom'

import { MaxWidth } from '../../MaxWidth'
import { ProjectRecommendationImage } from '../../media/ProjectRecommendationImage'
import { GRIDLY_PROJECT_SLUGS } from '../../../constants'
import { ROUTES } from '../../../routes/constants'

import './VendorListLayout.scss'

interface Props extends React.PropsWithChildren {
  heading: string
  subheading: string
  projectSlug: GRIDLY_PROJECT_SLUGS
  InfoTile: React.FC
}

export const VendorListLayout: React.FC<Props> = ({
  children,
  heading,
  subheading,
  projectSlug,
  InfoTile,
}) => (
  <div className="vendor-list-layout">
    <MaxWidth backgroundColor="blue" wide>
      <div className="vendor-list-layout__header">
        <h1 className="vendor-list-layout__heading">{heading}</h1>

        <p className="vendor-list-layout__subheading">{subheading}</p>

        <ProjectRecommendationImage
          className="vendor-list-layout__image"
          alt=""
          projectSlug={projectSlug}
        />
      </div>
    </MaxWidth>

    <MaxWidth wide>
      <div className="vendor-list-layout__content">
        <div className="vendor-list-layout__content--left">
          {children}

          <Link
            className="vendor-list-layout__return-to-ap-link"
            to={ROUTES.actionPlan}
          >
            Return to Action Plan
          </Link>
        </div>

        <div className="vendor-list-layout__content--right">
          <InfoTile />
        </div>
      </div>
    </MaxWidth>
  </div>
)
