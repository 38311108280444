import React from 'react'

import { Property } from '../../types'

export type PropertyDataContextValue = {
  propertyData: Property
  propertyDataHasBeenSet: boolean
  setPropertyData: (propertyData: Property) => void
  resetPropertyData: () => void
}

export const PropertyDataContext = React.createContext<
  PropertyDataContextValue | undefined
>(undefined)
