import React from 'react'

import { PropertyDataContext } from '../PropertyDataContext'

export const usePropertyData = () => {
  const ctx = React.useContext(PropertyDataContext)
  if (ctx === undefined) {
    throw new Error(
      'usePropertyData hook may only be used within PropertyDataContext'
    )
  }

  return ctx
}
