import React from 'react'
import { createPortal } from 'react-dom'
import classnames from 'classnames'

import { MaxWidth } from '../MaxWidth'
import { Checkmark, Flag } from '../media'
import { ToastStatus } from '../../types'
import './Toast.scss'

type ToastProps = {
  hidden: boolean
  message: string
  status: ToastStatus
}

export const Toast: React.VFC<ToastProps> = ({ hidden, message, status }) => {
  return createPortal(
    <MaxWidth>
      <div
        aria-hidden={hidden}
        aria-live="polite"
        role="alert"
        className={classnames([
          'toast',
          { 'toast--hidden': hidden },
          { 'toast--success': status === ToastStatus.SUCCESS },
          { 'toast--error': status === ToastStatus.ERROR },
        ])}
      >
        {status === ToastStatus.SUCCESS ? <Checkmark /> : <Flag />} {message}
      </div>
    </MaxWidth>,
    document.body
  )
}
