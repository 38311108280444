import axios from 'axios'

import { ProjectSolution, User } from '../../types'
import { routes } from '../constants'

export type RFPHomeOwnerData = Pick<
  User,
  'first_name' | 'last_name' | 'phone_number'
> & {
  street_address_2: string
}

export type CreateRFP = (
  solution_id: number,
  rfpFormData: RFPHomeOwnerData
) => Promise<ProjectSolution>

export const createRFP: CreateRFP = async (solution_id, rfpFormData) => {
  const resp = await axios.post<ProjectSolution>(routes.projectSolutions(), {
    ...rfpFormData,
    solution_id,
  })

  return resp.data
}
