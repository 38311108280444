import React from 'react'

import { MailToGridlyLink } from '../../components/MailToGridlyLink'
import { formatDollars } from '../../helpers'

export const TermsAndConditionsPage: React.FC = () => {
  return (
    <>
      <h2>Gridly Terms of Service</h2>

      <p>Last Updated: October 18, 2022</p>

      <h5>OVERVIEW</h5>
      <p>
        Gridly LLC. (together with our affiliates, “Gridly”, “we”, “our” or
        “us”) offers a platform and related products, services, content and
        features through our website (the “Gridly Site”), and social media
        pages. The Gridly Site and Gridly-controlled social media pages are
        collectively called the “Services.”
      </p>
      <p>
        By visiting, browsing, or using the Services in any way, users (“user,”
        “you” or “your”) accept and agree to be bound by these Terms of Service
        (“Terms”), which form a binding agreement between you and Gridly. If you
        do not wish to be bound by these Terms, you may not access or use the
        Services.
      </p>
      <p>
        THESE TERMS CONTAIN AN <strong>AGREEMENT TO ARBITRATE</strong>, WHICH
        (I) REQUIRES THAT YOU AND GRIDLY ARBITRATE CERTAIN CLAIMS BY BINDING,
        INDIVIDUAL ARBITRATION INSTEAD OF GOING TO COURT; AND (II) LIMITS CLASS
        ACTION CLAIMS, UNLESS YOU OPT OUT OF THE AGREEMENT TO ARBITRATE AS
        DESCRIBED IN THE “AGREEMENT TO ARBITRATE; WAIVER OF CLASS ACTION”
        SECTION BELOW.
      </p>

      <h5>CHANGES TO THESE TERMS AND THE SERVICES</h5>
      <p>
        We may revise and update these Terms from time to time in our sole
        discretion. All changes are effective immediately on the date that they
        are posted to the Gridly Site and/or otherwise made available through
        the Services, and apply to all access to and use of the Services
        thereafter. Your continued use of the Services following the posting of
        revised Terms means that you accept and agree to the changes.
      </p>
      <p>
        Because we are always evolving the Services we may change or discontinue
        all or any part of the Services, at any time and without notice to you,
        in our sole discretion.
      </p>

      <h5>WHO MAY USE THE SERVICES</h5>
      <p>
        We may, in our sole discretion, refuse to offer the Services to any
        person or entity at any time. You are solely responsible for ensuring
        that you comply with all laws, rules and regulations applicable to you
        in connection with your use of the Services. Users&apos; rights to
        access and use the Services are revoked where these Terms or use of the
        Services is prohibited or conflicts with any applicable law, rule or
        regulation. You must be at least 18 years old or the age of legal
        majority in your jurisdiction of residence, to download, register with
        and/or use the Services. UNDER NO CIRCUMSTANCES MAY ANY USER SOLICIT OR
        SUBMIT TO GRIDLY OR THE SERVICES ANY PERSONAL INFORMATION FROM CHILDREN
        UNDER THE AGE OF 13. THE SERVICES ARE NOT INTENDED TO BE USED BY
        CHILDREN UNDER THE AGE OF 13.
      </p>

      <h5>LICENSE TO USE THE SERVICES</h5>
      <p>
        Subject to your compliance with these Terms, Gridly grants you a
        limited, non-transferable, non-exclusive, revocable license to access
        and use the Services for your own personal, non- commercial purposes.
        This license includes the right to view Content (defined below)
        available on the Services. This license is personal to you and may not
        be assigned or sublicensed to anyone else.
      </p>

      <h5>ACCOUNT AND REGISTRATION</h5>
      <p>
        <u>Account Creation.</u> To enjoy full access to the Services, you must
        create an account. All information that you provide to Gridly and to any
        third parties in connection with your use of the Services must be
        accurate and complete, including your name, address, and email address.
      </p>
      <p>
        <u>Account Updates.</u> You must notify us if any of your account
        information changes. If you fail to keep your account information up to
        date, we may suspend or terminate your account in our discretion.
      </p>
      <p>
        <u>Profile Information.</u> You may not use someone else&apos;s name or
        any other information that violates a third party&apos;s rights, is
        against the law, or that is offensive, obscene, or otherwise
        objectionable in Gridly&apos;s sole discretion.
      </p>
      <p>
        <u>Account Security.</u> You are responsible for all activity that
        occurs under your account, including any activity by unauthorized users.
        You may not allow others to use your account. You must safeguard the
        confidentiality of your password, and if you are using a device that
        others have access to, log out of your account after using the Services.
        If you become aware of unauthorized access to your account, change your
        password and notify us immediately at{' '}
        <MailToGridlyLink
          to="privacy@gogridly.com"
          subject="Privacy Policy inquiry"
        />
        .
      </p>
      <p>
        <u>Account Deletion.</u> You may delete your account through your
        account settings (if applicable to your account), or by contacting us at{' '}
        <MailToGridlyLink
          to="privacy@gogridly.com"
          subject="Account Deletion request"
        />
      </p>

      <h5>GRIDLY&apos;S ROLE AND HOME ENERGY PROJECTS</h5>
      <p>
        Gridly assists homeowners with home energy project planning, but Gridly
        is not a contractor and does not provide home energy products, services,
        contracting, or work, and does not sell home energy products directly.
      </p>
      <p>
        <u>Home Energy Project-Related Service Providers.</u> Gridly may suggest
        home energy project- related service providers
        <u>(“Service Pros”)</u> in your area, who may be interested in assisting
        you with your home energy project by providing home energy
        project-related products and/or services to you
        <u>(“Pro Services”).</u> If you would like to explore a home energy
        project- related service or product, Gridly may attempt to connect you
        with a Service Pro, and may share your contact information with Service
        Pros that may be able to assist you. However, we do not guarantee that
        we will be able to match your project, product, or service needs with a
        Service Pro or that there are Service Pros in your area that are either
        capable or willing 3 to complete your project, or provide the Pro
        Services that you are in tested in.
      </p>
      <p>
        <u>No Guarantees or Endorsements.</u> While we may take certain steps to
        review the credentials of Service Pros listed on our Services, we make
        no guarantees, warranties or representations regarding the skills or
        undertakings of such Service Pros or the quality of the job that he or
        she may perform for you if you elect to retain their services, or for
        any Pro Services provided to you. We do not endorse or recommend the
        services of any particular Service Pro. We do not independently verify
        their representations about their services, nor validate any reviews. It
        is entirely your responsibility to evaluate the Service Pro and the
        Service Pro&apos;s qualifications, and to enter into a direct contract
        or otherwise reach agreement with a Service Pro. We do not guarantee or
        warrant any Service Pro&apos;s performance on the job or the outcome or
        quality of the services performed. The Service Pros are not employees or
        agents of Gridly, nor is Gridly an agent of the Service Professionals.
      </p>
      <p>
        <u>Pricing and Contracting.</u> Gridly may provide pricing or cost
        estimates for home energy projects, products and services, however,{' '}
        <strong>
          all pricing and cost estimates are purely informational and are
          general estimates, and Gridly does not guarantee or warrant the
          accuracy of pricing or costs displayed on the Services. The actual
          cost of a home energy project, product, or service will depend on a
          variety of factors, and may differ from pricing or cost estimates
          displayed on the Services. Any such pricing or costs estimates, or
          quotes provided by Service Pros via the Services, or which you find on
          the Services, are not contractually binding offers, are for
          informational purposes only, and cannot be accepted on or via the
          Services.
        </strong>{' '}
        No contractual arrangement is created based upon the quotes or estimates
        provided to you in the Services. To contract with a Service Pro, you
        must work directly with the Service Pro. Gridly does not perform, and is
        not responsible for, Pro Services or for any of the projects, products,
        or services requested by you in your home energy project request. Your
        rights under contracts you enter into with Service Pros are governed by
        the terms of such contracts and by applicable federal, state, provincial
        and local laws. Grisly is not a party to such agreements. All payments
        and applicable taxes must be made to the Service Pro in accordance with
        the agreements.
      </p>
      <p>
        <u>Home Energy Products.</u> The Services may display or contain links
        to products offered and sold by third parties.{' '}
        <strong>
          Gridly does not offer for sale or sell these products directly.
        </strong>{' '}
        If you are interested in a product displayed on or linked from the
        Services, you must complete your purpose with the third party offering
        such product(s). Gridly is not a party to your transaction, and is not
        responsible or liable in connection with your purchase or use of such
        product(s) in any way.
      </p>
      <p>
        <u>Release from Damages or Claims.</u> In the event that you have a
        dispute with respect to any Pro Services, or any other projects,
        products, or services provided by a Service Pro or purchased from a
        third party seller, or the fees charged by any Service Pro or third
        party seller, you must address such dispute with the Service Pro or
        third party seller directly. GRIDLY IS NOT LIABLE OR RESPONSIBLE IN ANY
        WAY FOR PRO SERVICES OR THIRD PARTY SELLERS OR FOR YOUR DEALING WITH
        SERVICE PROS OR THIRD PARTY SELLERS. YOU HEREBY AGREE TO RELEASE GRIDLY
        (AND OUR OFFICERS, DIRECTORS, SHAREHOLDERS, AFFILIATES, EMPLOYEES AND
        AGENTS) FROM ANY DAMAGES OR 4 CLAIMS (INCLUDING CONSEQUENTIAL AND
        INCIDENTAL DAMAGES) OF EVERY KIND OR NATURE, SUSPECTED AND UNSUSPECTED,
        KNOWN AND UNKNOWN, AND DISCLOSED OR UNDISCLOSED, ARISING OUT OF OR IN
        ANY WAY CONNECTED SERVICE PROS, PRO SERVICES, THIRD PARTY SELLERS, WITH
        SUCH DISPUTES, AND YOUR DEALINGS WITH SERVICE PROS OR THIRD PARTY
        SELLERS.
      </p>
      <h5>NO SUBSCRIPTION FEES; PAYMENTS TO THIRD PARTIES</h5>
      <p>
        Gridly does not charge you any subscription or other fees for use of the
        Services.
      </p>
      <p>
        In connection with your use of the Services, you agree to pay all
        applicable fee(s), taxes and other charges owed by you to third parties
        for products and services made available to you through use of the
        Services. However, Gridly is not a party to such transactions and has no
        liability in connection therewith.
      </p>

      <h5>TERM AND TERMINATION; ACCOUNT DELETION</h5>
      <p>
        <u>Term.</u> These Terms begin on the date you first use the Services
        and continue as long as you have an account with us and/or continue to
        use the Services.
      </p>
      <p>
        <u>Termination and Content Removal.</u> Gridly may, in Gridly&apos;s
        sole discretion, suspend, disable, or delete your account (or any part
        thereof), terminate your account, or block or remove any User Content
        (defined below) that you submitted, for any lawful reason, including if
        Gridly determines that you have violated these Terms or that your
        conduct or User Content would tend to damage Gridly&apos;s reputation or
        goodwill. If Gridly deletes your account or terminates your account, you
        may not re-register for or use the Services under any other user name or
        profile without the express written consent of Gridly. Gridly may block
        your access to the Services to prevent re-registration.
      </p>
      <p>
        <u>Effect of Termination / Account Deletion.</u> Upon termination of
        these Terms all licenses granted by Gridly will terminate. In the event
        of account deletion for any reason, User Content may no longer be
        available
      </p>

      <h5>COMMUNICATION</h5>
      <p>
        You are not required to agree to receive promotional text messages,
        calls or pre-recorded messages as a condition of using the Services. By
        submitting your phone number to us and agreeing to these Terms, you
        agree to receive communications from Gridly, including via text
        messages, calls, pre-recorded messages, and push notifications, any of
        which may be generated by automatic telephone dialing systems. These
        communications include, for example, operational communications
        concerning your account or use of the Services, your inquires related to
        home energy projects, products or services, updates concerning new and
        existing features on the Services, communications concerning promotions
        run by us or third parties, and news relating to the Services and
        industry developments. Standard text message charges applied by your
        telephone carrier may apply to text messages we send. If you submit
        someone else&apos;s phone number or email address to us to receive
        communications, you 5 represent and warrant that each person for whom
        you provide a phone number or email address has consented to receive
        communications from Gridly.
      </p>
      <p>
        If you wish to stop receiving promotional emails or promotional text
        messages, we provide the following methods for you to opt-out or
        unsubscribe: (a) follow the instructions we provide in the email or
        initial text message for that category of promotional emails or text
        messages or (b) if you have an account on the Services, you may opt-out
        or unsubscribe using your settings.
      </p>

      <h5>USER CONTENT</h5>
      <p>
        For purposes of these Terms: (i) “Content” means text, graphics, images,
        music, software, audio, video, works of authorship of any kind, and
        information or other materials that are posted, generated, provided or
        otherwise made available through the Services; and (ii) “User Content”
        means any content that users (including you) provide to be made
        available through the Services. Content includes, without limitation,
        User Content. Any User Content, whether publicly posted or privately
        transmitted, is the sole responsibility of the person who originated
        such User Content. You represent that all User Content submitted by or
        on behalf of you is accurate, complete, up-to-date, and in compliance
        with these Terms and with all applicable laws, rules and regulations.
        You acknowledge that all Content, including User Content, accessed by
        you using the Services is at your own risk and you will be solely
        responsible for any damage or loss to you or any other party resulting
        therefrom. To the full extent permitted by law, we make no
        representations, warranties or guarantees with respect to any Content
        that you access on or through the Services.
      </p>
      <p>
        As between you and Gridly, you represent that you own (or have all
        rights necessary to grant Gridly the rights below to) all User Content
        that you submit to the Services, and that Gridly will not need to obtain
        licenses from any third party or pay royalties to any third party in
        order to use such User Content. You grant Gridly a worldwide, perpetual,
        irrevocable, non- exclusive, sublicensable (through multiple tiers),
        transferable, royalty-free license and right to use, copy, transmit,
        distribute, publicly perform and display (through all media now known or
        later created), edit, modify, and make derivative works from your User
        Content (including, without limitation, translations) for any purpose
        whatsoever, commercial or otherwise, without compensation to you. In
        addition, you waive any so-called “moral rights” or rights of privacy or
        publicity in your User Content. You further grant all users of the
        Services permission to view your User Content for their personal,
        non-commercial purposes.
      </p>

      <h5>FEEDBACK AND SUGGESTIONS</h5>
      <p>
        Any communication or other material that you send to us, such as any
        questions, comments, feedback, suggestions, testimonials, or the like,
        is and will be deemed to be non-confidential and we shall have no
        obligation of any kind with respect to such information. We shall be
        free to use any ideas, concepts, know-how or techniques contained in
        such communication for any purpose whatsoever, including but not limited
        to, operating, developing, and improving the Services. As further
        described herein, you are solely responsible for all information you
        send to us, including, without limitation, its accuracy, truthfulness
        and non- infringement of any other party&apos;s legal rights.
      </p>

      <h5>GENERAL PROHIBITIONS AND USER CONTENT STANDARDS</h5>
      <p>You agree not to do any of the following:</p>
      <ul>
        <li>
          Reproduce, redistribute, sell, create derivative works from,
          decompile, reverse engineer, or disassemble the Services, or any part
          thereof, unless expressly permitted in writing by an authorized
          representative of Gridly;
        </li>
        <li>
          Post, upload, publish, submit or transmit any User Content that: (i)
          infringes, misappropriates or violates a third party&apos;s patent,
          copyright, trademark, trade secret, moral rights or other intellectual
          property rights, or rights of publicity or privacy; (ii) violates, or
          encourages any conduct that would violate, any applicable law or
          regulation or would give rise to civil liability; (iii) is fraudulent,
          false, misleading or deceptive; (iv) is defamatory, obscene,
          pornographic, vulgar or offensive; (v) promotes discrimination,
          bigotry, racism, hatred, harassment or harm against any individual or
          group; (vi) is violent or threatening or promotes violence or actions
          that are threatening to any person, animal, or entity; (vii) exploits
          minors or (viii) promotes illegal or harmful activities or substances;
        </li>
        <li>
          Download and/or install any third-party software and/or application on
          any Gridly hardware that is not expressly permitted by Gridly in
          writing;
        </li>
        <li>
          Use, display, mirror or frame the Services or any individual element
          within the Services, Gridly&apos;s name, any Gridly trademark, logo or
          other proprietary information, without Gridly&apos;s express written
          consent; nor alter, modify and/or remove any copyright or other
          proprietary notice associated with the Services;
        </li>
        <li>
          Access, tamper with, or use non-public areas of the Services,
          Gridly&apos;s computer systems, or the technical delivery systems of
          Gridly&apos;s providers;
        </li>
        <li>
          Attempt to probe, scan or test the vulnerability of any Gridly system
          or network or breach any security or authentication measures;
        </li>
        <li>
          Attempt to access, scrape or search the Services or Content or
          download Content from the Services, including through the use of any
          engine, software, tool, agent, device or mechanism (including spiders,
          robots, crawlers, data mining tools, plugins, add-ons or the like),
          other than the software and/or search agents provided by Gridly or
          other generally available third-party web browsers;
        </li>
        <li>
          Use the Services or Content, or any portion thereof, for any
          commercial purpose or for the benefit of any third party or in any
          manner not permitted by these Terms or permitted expressly in writing
          by Gridly;
        </li>
        <li>
          Interfere with, or attempt to interfere with, the access of any user,
          host or network, including, without limitation, sending a virus,
          overloading, flooding, spamming, or mail-bombing the Services;
        </li>
        <li>
          Collect or store any personally identifiable information from the
          Services from other users of the Services without their express
          permission;
        </li>
        <li>
          Copy, use, disclose or distribute any information obtained from the
          Services, whether directly or through third parties (such as search
          engines), without Gridly&apos;s express written consent;
        </li>
        <li>
          Impersonate or misrepresent your affiliation with any person or
          entity;
        </li>
        <li>Violate any applicable law or regulation; or</li>
        <li>
          Encourage or enable any other individual to do any of the foregoing.
        </li>
      </ul>
      <p>
        We reserve the right, but are not obligated, to remove or disable access
        to the Services and/or any Content, at any time and without notice,
        including, but not limited to, if we, at our sole discretion, determine
        that a user has violated these Terms. We have the right to investigate
        violations of these Terms or conduct that affects the Services. We may
        also consult and cooperate with law enforcement authorities to prosecute
        users who violate the law.
      </p>

      <h5>INDEMNIFICATION</h5>
      <p>
        You agree to indemnify, defend, and hold harmless Gridly and its
        directors, officers, employees, and agents, from and against all claims,
        damages, losses and costs that arise from or relate to (i) your access
        to, or use or misuse of, the Services and/or Content, (ii) any User
        Content submitted by or on behalf of you, including with respect to any
        violation of any intellectual property or other right of any third party
        or (iii) your breach of these Terms.
      </p>

      <h5>THIRD-PARTY LINKS</h5>
      <p>
        There may be links on the Services that let you leave the particular
        Services you are accessing in order to access a linked site that is
        operated by a third party. Gridly neither controls nor endorses these
        sites, nor has Gridly reviewed or approved the content that appears on
        them. Gridly is not responsible for the legality, accuracy or
        inappropriate nature of any content, advertising, products or other
        materials on or available from any such third-party sites. You
        acknowledge and agree that Gridly is not responsible or liable, directly
        or indirectly, for any damage or loss caused or alleged to be caused by
        or in connection with the access or use of any of the links, content,
        goods or services available on or through these third-party sites.
      </p>

      <h5>NO WARRANTIES</h5>
      <p>
        Gridly reserves the right to modify the Services, including, but not
        limited to updating, adding to, enhancing, modifying, removing or
        altering any Content or features of the Services, at any time, in its
        sole discretion. You are responsible for providing your own access
        (e.g., computer, smart phone, mobile device, Internet connection,
        virtual or augmented reality headset, etc.) to the Services. Gridly has
        the right but not the obligation to screen or monitor any Content and
        Gridly does not guarantee that any Content available on the Services is
        suitable for all users or that it will continue to be available for any
        length of time.
      </p>
      <p>
        Gridly provides the Services and all Content, and information relating
        to Service Pros and Pro Services, on an “AS IS” and “AS AVAILABLE”
        basis. You therefore use the Services and Content at your own risk.
        Other than as expressly provided in writing by Gridly in these Terms or
        otherwise, to the extent permitted by law, Gridly expressly disclaims
        any and all warranties of any kind, whether express or implied,
        including, but not limited to implied warranties of merchantability,
        fitness for a particular purpose, non-infringement, accuracy, quiet
        enjoyment and any other warranty that might arise under any law. Without
        limiting the foregoing, Gridly makes no representations or warranties:
      </p>
      <ul>
        <li>
          That the Services are or will be permitted in your jurisdiction;
        </li>
        <li>That the Services will be uninterrupted or error-free;</li>
        <li>Concerning any Content, including User Content;</li>
        <li>Concerning any cost estimates or pricing;</li>
        <li>
          Concerning any Service Pro, Pro Services, or any project, work,
          product, or service of any Service Pro;
        </li>
        <li>
          Concerning any third party product displayed or linked from the
          Services, or the third party sellers of such products;
        </li>
        <li>
          That the Services will meet your personal or professional needs;
        </li>
        <li>
          That Gridly will continue to support any particular feature of the
          Services; or
        </li>
        <li>
          Concerning sites and resources linked to, or integrated with, the
          Services.
        </li>
      </ul>

      <h5>LIMITATION OF LIABILITY</h5>
      <p>
        As between you and Gridly, in the event of any problem with the Services
        and/or any Content, any Service Pro, the Pro Services, any third party
        product, or any third party seller, you agree that your sole remedy is
        to cease using the Services and Content. In no event shall Gridly or its
        subsidiaries, affiliates, directors, officers, employees or any party
        involved in creating or producing the Services and/or Content be liable
        to you for any amount exceeding {formatDollars(100)}, or for any
        special, indirect, incidental, consequential, punitive or exemplary
        damages, or any other damages whatsoever, resulting from the use of the
        Services (or with the delay or inability to use the Services), any
        Content, Service Pros, Pro Services, third party products, third party
        sellers, or otherwise arising out of using the Services, Content, Pro
        Services, third party products, or your dealings with Service Pros or
        third party sellers, whether under a theory of breach of contract, tort,
        strict liability, negligence, or otherwise, even if such party has been
        advised of the possibility of such damages. If you live in a
        jurisdiction that does not allow the exclusion or limitation of
        liability for consequential or similar damages, the above limitation
        does not apply to you. To the extent that one or any aspect of
        Gridly&apos;s limitations set out above does not apply, all remaining
        aspects survive. The exclusions and limitations of damages set forth
        above are fundamental elements of the basis of the bargain between
        Gridly and you.
      </p>

      <h5>INTELLECTUAL PROPERTY</h5>
      <p>
        The Services, its software, features and functionality, and all of
        Gridly&apos;s Content, including but not limited to all information,
        text, displays, images, video and audio, and the design, selection and
        arrangement thereof, are owned by Gridly or its licensors, as the case
        may be, and are protected by United States and international copyright,
        trademark, patent, trade secret and other intellectual property or
        proprietary rights laws. As between you and Gridly, Gridly owns a
        copyright in the selection, coordination, arrangement and enhancement of
        all Content in the Services. Unless otherwise specified, copying or
        modifying any Content or using Content for any purpose other than your
        personal, non-commercial use of the Services, including use of any such
        Content on any other website or networked computer environment, is
        strictly prohibited.
      </p>
      <p>
        The Gridly name, logos and affiliated properties, designs and marks are
        the exclusive property of Gridly, whether registered or unregistered,
        and may not be used in connection with any product or service that is
        not ours, or in any manner that is likely to cause confusion 9 as to our
        endorsement, affiliation or sponsorship of any person, product or
        service. Nothing contained on the Services should be construed as
        granting, by implication, estoppel or otherwise, any license or right to
        use any of our trade names, trademarks or other intellectual property
        without our express prior written consent.
      </p>
      <p>
        Any other trademarks appearing on the Services are trademarks of Gridly
        or their respective owners. Our partners or service providers may also
        have additional proprietary rights in certain Content that they make
        available through the Services. All rights not expressly granted in
        these Terms are reserved by Gridly.
      </p>

      <h5>GOVERNING LAW AND JURISDICTION</h5>
      <p>
        These Terms, and any dispute between you and Gridly, shall be governed
        by the laws of the State of Massachusetts without regard to principles
        of conflicts of law that would result in the application of the law of
        any other jurisdiction, except that the Federal Arbitration Act shall
        govern the interpretation and enforcement of the arbitration provisions
        set forth below. Unless you and we agree otherwise, in the event that
        the “AGREEMENT TO ARBITRATE; WAIVER OF CLASS ACTION” section below is
        found not to apply to you or to a particular claim or dispute, either as
        a result of your decision to opt out of the Agreement to Arbitrate (as
        defined below) or as a result of a decision by the arbitrator or a court
        order, you agree that any claim or dispute that has arisen or may arise
        between you and us must be resolved exclusively by a state court located
        in Boston, MA or a federal court located in Boston, MA, except that you
        or we are permitted (i) to bring small claims actions in state court in
        the county in which you reside if such court has a small claims
        procedure; (ii) to bring claims for injunctive relief in any court
        having jurisdiction over the parties; or (iii) to seek enforcement of a
        judgment in any court having jurisdiction over the parties. To the
        extent permitted by law, you and we agree to waive trial by jury in any
        court proceeding.
      </p>

      <h5>AGREEMENT TO ARBITRATE; WAIVER OF CLASS ACTION</h5>
      <p>
        Except for disputes relating to your or our intellectual property (such
        as trademarks, trade dress, domain names, trade secrets, copyrights and
        patents) or for items (i)-(iii) set forth in the “GOVERNING LAW AND
        JURISDICTION” Section above, you agree that all disputes between you and
        Gridly (whether or not such dispute involves a third party) arising out
        of or relating to these Terms, our Services, our Privacy Policy, Service
        Pros, Pro Services, third party sellers, and/or third party products,
        shall be finally resolved by arbitration conducted in the English
        language in Boston, MA, U.S.A. under the Commercial Arbitration Rules of
        the American Arbitration Association (AAA) and you and we hereby
        expressly waive trial by jury. You and we shall appoint one arbitrator
        mutually agreed upon by you and us or, if you and we cannot agree within
        thirty (30) days of either party&apos;s request for arbitration, such
        arbitrator shall be selected by the AAA upon the request of either
        party. The parties shall bear equally the cost of the arbitration
        (except that the prevailing party shall be entitled to an award of
        reasonable attorneys&apos; fees incurred in connection with the
        arbitration in such an amount as may be determined by the arbitrator).
        All decisions of the arbitrator shall be final and binding on both
        parties and enforceable in any court of competent jurisdiction.
        Notwithstanding this, application may be made to any court for a
        judicial acceptance of the award or order of enforcement. Under no
        circumstances shall the 10 arbitrator be authorized to award damages,
        remedies or awards that conflict with these Terms.
      </p>
      <p>
        Any claims brought by you or us must be brought in that party&apos;s
        individual capacity, and not as a plaintiff or class member in any
        purported class or representative proceeding. Neither you nor Gridly
        will participate in a class action or class-wide arbitration for any
        claims covered by these Terms. You hereby waive any and all rights to
        bring any claims related to these Terms and/or our Privacy Policy as a
        plaintiff or class member in any purported class or representative
        proceeding. You may bring claims only on your own behalf.
      </p>
      <p>
        You may opt out of this agreement to arbitrate. If you do so, neither
        you nor we can require the other to participate in an arbitration
        proceeding. To opt out, you must notify us in writing within thirty (30)
        days after the date that you first became subject to this arbitration
        provision. The opt out notice must state that you do not agree to the
        Agreement To Arbitrate and must include your name, address, phone
        number, your Gridly account to which the opt out applies and a clear
        statement that you want to opt out of this agreement to arbitrate. You
        must sign the opt-out notice for it to be effective. This procedure is
        the only way you can opt out of the Agreement To Arbitrate. You must use
        this address to opt out: 65 Great Road, Acton, MA 01720
      </p>
      <p>
        Notwithstanding any provision in these Terms to the contrary, you and
        Gridly agree that if we make any change to the Arbitration Procedures
        (other than a change to any notice address or Gridly Site link provided
        herein) in the future, that change shall not apply to any claim that was
        filed in a legal proceeding against us prior to the effective date of
        the change. Moreover, if we seek to terminate the Arbitration Procedures
        from these Terms, such termination shall not be effective until thirty
        (30) days after the version of these Terms not containing the
        Arbitration Procedures is posted to our Site, and shall not be effective
        as to any claim that was filed in a legal proceeding against us prior to
        the effective date of removal.
      </p>
      <p>
        In accordance with this Section, this agreement to arbitrate will
        survive the termination of your relationship with us.
      </p>

      <h5>INTERPRETATION; SEVERABILITY; WAIVER; REMEDIES</h5>
      <p>
        Headings are for convenience only and shall not be used to construe
        these Terms. If any portion of these Terms is found invalid or
        unenforceable by any court of competent jurisdiction, that portion will
        be enforced to the maximum extent permissible and otherwise severed from
        these Terms. No failure or delay by Gridly in exercising any right
        hereunder will waive any further exercise of that right. The waiver of
        any such right or provision will be effective only if in writing and
        signed by a duly authorized representative of Gridly. Gridly&apos;s
        rights and remedies hereunder are cumulative and not exclusive.
      </p>

      <h5>SUCCESSORS; ASSIGNMENT; NO THIRD-PARTY BENEFICIARIES</h5>
      <p>
        These Terms are binding upon and will inure to the benefit of both
        parties and their 11 respective successors, heirs, executors,
        administrators, personal representatives, and permitted assigns. You may
        not assign or transfer these Terms without Gridly&apos;s prior written
        consent. Gridly may assign its rights, obligations and/or these Terms at
        any time in its sole discretion without notice to you. The Services are
        offered only for your personal, non- commercial use, and not for the use
        or benefit of any third party.
      </p>

      <h5>NOTICES</h5>
      <p>
        You consent to electronic delivery for all notices, agreements,
        disclosures, or other information from Gridly. Gridly may communicate
        with you by email or by posting notices to you through the Services. For
        support-related inquiries, you may email{' '}
        <MailToGridlyLink
          to="hello@gogridly.com"
          subject="Terms and Conditions inquiry"
        />
        . For all other notices to Gridly, write to the following address: 65
        Great Road, Acton, MA 01720.
      </p>
      <p>
        Nothing in these Terms or otherwise limits Gridly&apos;s right to object
        to subpoenas, claims, or other demands.
      </p>

      <h5>ENTIRE AGREEMENT; MODIFICATION</h5>
      <p>
        These Terms represent the entire understanding between Gridly and you
        regarding the Services and/or Content and supersede all prior agreements
        and understandings regarding the same. These Terms cannot be amended
        except by a writing signed by both parties or by our posting of an
        amended version of these Terms.
      </p>
      <p>
        If any provision of these Terms is held invalid or unenforceable by an
        arbitrator or a court of competent jurisdiction, the other provisions of
        these Terms will remain in full force and effect. These Terms shall bind
        and inure to the benefit of the parties to these Terms and their
        respective successors and permitted assigns. We and you are not
        partners, joint venturers, agents, employees or representatives of the
        other party.
      </p>

      <h5>FORCE MAJEURE</h5>
      <p>
        Neither you nor Gridly shall be liable for any failure or delay in
        performance under these Terms for causes beyond the party&apos;s
        reasonable control and not caused by that party&apos;s fault, or
        negligence, including, but not limited to, “acts of God,” acts of
        government, flood, fire, civil unrest, acts of terror, pandemics and
        epidemics, strikes or other labor problems, computer attacks or
        malicious acts, such as attacks on or through the internet, any internet
        service provider, telecommunications or hosting facility.
      </p>

      <h5>NOTICE TO INTERNATIONAL USERS</h5>
      <p>
        The Services are operated from the United States and international users
        of the Services agree to be subject to applicable laws in the United
        States as set forth in the GOVERNING LAW AND JURISDICTION section above.
        Please note that other countries may have laws and regulatory
        requirements that differ from those in the United States, and if you
        access the Services from outside the United States, you do so on your
        own initiative and are responsible for compliance with all local laws.
      </p>

      <h5>CONTACT US</h5>
      <p>
        If you have any questions about these Terms, please contact us at 65
        Great Road, Acton, MA 01720,{' '}
        <MailToGridlyLink
          to="hello@gogridly.com"
          subject="Terms and Conditions inquiry"
        />
        .
      </p>
    </>
  )
}
