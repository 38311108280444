import useSWR from 'swr'

import { UtilityData } from '../../types'
import { routes } from '../constants'

type UtilityDataApiLoading = {
  utilityData: undefined
  isLoading: true
  error: undefined
}

type UtilityDataApiError = {
  utilityData: undefined
  isLoading: false
  error: Error
}

type UtilityDataApiResolved = {
  utilityData: UtilityData
  isLoading: false
  error: undefined
}

type UtilityDataApi =
  | UtilityDataApiLoading
  | UtilityDataApiError
  | UtilityDataApiResolved

export const useUtilityData = (
  postalCode: string | undefined | null
): UtilityDataApi => {
  const { data, error } = useSWR<UtilityData>(
    () => postalCode && `${routes.utilities}?postal_code=${postalCode}`
  )

  if (data) {
    return {
      utilityData: data,
      isLoading: false,
      error: undefined,
    }
  } else if (error) {
    return {
      utilityData: undefined,
      isLoading: false,
      error: error,
    }
  } else {
    return {
      utilityData: undefined,
      isLoading: true,
      error: undefined,
    }
  }
}
