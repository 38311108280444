import React from 'react'

export const PrivacyPolicyPage: React.FC = () => {
  return (
    <>
      <h2>Gridly Privacy Notice</h2>

      <h5>Last Updated: September 14, 2022</h5>

      <p>
        Gridly, LLC. (“Gridly”, “we”, “us”, or “our”) is committed to respecting
        your privacy. This Privacy Notice (the “Privacy Notice”) explains our
        practices regarding the collection, use, and disclosure of information
        that you (“you” or “users”) may provide to us through your use of our
        website located at www.GoGridly.com, our social media pages, and any of
        our other web pages that link to this Privacy Notice (collectively, our
        “Services”).
      </p>

      <p>
        If you have entered into a separate agreement with us that relates to
        our Services, that separate agreement shall control, and only those
        terms within this Privacy Notice that do not conflict with such separate
        agreement shall apply. This Privacy Notice does not cover information we
        receive from third parties. If you have any questions regarding this
        Privacy Notice, please contact us at privacy@gogridly.com.
      </p>

      <p>
        By accessing the Services, you agree to be bound by this Privacy Notice.
        If you do not agree to the terms of this Privacy Notice, please do not
        use the Services. Each time you use the Services, the current version of
        this Privacy Notice will apply. Accordingly, when you use the Services,
        you should check the date of this Privacy Notice (which appears at the
        top) and review any changes since you last reviewed the Privacy Notice.
      </p>

      <h5>Information We Collect</h5>

      <p>
        We collect two types of information from or about users of our Services:
        “Personal Information,” which is information that you provide that can
        be used to identify you directly or indirectly (such as your name, email
        address, etc.), and “Aggregate Information,” which is information that
        cannot be used to identify you (such as frequency of visits to the Site,
        browser type, etc.). When we collect or create Aggregate Information, it
        is not linked to your Personal Information.
      </p>

      <h5>INFORMATION YOU PROVIDE TO US</h5>

      <p>
        We collect Personal Information that you voluntarily provide to us when
        you use our Services. For example, you may provide us with your contact
        information such as your email address, first name and last name, the
        content of your message, or other Personal Information when you choose
        to submit such information to us through email, an online form, or other
        method (such as contacting us through our “Contact Us” Site feature).
      </p>

      <p>
        If you sign up for a Gridly account, we collect your contact information
        such as your email address, first and last name, and your home address.
        We also collect additional information about your home, your energy
        usage, and your energy providers. We may use publicly available
        information and data to automatically populate data fields with certain
        information about your home to assist you with creating your Gridly
        account. You will have the option to confirm or to change such
        information.
      </p>

      <h5>INFORMATION WE AUTOMATICALLY COLLECT</h5>

      <p>
        When you use our Services, we automatically receive and collect
        information about you and your device. This information includes the
        following:
      </p>

      <ul>
        <li>
          Information about your device, such as the operating system, hardware,
          system version, Internet Protocol (IP) address, device ID, and device
          language.
        </li>

        <li>
          The specific actions that you take when you use our Services,
          including but not limited to the pages and screens that you view or
          visit, search terms that you enter, and how you interact with our
          Services.
        </li>

        <li>
          The time, frequency, connection type, and duration of your use of our
          Services.
        </li>

        <li>
          Information regarding your interaction with email messages, for
          example, whether you opened, clicked on, or forwarded the email
          message.
        </li>

        <li>
          Identifiers associated with cookies or other technologies that may
          uniquely identify your device or browser (as further described below).
        </li>

        <li>Pages you visited before or after navigating to our Site.</li>
      </ul>

      <h5>EMPLOYMENT</h5>

      <p>
        If we have collected your Personal Information through our “Careers”
        page in response to a job opening, you are giving us permission to use
        and retain your information for the purpose of evaluating your
        application for employment consideration for the specific position that
        you applied to, as well as other roles at Gridly. This Personal
        Information may be processed and stored by a third party talent tracking
        solution provider as well as with vendors, consultants, and other
        service providers who need access to such information to carry out work
        relating to the recruitment process on our behalf.
      </p>

      <h5>COLLECTION OF PERSONAL INFORMATION BY THIRD PARTIES</h5>

      <p>
        Some links on our Services may redirect you to third-party websites that
        we do not operate, which may include third parties that offer products
        and services, and social media features, such as LinkedIn or Twitter
        buttons or links. The privacy practices of those websites will be
        governed by their own policies. Similarly, if you purchase products or
        services from a third-party website linked from our Services, you are
        purchasing such product or service directly from that third-party and
        not from Gridly, and you are providing your information directly to that
        third-party, not to Gridly. We make no representation or warranty as to
        the privacy practices or policies of any third parties, including the
        providers of third-party applications. If you are submitting information
        to any such third-party through our Services, you should review and
        understand that party&apos;s applicable policies, including their
        privacy policies, before providing your information to the third-party.
      </p>

      <h5>AGGREGATE INFORMATION COLLECTED</h5>

      <p>
        Aggregate Information is information that does not identify you.
        Aggregate Information may be collected when you use our Services,
        independent of any information you voluntarily enter. Additionally, we
        may use one or more processes to de-identify information that contains
        Personal Information, such that only Aggregate Information remains. We
        may collect, use, store, and transfer Aggregate Information without
        restriction.
      </p>

      <h5>USE OF “COOKIES”</h5>

      <p>
        Like most websites, our Services may use “cookies.” Cookies are
        alphanumeric identifiers that we transfer to your computer&apos;s hard
        drive through your web browser to help us identify you when you come to
        our Services. You have choices with respect to cookies. By modifying
        your browser preferences, you have the choice to accept all cookies, to
        be notified when a cookie is set, or to reject all cookies. If you
        choose to reject all cookies you may be unable to use those aspects of
        our Services that require registration in order to participate. You can
        learn more about cookies and how they work at www.allaboutcookies.org.
        You can always disable cookies through your browser settings. Doing so,
        however, may disable certain features on our Services. You can opt-out
        from third-party cookies that are used for advertising purposes on the
        NAI website..
      </p>

      <p>
        Although we do our best to honor the privacy preferences of our
        visitors, we are not able to respond to Do Not Track signals from your
        browser.
      </p>

      <h5>ANALYTICS</h5>

      <p>
        Our website uses Google Analytics to provide insight into our website,
        and to improve the relevance of advertisements you receive. If you would
        like to opt out of Google Analytics, please visit{' '}
        <a href="https://tools.google.com/dlpage/gaoptout/">
          https://tools.google.com/dlpage/gaoptout/.
        </a>
      </p>

      <h3>How we Use Your Information</h3>

      <p>
        In general, we collect Personal Information and other information from
        you so that we can provide our Services, operate our business, and
        provide information that you request from us. This includes the
        following uses and purposes:
      </p>

      <ul>
        <li>Provide, operate, improve, maintain, and protect our Services.</li>

        <li>Provide you with technical and other support.</li>

        <li>
          Facilitate your interaction with home energy project-related service
          providers when you ask us to do so.
        </li>

        <li>
          Send you updates, marketing communication, and other information about
          Gridly and our Services.{' '}
        </li>

        <li>
          Conduct research and analysis, and monitor and analyze trends and
          usage.
        </li>

        <li>
          Enhance or improve user experience, our business, and our Services,
          including the safety and security thereof.{' '}
        </li>

        <li>Personalize our Services to you.</li>

        <li>Communicate with you and respond to inquiries.</li>

        <li>Consider your application for employment.</li>

        <li>
          As necessary to comply with any applicable law, regulation, subpoena,
          legal process, or governmental request.
        </li>

        <li>
          Enforce contracts, including investigation of potential violations
          thereof.
        </li>

        <li>
          Detect, prevent, or otherwise address fraud, security or technical
          issues.
        </li>

        <li>
          Protect against harm to the rights, property or safety of Gridly, our
          users, or the public as required or permitted by law.
        </li>
      </ul>

      <h3>Sharing of Personal Information with Third Parties</h3>

      <p>We do not rent or sell your Personal Information.</p>

      <p>
        Like most companies, we share information, including Personal
        Information, in certain circumstances with third parties through
        operation of our Services and our business. Below we explain when that
        happens.
      </p>

      <h5>SERVICE PROVIDERS</h5>

      <p>
        We may transfer Personal Information to third parties for the purpose of
        providing the Services or operating our business. For example, we may
        transfer your Personal Information to third-party service providers to
        assist with providing the Services and other services, and to
        communicate with you about current or future offerings.
      </p>

      <h5>HOME ENERGY PROJECT-RELATED SERVICE PROVIDERS</h5>

      <p>
        If you request to be contacted by a home energy project-related vendor
        or service provider, we will share your contact information with the
        applicable vendor or service provider. We will only share your
        information when you ask us to do so.
      </p>

      <h5>REQUIRED TRANSFERS</h5>

      <p>
        We may also transfer your Personal Information to third parties under
        the following circumstances: (i) to obtain legal advice or comply with a
        legal requirement, law, subpoena, judicial proceeding, court order,
        governmental request, or legal process; (ii) to investigate a possible
        crime, such as fraud or identity theft; (iii) in connection with the
        sale, purchase, merger, asset sale, financing, reorganization,
        liquidation or dissolution of Gridly, including in the evaluation
        thereof; (iv) when we believe it is necessary to protect the rights,
        property, or safety of Gridly or other persons, or (v) as otherwise
        required or permitted by law, including any contractual obligations of
        Gridly.
      </p>

      <h5>AS DIRECTED BY YOU AND WITH YOUR CONSENT</h5>

      <p>
        Except as otherwise provided in this Privacy Notice, we share Personal
        Information with companies, organizations or individuals outside of
        Gridly only at your direction or when we have your consent to do so.
      </p>

      <h3>Opt-Out</h3>

      <p>
        If you no longer wish to receive our marketing and promotional
        communications, you may opt-out by following the instructions included
        in each communication.
      </p>

      <h3>Children and Privacy</h3>
      <p>
        Our Services are not intended to be used by children under the age of
        13, and we do not knowingly collect Personal Information from children
        under the age of 13 in connection with the Services.
      </p>

      <h3>How Do We Protect Your Information</h3>
      <p>
        We take the security of your Personal Information very seriously. We use
        reasonable administrative, physical, and technical safeguards to secure
        the Personal Information you share with us. Despite these safeguards and
        our additional efforts to secure your information, we cannot promise or
        guarantee that hackers, cybercriminals, or other unauthorized third
        parties will not be able to defeat our security and improperly collect,
        access, steal, or modify your Personal Information.
      </p>

      <h3>Privacy Notice Updates</h3>
      <p>
        Each time you use our Services the current version of this Privacy
        Notice will apply. When you use our Services, you should check the date
        of this Privacy Notice (which appears at the top of the Privacy Notice)
        and review any changes since the last version. Our business changes
        frequently and this Privacy Notice is subject to change from time to
        time.
      </p>

      <h3>How to Contact Us</h3>
      <p>
        To contact us with your questions or comments regarding this Privacy
        Notice or our privacy practices in general, please email us at
        privacy@gogridly.com.
      </p>
      <p>
        Gridly, LLC <br aria-hidden />
        65 Great Road <br aria-hidden />
        Acton, MA 01720
      </p>
    </>
  )
}
