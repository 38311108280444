import React from 'react'
import classnames from 'classnames'

import './Button.scss'

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  variant?: 'wire' | 'wire-alt' | 'transparent' | 'green'
  small?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  children,
  small,
  variant,
  ...props
}) => {
  return (
    <button
      type="button"
      {...props}
      className={classnames(
        'button',
        {
          'button--small': small,
          'button--wire': variant === 'wire',
          'button--wire-alt': variant === 'wire-alt',
          'button--green': variant === 'green',
          'button--transparent': variant === 'transparent',
        },
        props.className
      )}
    >
      {children}
    </button>
  )
}
