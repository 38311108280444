import axios from 'axios'
import { DevHelperLink, Property } from '../../types'
import { routes } from '../constants'

type PropertyWithEmail = Property & { email: string, subscribe_to_email_updates: boolean }
type CreateProperty = (
  params: PropertyWithEmail
) => Promise<DevHelperLink>

export const createProperty: CreateProperty = async (property) => {
  const resp = await axios.post<DevHelperLink>(
    routes.properties,
    property
  )
  return resp.data
}
