const AllProducts = [
  {
    imageSrc:
      '//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B06W56TBLN&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=gridlyhomeene-20&language=en_US',
    manufacturer: 'ecobee',
    name: 'ecobee3 Lite Thermostat',
    description:
      "Save up to 26% per year on heating and cooling costs. ENERGY STAR certified. Automatically adjusts the temperature when you're away to never heat or cool an empty home. Compared to a hold of 72°F",
    buyNowHref: 'https://amzn.to/3s1Wskt',
    requiresCWire: true,
  },
  {
    imageSrc:
      '//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07NQT85FC&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=gridlyhomeene-20&language=en_US',
    manufacturer: 'ecobee',
    name: 'ecobee SmartThermostat with Voice Control',
    description:
      "Save up to 26% per year on heating and cooling costs. ENERGY STAR certified. Automatically adjusts the temperature when you're away to never heat or cool an empty home. Compared to a hold of 72°F.  Power extender adapter for use without a C-wire included.",
    buyNowHref: 'https://amzn.to/3Mja5EW',
    requiresCWire: false,
  },
  {
    imageSrc:
      '//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01NB1OB0I&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=gridlyhomeene-20&language=en_US',
    manufacturer: 'Emerson',
    name: 'Emerson Sensi',
    description:
      'Save about 23% on HVAC energy: The ENERGY STAR-certified Sensi smart thermostat can help you save energy with features like flexible scheduling, remote access and usage reports.',
    buyNowHref: 'https://amzn.to/3ViOcKf',
    requiresCWire: false,
  },
  {
    imageSrc:
      '//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01N3CEUDH&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=gridlyhomeene-20&language=en_US',
    manufacturer: 'Emerson',
    name: 'Sensi Touch Smart Thermostat',
    description:
      'Save about 23% on HVAC energy: The ENERGY STAR-certified Sensi smart thermostat can help you save energy with features like flexible scheduling, remote access and usage reports.',
    buyNowHref: 'https://amzn.to/3T2f67i',
    requiresCWire: true,
  },
  {
    imageSrc:
      '//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09LRTB5GZ&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=gridlyhomeene-20&language=en_US',
    manufacturer: 'Nest',
    name: 'Google Nest Thermostat',
    description:
      'Programmable thermostat that lets you create an energy efficient schedule in the Google Home app on your Android or iPhone',
    buyNowHref: 'https://amzn.to/3rJxUfD',
    requiresCWire: false,
  },
  {
    imageSrc:
      '//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01MYE7VDD&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=gridlyhomeene-20&language=en_US',
    manufacturer: 'Nest',
    name: 'Google Nest Learning Thermostat',
    description:
      'Programmable smart thermostat that learns your schedule and the temperatures you like and programs itself to help you save energy and stay comfortable',
    buyNowHref: 'https://amzn.to/3rFT5iS',
    requiresCWire: true,
  },
]

const CWireRequiredProducts = AllProducts.filter(
  ({ requiresCWire }) => requiresCWire
)

const CWireNotRequiredProducts = AllProducts.filter(
  ({ requiresCWire }) => !requiresCWire
)

export const SmartThermostatProducts = {
  AllProducts,
  CWireRequiredProducts,
  CWireNotRequiredProducts,
}
