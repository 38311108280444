import React from 'react'

import { Project } from '../../../../types'
import { PriceDetail } from '../../../PriceDetail'
import { EstimatesTable } from '../EstimatesTable'

type Props = Pick<Project, 'annual_savings' | 'payback_period'> & {
  showFinanced: boolean
}

export const SavingsEstimates: React.FC<Props> = ({
  annual_savings,
  payback_period,
  showFinanced,
}) => {
  const monthlySavings = annual_savings
    ? Math.round(parseFloat(annual_savings) / 12).toFixed(0)
    : null

  const formattedPaybackPeriod = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 1,
  }).format(parseFloat(payback_period || '0'))

  return (
    <EstimatesTable heading="Savings Estimates">
      <tr>
        <td className="body-3">Energy Cost Savings{showFinanced || '/Year'}</td>
        <td className="body-2 body-2--bold green-text green-text--dark">
          {showFinanced ? (
            <PriceDetail price={monthlySavings} paymentFrequency="monthly" />
          ) : (
            <>
              Up to <PriceDetail price={annual_savings} />
            </>
          )}
        </td>
      </tr>

      {showFinanced || (
        <tr>
          <td className="body-3">Payback Period</td>
          <td className="body-2 body-2--bold">
            {formattedPaybackPeriod} years
          </td>
        </tr>
      )}
    </EstimatesTable>
  )
}
