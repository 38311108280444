import React from 'react'
import axios from 'axios'
import { SWRConfig as _SWRConfig } from 'swr'

export type Fetcher = <T>(resource: string) => Promise<T>

const fetcher: Fetcher = (resource) =>
  axios.get(resource).then((res) => res.data)

export const SWRConfig: React.FC<React.PropsWithChildren> = ({ children }) => (
  <_SWRConfig value={{ fetcher }}>{children}</_SWRConfig>
)
