import React from 'react'
import classnames from 'classnames'

import './ReadOnlyField.scss'

interface Props {
  className?: string
  label: string
  value: string
}

export const ReadOnlyField: React.FC<Props> = ({ className, label, value }) => {
  return (
    <div className={classnames(className, 'read-only-field')}>
      <span className="read-only-field__label">{label}</span>
      <span className="read-only-field__value">{value}</span>
    </div>
  )
}
