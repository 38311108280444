import React from 'react'

import {
  GRIDLY_INSULATION_PRODUCT_NAMES,
  GRIDLY_PROJECT_SLUGS,
} from '../../../constants'
import { ROUTES } from '../../../routes/constants'
import { LinkButton } from '../../../components/LinkButton'

export const PRODUCT_EXPLORE_LINKS: Record<
  GRIDLY_INSULATION_PRODUCT_NAMES,
  JSX.Element | undefined
> = {
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_NON_MASS_SAVE]: (
    <LinkButton
      to="https://drive.google.com/file/d/1eUrLEwuHjd3jE6CeKEGv7p1AZ9fB2Bqq/view"
      variant="blue"
    >
      Explore Your Options
    </LinkButton>
  ),
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_MASS_SAVE]: (
    <LinkButton
      to={ROUTES.projectExplorePages[GRIDLY_PROJECT_SLUGS.INSULATION]}
      variant="blue"
    >
      Explore Your Options
    </LinkButton>
  ),
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_NEEECO]: (
    <LinkButton
      to={ROUTES.projectExplorePages[GRIDLY_PROJECT_SLUGS.INSULATION]}
      variant="blue"
    >
      Explore Your Options
    </LinkButton>
  ),
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_CAPE_COD]: (
    <LinkButton
      to={ROUTES.projectExplorePages[GRIDLY_PROJECT_SLUGS.INSULATION]}
      variant="blue"
    >
      Explore Your Options
    </LinkButton>
  ),
  [GRIDLY_INSULATION_PRODUCT_NAMES.WEATHERIZATION]: undefined,
}

export const PRODUCT_SCHEDULE_LINKS: Record<
  GRIDLY_INSULATION_PRODUCT_NAMES,
  JSX.Element | undefined
> = {
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_NON_MASS_SAVE]: (
    <LinkButton
      to="https://drive.google.com/file/d/1eUrLEwuHjd3jE6CeKEGv7p1AZ9fB2Bqq/view"
      variant="green"
    >
      Schedule Home Energy Audit
    </LinkButton>
  ),
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_MASS_SAVE]: (
    <LinkButton
      to={ROUTES.projectExplorePages[GRIDLY_PROJECT_SLUGS.INSULATION]}
      variant="green"
    >
      Schedule Home Energy Audit
    </LinkButton>
  ),
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_NEEECO]: (
    <LinkButton
      to={ROUTES.projectExplorePages[GRIDLY_PROJECT_SLUGS.INSULATION]}
      variant="green"
    >
      Schedule Home Energy Audit
    </LinkButton>
  ),
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_CAPE_COD]: (
    <LinkButton
      to={ROUTES.projectExplorePages[GRIDLY_PROJECT_SLUGS.INSULATION]}
      variant="green"
    >
      Schedule Home Energy Audit
    </LinkButton>
  ),
  [GRIDLY_INSULATION_PRODUCT_NAMES.WEATHERIZATION]: undefined,
}
