import React from 'react'

import { PropertyFormValues } from '../../../types'
import {
  GRIDLY_CARBON_IMPACT_DESCRIPTIONS,
  GRIDLY_INSULATION_PRODUCT_NAMES,
  GRIDLY_INSULATION_PRODUCT_TITLES,
  GRIDLY_PROJECT_SLUGS,
  HOME_INSPECTION_PRODUCT_NAMES,
} from '../../../constants'
import { useToast } from '../../../context'
import { useProject, useProperty } from '../../../api'
import { InsulationForm, Project, RenewablePie } from '../../../components'
import { formatDollars } from '../../../helpers'
import { PRODUCT_EXPLORE_LINKS, PRODUCT_SCHEDULE_LINKS } from './constants'

export const InsulationPage: React.VFC = () => {
  const { setSuccessToast, setErrorToast } = useToast()
  const {
    error: errorLoadingProperty,
    isLoading: isLoadingProperty,
    property,
    updateProperty,
  } = useProperty()
  const {
    error: errorLoadingProject,
    isLoading: isLoadingProject,
    project,
  } = useProject(GRIDLY_PROJECT_SLUGS.INSULATION)

  if (errorLoadingProperty || errorLoadingProject) {
    return (
      <>Error {errorLoadingProject?.message || errorLoadingProject?.message}</>
    )
  }

  if (isLoadingProperty || isLoadingProject) {
    return <>Loading...</>
  }

  const handleSubmit = async (propertyData: PropertyFormValues) => {
    if (property.id) {
      try {
        await updateProperty(property.id, propertyData)

        setSuccessToast()
      } catch (e) {
        setErrorToast()

        throw e
      }
    }
  }

  const hideIncentives = HOME_INSPECTION_PRODUCT_NAMES.includes(
    project.product_recommendation ?? ''
  )

  return (
    <>
      <Project.Header
        title="Schedule a home energy audit"
        recommendationText="Schedule a no-cost professional home assessment to check for insulation quality and air leaks. Make insulation repairs as specified."
        recommendationCTA={
          PRODUCT_SCHEDULE_LINKS[
            project.product_recommendation as GRIDLY_INSULATION_PRODUCT_NAMES
          ]
        }
        whyText="Qualify for better rebates and incentives. Improve HVAC effectiveness by up to 40%."
      >
        <Project.Highlight
          description="Gain up to 20% in heating and cooling efficiences."
          takeAway="20%"
          takeAwayStyle="solid"
        />

        <Project.Highlight
          description={`Save up to ${formatDollars(
            parseFloat(project.annual_savings ?? '0'),
            { format: 'longhand' }
          )} in energy costs per year.*`}
          takeAway={formatDollars(parseFloat(project.annual_savings ?? '0'), {
            format: 'shorthand',
            fractionDigits: 1,
          })}
          takeAwayStyle="outline"
        />

        {project.carbon_impact && (
          <Project.Highlight
            description={
              GRIDLY_CARBON_IMPACT_DESCRIPTIONS[project.carbon_impact]
            }
            takeAway={project.carbon_impact}
            takeAwayStyle="outline"
          />
        )}

        <Project.Highlight
          description={`Increase your renewable energy from ${property.totalUtilityPercentRenewable}% to ${project.future_total_utility_percent_renewable}%`}
          takeAway={
            <RenewablePie
              percentRenewable={parseFloat(
                project.future_total_utility_percent_renewable || '0'
              )}
            />
          }
        />
      </Project.Header>

      <Project.Sandbox title="We'll help you navigate the assessment and insulation process.">
        <InsulationForm
          initialValues={property.toFormValues()}
          onSubmit={handleSubmit}
        />
      </Project.Sandbox>

      <Project.Recommendation
        title={
          GRIDLY_INSULATION_PRODUCT_TITLES[
            project.product_recommendation as GRIDLY_INSULATION_PRODUCT_NAMES
          ]
        }
        propertyIsEligible={property.isEligibleForInsulation}
        exploreLink={
          PRODUCT_EXPLORE_LINKS[
            project.product_recommendation as GRIDLY_INSULATION_PRODUCT_NAMES
          ]
        }
        project={project}
        hideIncentiveLink={hideIncentives}
      />

      <Project.Education project={project} hideIncentives={hideIncentives} />
    </>
  )
}
