import React from 'react'

export interface DisplaySettings {
  showFinanced: boolean
}

export type DisplaySettingsContextValue = {
  displaySettings: DisplaySettings
  setDisplaySettings: (displaySettings: DisplaySettings) => void
  resetDisplaySettings: () => void
}

export const DisplaySettingsContext = React.createContext<
  DisplaySettingsContextValue | undefined
>(undefined)
