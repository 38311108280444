import { GRIDLY_PROJECT_SLUGS, GRIDLY_PROJECT_STATUSES } from '../../constants'
import { createEstimateRangeFromValue } from '../../helpers'
import { ActionPlan } from '../../types'

export class ActionPlanModel {
  constructor(public data: ActionPlan) {}

  getProjectBySlug(slug: GRIDLY_PROJECT_SLUGS) {
    const project = this.data.projects.find((p) => p.slug === slug)

    if (!project) {
      throw new Error(`Unregistered Project: ${slug}`)
    }

    return project
  }

  get formattedTotalAnnualSavings() {
    return `+$${createEstimateRangeFromValue(
      parseFloat(this.data.total_annual_savings)
    )}`
  }

  get formattedTotalNetCost() {
    const netCost = parseFloat(this.data.total_net_cost)

    return `$${createEstimateRangeFromValue(netCost)}`
  }

  get formattedTotalFinancedMonthlyCost() {
    const totalFinancedMonthlyCost = parseFloat(
      this.data.total_financed_monthly_cost
    )

    return `$${createEstimateRangeFromValue(totalFinancedMonthlyCost)}/mo`
  }

  projectsSortedBy(sortBy?: string) {
    switch (sortBy) {
      case 'cost_low_to_high':
        return this.projectsSortedByNetCostLowToHigh
      case 'cost_high_to_low':
        return this.projectsSortedByNetCostHighToLow
      case 'status':
        return this.projectsSortedByStatus
      case 'savings_high_to_low':
        return this.projectsSortedBySavingsHighToLow
      default:
        return this.projectsSortedByDefaultOrder
    }
  }

  get projectsSortedByNetCostLowToHigh() {
    return [...this.data.projects].sort(({ net_cost: a }, { net_cost: b }) => {
      const aFloat = parseFloat(`${a}`)
      const bFloat = parseFloat(`${b}`)

      if (aFloat > bFloat) {
        return 1
      } else if (aFloat < bFloat) {
        return -1
      } else {
        return 0
      }
    })
  }

  get projectsSortedByNetCostHighToLow() {
    return [...this.data.projects].sort(({ net_cost: a }, { net_cost: b }) => {
      const aFloat = parseFloat(`${a}`)
      const bFloat = parseFloat(`${b}`)

      if (aFloat > bFloat) {
        return -1
      } else if (aFloat < bFloat) {
        return 1
      } else {
        return 0
      }
    })
  }

  get projectsSortedByStatus() {
    return [...this.data.projects].sort(({ status: a }, { status: b }) => {
      if (a === GRIDLY_PROJECT_STATUSES.IN_PROGRESS) {
        return -1
      } else if (a === null && b === GRIDLY_PROJECT_STATUSES.COMPLETED) {
        return -1
      } else if (a === GRIDLY_PROJECT_STATUSES.COMPLETED) {
        return 1
      } else {
        return 0
      }
    })
  }

  get projectsSortedBySavingsHighToLow() {
    return [...this.data.projects].sort(
      ({ annual_savings: a }, { annual_savings: b }) => {
        const aFloat = parseFloat(`${a}`)
        const bFloat = parseFloat(`${b}`)

        if (aFloat > bFloat) {
          return -1
        } else if (aFloat < bFloat) {
          return 1
        } else {
          return 0
        }
      }
    )
  }

  get projectsSortedByDefaultOrder() {
    return this.data.projects
  }
}
