import React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import { GridlyHouseIcon } from '../media'
import { ResponsiveText } from '../ResponsiveText'
import './CompareTower.scss'
import { formatDollars } from '../../helpers'

type Props = {
  title: string
  description: string
  pros: string
  cons: string
  optionType?: 'current' | 'recommended' | 'ineligible' | 'default'
  monthlyCost: `${number}` | null
  unitPrice: `${number}` | null
  exploreLink?: string
}

export const CompareTower: React.VFC<Props> = ({
  title,
  description,
  pros,
  cons,
  optionType = 'default',
  unitPrice,
  monthlyCost,
  exploreLink,
}) => (
  <div
    className={classnames('compare-tower', {
      'compare-tower--current-option': optionType === 'current',
      'compare-tower--recommended-option': optionType === 'recommended',
      'compare-tower--ineligible-option': optionType === 'ineligible',
    })}
  >
    <div
      className={classnames([
        'compare-tower__label',
        {
          'compare-tower__label--recommended': optionType === 'recommended',
          'compare-tower__label--hidden':
            optionType === 'default' || optionType === 'ineligible',
        },
      ])}
    >
      {optionType === 'current' && 'Current Provider'}
      {optionType === 'recommended' && (
        <>
          <GridlyHouseIcon />
          <ResponsiveText mobile="Recommended" desktop="Gridly Recommends" />
        </>
      )}
    </div>

    <div className="compare-tower__title-section">
      <div className="compare-tower__title">{title}</div>
      <p className="body-2">{description}</p>
    </div>

    <div className="compare-tower__section compare-tower__pros-n-cons-section">
      <div className="compare-tower__section-header">Pros</div>
      <p>{pros}</p>

      <div className="compare-tower__section-header">Cons</div>
      <p>{cons}</p>
    </div>

    <div className="compare-tower__section compare-tower__monthly-cost-section">
      {monthlyCost && unitPrice && (
        <>
          <div
            className={classnames('compare-tower__highlighted-text', {
              'compare-tower__highlighted-text--current':
                optionType === 'current',
            })}
          >
            {formatDollars(parseFloat(monthlyCost), { fractionDigits: 0 })} per
            month*
          </div>

          <div className="body-2">
            ({formatDollars(parseFloat(unitPrice), { fractionDigits: 2 })} per
            kWh)
          </div>
        </>
      )}
    </div>

    <div className="compare-tower__section">
      {exploreLink &&
        (optionType === 'ineligible' ? (
          <p>Sorry! You can&apos;t sign up for this right now</p>
        ) : (
          <Link to={exploreLink} className="compare-tower__explore-link">
            Explore
          </Link>
        ))}
    </div>
  </div>
)
