// Adapted from https://github.com/jaredpalmer/formik /formik/examples/DebouncedAutoSave.js
// https://github.com/jaredpalmer/formik/blob/e51f09a318cba216a1ba3932da0906202df0b979/examples/DebouncedAutoSave.js

import React, { useCallback, useEffect } from 'react'

import { useFormikContext } from 'formik'
import debounce from 'just-debounce-it'

interface Props {
  debounceMs?: number
}

export const AutoSave: React.FC<Props> = ({ debounceMs = 1500 }) => {
  const formik = useFormikContext()

  const debouncedSubmit = useCallback(
    debounce(() => formik.submitForm(), debounceMs),
    [debounceMs, formik.submitForm]
  )

  useEffect(() => {
    if (formik.dirty || formik.status?.dirty) {
      debouncedSubmit()
    }
  }, [debouncedSubmit, formik.values])

  return null
}
