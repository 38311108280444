export enum GRIDLY_NATURAL_GAS_PROVIDERS {
  EVERSOURCE = 'Eversource',
  NATIONAL_GRID = 'National Grid',
  LIBERTY_UTILITIES = 'Liberty Utilities',
  BERKSHIRE_GAS = 'Berkshire Gas',
  UNITIL = 'Unitil (Fitchburg Gas & Electric)',
  HOLYOKE_GAS_AND_ELECTRIC_DEPARTMENT = 'Holyoke Gas & Electric Department',
  MIDDLEBOROUGH_GAS_AND_ELECTRIC_DEPARTMENT = 'Middleborough Gas & Electric Department',
  WAKEFIELD_MUNICIPAL_LIGHT_DEPARTMENT = 'Wakefield Municipal Light Department',
  WESTFIELD_GAS_AND_ELECTRIC_LIGHT_DEPARTMENT = 'Westfield Gas & Electric Light Department',
}
