import React from 'react'
import { Field, Form, Formik, FormikHelpers } from 'formik'

import { PropertyFormValues } from '../../../types'
import {
  AutoSave,
  Fieldset,
  NumberInput,
  SelectInput,
} from '../../formElements'
import { yesNoSelectOptions } from '../constants'
import {
  hotWaterTankLocationOptions,
  hotWaterTankSizeOptions,
  washingMachineStyleOptions,
} from './constants'

import './HotWaterHeaterForm.scss'

interface Props {
  initialValues: PropertyFormValues
  onSubmit: (values: PropertyFormValues) => Promise<void>
}
export const HotWaterHeaterForm: React.VFC<Props> = ({
  initialValues,
  onSubmit,
}) => {
  const handleSubmit = async (
    values: PropertyFormValues,
    helpers: FormikHelpers<PropertyFormValues>
  ) => {
    try {
      await onSubmit(values)
    } catch (e) {
      helpers.resetForm()
    }
  }

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Form className="hot-water-heater-form" role="form">
        <Fieldset
          className="hot-water-heater-form__fieldset"
          legend="YOUR CURRENT TANK"
          mobileColumnCount={1}
          desktopColumnCount={1}
        >
          <Field
            as={SelectInput}
            options={hotWaterTankLocationOptions}
            name="hot_water_tank_location"
            label="Current Tank Location"
          />

          <Field
            as={SelectInput}
            options={hotWaterTankSizeOptions}
            name="hot_water_tank_size"
            label="Current Tank Size"
          />
        </Fieldset>

        <Fieldset
          className="hot-water-heater-form__fieldset"
          legend="YOUR HOT WATER USAGE"
        >
          <Field
            as={NumberInput}
            name="num_residents"
            label="Number of Residents"
          />

          <Field
            as={NumberInput}
            name="back_to_back_showers"
            label="Back-To-Back Showers"
          />

          <Field
            as={SelectInput}
            options={washingMachineStyleOptions}
            name="washing_machine_style"
            label="Washing Machine Type"
          />

          <Field
            as={SelectInput}
            options={yesNoSelectOptions}
            name="dishwasher"
            label="Do you have a dishwasher?"
          />
        </Fieldset>

        <AutoSave />
      </Form>
    </Formik>
  )
}
