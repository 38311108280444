import React from 'react'

import WhiteCheckmarkSVG from './checkmark--white.svg'
import BlueCheckmarkSVG from './checkmark--blue.svg'

interface Props extends React.ComponentPropsWithoutRef<'img'> {
  variant?: 'white' | 'blue'
}

export const Checkmark: React.VFC<Props> = ({
  variant = 'white',
  ...props
}) => {
  return (
    <img
      alt=""
      {...props}
      src={variant === 'white' ? WhiteCheckmarkSVG : BlueCheckmarkSVG}
    />
  )
}
