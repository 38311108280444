import React from 'react'

import { PropertyFormValues } from '../../../types'
import { ROUTES } from '../../../routes'
import {
  GRIDLY_CARBON_IMPACT_DESCRIPTIONS,
  GRIDLY_PROJECT_SLUGS,
} from '../../../constants'
import { useToast } from '../../../context'
import { useProject, useProperty } from '../../../api'
import { Project, RenewablePie, SolarPanelsForm } from '../../../components'
import { formatDollars } from '../../../helpers'
import { LinkButton } from '../../../components/LinkButton'

export const SolarPanelsPage: React.FC = () => {
  const { setSuccessToast, setErrorToast } = useToast()
  const {
    error: errorLoadingProperty,
    isLoading: isLoadingProperty,
    property,
    updateProperty,
  } = useProperty()
  const {
    error: errorLoadingProject,
    isLoading: isLoadingProject,
    project,
  } = useProject(GRIDLY_PROJECT_SLUGS.SOLAR_PANELS)

  if (errorLoadingProperty || errorLoadingProject) {
    return (
      <>Error {errorLoadingProject?.message || errorLoadingProject?.message}</>
    )
  }

  if (isLoadingProperty || isLoadingProject) {
    return <>Loading...</>
  }

  const defaultTitle = 'Multi-Panel Rooftop Solar Install'

  const handleSubmit = async (values: PropertyFormValues) => {
    if (property.id) {
      try {
        await updateProperty(property.id, values)

        setSuccessToast()
      } catch (error) {
        setErrorToast()

        throw error
      }
    }
  }

  return (
    <>
      <Project.Header
        title="Rooftop Solar"
        recommendationText="Generate your own renewable electricity with a solar system installation."
        whyText="Eliminate or reduce your monthly electricity costs. Going solar offers big rebates and incentives."
      >
        <Project.Highlight
          takeAway="100%"
          description="Go all-in with renewables and net-zero potential."
          takeAwayStyle="solid"
        />{' '}
        <Project.Highlight
          takeAway={formatDollars(parseFloat(project.annual_savings ?? '0'), {
            fractionDigits: 1,
            format: 'shorthand',
          })}
          description={`Save up to ${formatDollars(
            parseFloat(project.annual_savings ?? '0'),
            { fractionDigits: 0, format: 'longhand' }
          )} in energy costs per year.*`}
          takeAwayStyle="outline"
        />
        {project.carbon_impact && (
          <Project.Highlight
            description={
              GRIDLY_CARBON_IMPACT_DESCRIPTIONS[project.carbon_impact]
            }
            takeAway={project.carbon_impact}
            takeAwayStyle="outline"
          />
        )}
        <Project.Highlight
          description={`Increase your renewable energy from ${property.data.total_utility_percent_renewable}% to ${project.future_total_utility_percent_renewable}%`}
          takeAway={
            <RenewablePie
              percentRenewable={parseInt(
                project.future_total_utility_percent_renewable || '0'
              )}
            />
          }
        />
      </Project.Header>

      <Project.Sandbox title="We'll help you choose the perfect rooftop solar plan">
        <SolarPanelsForm
          initialValues={property.toFormValues()}
          onSubmit={handleSubmit}
        />
      </Project.Sandbox>

      <Project.Recommendation
        title={project.product_recommendation || defaultTitle}
        propertyIsEligible={property.isEligibleForSolarPanels}
        project={project}
        exploreLink={
          <LinkButton
            to={ROUTES.projectExplorePages[GRIDLY_PROJECT_SLUGS.SOLAR_PANELS]}
            variant="blue"
          >
            Explore Your Options
          </LinkButton>
        }
      />

      <Project.Education project={project} />
    </>
  )
}
