import React from 'react'
import { Formik, Form, Field, FormikHelpers } from 'formik'

import { PropertyFormValues } from '../../../types'
import { AddressSatelliteImage } from '../../media'
import { SelectInput, SubmitButton } from '../../formElements'
import { constants } from './constants'

import './SolarPanelsForm.scss'

type Props = {
  initialValues: PropertyFormValues
  onSubmit: (values: PropertyFormValues) => Promise<void>
}

export const SolarPanelsForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
}) => {
  const handleSubmit = async (
    values: PropertyFormValues,
    helpers: FormikHelpers<PropertyFormValues>
  ) => {
    try {
      await onSubmit(values)
    } catch (e) {
      helpers.resetForm()
    }
  }

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Form className="solar-panels-form" role="form">
        <AddressSatelliteImage
          className="solar-panels-form__satellite-image"
          propertyData={initialValues}
        />

        <div className="solar-panels-form__fields">
          <Field
            as={SelectInput}
            label="Current Roof Condition"
            name="roof_condition"
            options={constants.ROOF_CONDITION_OPTIONS}
          />

          <Field
            as={SelectInput}
            label="Roof Exposure"
            name="roof_exposure"
            options={constants.ROOF_EXPOSURE_OPTIONS}
          />

          <Field
            as={SelectInput}
            label="Solar Production Needed"
            name="solar_kwh_need"
            options={constants.SOLAR_KWH_NEED_OPTIONS}
          />
        </div>

        <SubmitButton variant="wire" className="solar-panels-form__submit">
          Refine Your Recommendation
        </SubmitButton>
      </Form>
    </Formik>
  )
}
