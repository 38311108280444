import React from 'react'

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  to: `${'hello' | 'privacy'}@gogridly.com`
  linkText?: string
  subject?: string
  body?: string
}

export const MailToGridlyLink: React.FC<Props> = ({
  to,
  children,
  subject = '',
  body = '',
  ...props
}) => (
  <a
    {...props}
    href={encodeURI(`mailto:${to}?subject=${subject}&body=${body}`)}
  >
    {children || to}
  </a>
)
