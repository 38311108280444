import React from 'react'

import { Link } from 'react-router-dom'

import './BottomNav.scss'

export const BottomNav: React.FC = () => {
  return (
    <nav className="bottom-nav">
      <ul>
        <li>
          <Link to="">Contact Us</Link>
        </li>
        <li>
          <Link to="">Newsletter Signup</Link>
        </li>
        <li>
          <Link to="">Privacy Policy</Link>
        </li>
        <li>
          <Link to="">Terms &amp; Conditions</Link>
        </li>
      </ul>
    </nav>
  )
}
