import React from 'react'
import { GRIDLY_PROJECT_SLUGS } from '../../../constants'

import { ProjectImageByProjectSlug } from './constants'

interface Props extends React.ComponentProps<'img'> {
  projectSlug: GRIDLY_PROJECT_SLUGS
}

export const ProjectRecommendationImage: React.VFC<Props> = ({
  projectSlug,
  ...props
}) => <img alt="" {...props} src={ProjectImageByProjectSlug[projectSlug]} />
