import React from 'react'
import classnames from 'classnames'

import ChevronIconSvg from './chevron-icon.svg'
import './ChevronIcon.scss'

type Props = React.ComponentPropsWithoutRef<'img'> & {
  pointUp?: boolean
}

export const ChevronIcon: React.FC<Props> = ({ pointUp, ...props }) => {
  return (
    <img
      {...props}
      className={classnames({ 'point-up': pointUp })}
      alt=""
      src={ChevronIconSvg}
    />
  )
}
