import React from 'react'
import { Formik, Form, Field } from 'formik'

import { SmartThermostatProducts } from '../../../../constants'
import { useProperty } from '../../../../api'
import {
  MaxWidth,
  SelectInput,
  SmartThermostatProduct,
} from '../../../../components'

import './SmartThermostatExplorePage.scss'

enum FilterValues {
  CWIRE_REQUIRED = 'cwire_required',
  CWIRE_NOT_REQUIRED = 'cwire_not_required',
  ALL = 'all',
}

const filterOptions = [
  { label: 'C-Wire Required', value: FilterValues.CWIRE_REQUIRED },
  { label: 'C-Wire Not Required', value: FilterValues.CWIRE_NOT_REQUIRED },
  { label: 'All Thermostats', value: FilterValues.ALL },
]

const showToProductsMap = {
  [FilterValues.CWIRE_REQUIRED]: SmartThermostatProducts.CWireRequiredProducts,
  [FilterValues.CWIRE_NOT_REQUIRED]:
    SmartThermostatProducts.CWireNotRequiredProducts,
  [FilterValues.ALL]: SmartThermostatProducts.AllProducts,
}

export const SmartThermostatExplorePage: React.FC = () => {
  const { property, error, isLoading } = useProperty()

  if (error) {
    return <>Error: {error.message}</>
  }

  if (isLoading) {
    return <>Loading...</>
  }

  const show = property.data.c_wire
    ? FilterValues.CWIRE_REQUIRED
    : FilterValues.CWIRE_NOT_REQUIRED

  return (
    <Formik initialValues={{ show }} onSubmit={() => undefined}>
      {({ values }) => (
        <div className="smart-thermostat-explore-page">
          <MaxWidth backgroundColor="blue" wide>
            <div className="smart-thermostat-explore-page__header">
              <h1>Add Smart Thermostats</h1>
            </div>
          </MaxWidth>

          <MaxWidth wide>
            <div className="smart-thermostat-explore-page__content">
              <div className="smart-thermostat-explore-page__sub-header">
                <h3>Smart Thermostats</h3>

                <Form role="form">
                  <Field
                    as={SelectInput}
                    className="smart-thermostat-explore-page__filter"
                    label="Show"
                    name="show"
                    options={filterOptions}
                  />
                </Form>
              </div>

              <ul className="smart-thermostat-explore-page__products">
                {showToProductsMap[values.show].map((product) => (
                  <li key={product.name}>
                    <SmartThermostatProduct {...product} />
                  </li>
                ))}
              </ul>
            </div>
          </MaxWidth>
        </div>
      )}
    </Formik>
  )
}
