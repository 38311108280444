import {
  GRIDLY_COOLING_DISTRIBUTION_CODES,
  GRIDLY_HEATING_FUEL_CODES,
  GRIDLY_COOLING_FUEL_CODES,
  GRIDLY_HEATING_METHOD_CODES,
  GRIDLY_ELECTRIC_HEATING_METHOD_CODES,
  GRIDLY_HOT_WATER_DEVICE_CODES,
  GRIDLY_HOT_WATER_FUEL_CODES,
} from '../../../constants'

import { constantToOptions } from '../../../helpers'

export const basementTypeOptions = [
  { value: 'FINISHED', label: 'Finished' },
  { value: 'UNFINISHED', label: 'Unfinished' },
  { value: 'PARTIALLY_FINISHED', label: 'Partially Finished' },
  { value: 'NONE', label: 'No Basement' },
]

export const coolingDistributionOptions = constantToOptions(
  GRIDLY_COOLING_DISTRIBUTION_CODES
)

export const heatingDeviceTypeOptions = constantToOptions(
  GRIDLY_HEATING_METHOD_CODES
)

export const electricHeatingDeviceTypeOptions = constantToOptions(
  GRIDLY_ELECTRIC_HEATING_METHOD_CODES
)

export const heatingFuelOptions = constantToOptions(GRIDLY_HEATING_FUEL_CODES)

export const hotWaterFuelOptions = constantToOptions(GRIDLY_HOT_WATER_FUEL_CODES)

export const hotWaterDeviceTypeOptions = constantToOptions(
  GRIDLY_HOT_WATER_DEVICE_CODES
)

export const coolingFuelOptions = constantToOptions(GRIDLY_COOLING_FUEL_CODES)
