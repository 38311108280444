import React from 'react'
import { useNavigate } from 'react-router-dom'

import { PropertyFormValues } from '../../types'
import { PropertyDataModel } from '../../models'
import { ROUTES } from '../../routes'
import { usePropertyData, useToast } from '../../context'
import { getUpdatedPropertyEstimates } from '../../api'
import { HomeDetailsForm, HotStepper, MaxWidth } from '../../components'

import './HomeDetailsPage.scss'

export const HomeDetailsPage: React.VFC = () => {
  const { setErrorToast } = useToast()
  const { propertyData, setPropertyData } = usePropertyData()
  const navigate = useNavigate()
  const propertyDataModel = new PropertyDataModel(propertyData)

  const onSubmit = async (params: PropertyFormValues) => {
    try {
      const property = await getUpdatedPropertyEstimates(params, true)
      setPropertyData(property)
      navigate(ROUTES.utilityUsage)
    } catch (e) {
      setErrorToast()

      throw e
    }
  }

  return (
    <MaxWidth>
      <div className="home-details-page">
        <div className="home-details-page__page-heading-wrapper">
          <div className="home-details-page__hot-stepper">
            <HotStepper currentStep={1} />
          </div>

          <p className="home-details-page__address-line">
            {propertyDataModel.formattedSingleLineAddress}
          </p>

          <h2 className="home-details-page__page-heading">
            Here&apos;s Your Home Energy Profile.
          </h2>

          <p className="home-details-page__sub-heading">
            Please Confirm or edit the details below to improve accuracy.
          </p>
        </div>

        <HomeDetailsForm
          initialValues={propertyDataModel.toFormValues()}
          onSubmit={onSubmit}
        />
      </div>
    </MaxWidth>
  )
}
