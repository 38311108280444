import React from 'react'
import { Tile } from '../Tile'

import './VendorInfoTile.scss'

interface Props extends React.PropsWithChildren {
  Title: React.FC
}

export const VendorInfoTile: React.FC<Props> = ({ children, Title }) => (
  <Tile className="vendor-info-tile">
    <Title />

    <div className="vendor-info-tile__content">{children}</div>
  </Tile>
)
