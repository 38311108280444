import React from 'react'

import classnames from 'classnames'

import './Fieldset.scss'

type Props = React.ComponentPropsWithoutRef<'fieldset'> & {
  legend: string
  mobileColumnCount?: 1 | 2
  desktopColumnCount?: 1 | 2 | 3
}

interface Style extends React.CSSProperties {
  '--mobileColumnCount': Props['mobileColumnCount']
  '--desktopColumnCount': Props['desktopColumnCount']
}

export const Fieldset: React.FC<Props> = ({
  children,
  desktopColumnCount = 2,
  legend,
  mobileColumnCount = 1,
  ...props
}) => {
  const style: Style = {
    '--mobileColumnCount': mobileColumnCount,
    '--desktopColumnCount': desktopColumnCount,
  }

  return (
    <fieldset
      {...props}
      className={classnames('fieldset', props.className)}
      style={style}
    >
      <legend className="fieldset__legend">{legend}</legend>
      {children}
    </fieldset>
  )
}
