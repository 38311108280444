import React from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import { ROUTES } from '../../routes'
import { Address } from '../../types'
import { getPropertyData } from '../../api'
import { usePropertyData, useToast } from '../../context'
import { GoogleAddressForm, MaxWidth } from '../../components'
import './LandingPage.scss'

export const LandingPage: React.VFC = () => {
  const navigate = useNavigate()
  const { setErrorToast } = useToast()
  const { setPropertyData } = usePropertyData()

  const onSubmit = async (params: Address) => {
    try {
      const propertyData = await getPropertyData(params)
      setPropertyData(propertyData)
      navigate(ROUTES.homeDetails)
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.errors.state) {
        navigate(ROUTES.comingSoon)
      } else {
        setErrorToast("Hmm, something's not right.")

        throw e
      }
    }
  }

  return (
    <MaxWidth>
      <h1 className="landing-page__text landing-page__header-text">
        Your{' '}
        <span className="landing-page__text landing-page__text--green">
          Net-Zero Future
        </span>
        <br aria-hidden="true" /> Starts Today
      </h1>

      <div className="landing-page__address-form-wrapper">
        <p className="body-1 landing-page__text">
          Enter your address below to begin the process.
        </p>

        <p className="body-1 landing-page__text">
          Gridly uses this to pull applicable geographic and property
          information to aid in the development of your custom energy action
          plan.
        </p>

        <div className="landing-page__address-form">
          <GoogleAddressForm onSubmit={onSubmit} />
        </div>
      </div>
    </MaxWidth>
  )
}
