import { GRIDLY_PROJECT_SLUGS } from '../../constants'
import { constantToOptions } from '../../helpers'
import { ROUTES } from '../../routes/constants'

export const CLEAN_ENERGY_PROJECTS: Record<
  string,
  {
    title: string
    description: string
    pros: string
    cons: string
    exploreLink: string
  }
> = {
  [GRIDLY_PROJECT_SLUGS.SOLAR_PANELS]: {
    title: 'Install Solar Panels',
    description:
      'Generate your own renewable electricity with a solar system installation.',
    pros: 'Energy independence. Offset your utility electricity charges to near zero. Highest return on investment.',
    cons: 'High upfront costs, and may not work for some homes.',
    exploreLink: ROUTES.projects[GRIDLY_PROJECT_SLUGS.SOLAR_PANELS],
  },
  [GRIDLY_PROJECT_SLUGS.COMMUNITY_SOLAR]: {
    title: 'Sign Up for Community Solar',
    description:
      'Source your electricity from a local, subscriber-based, community solar facility.',
    pros: '100% solar power with no panels to install. Easy enrollment, instant sign up rebate, and no cancellation fees.',
    cons: 'When utility rates go up, community solar rates also rise.',
    exploreLink: ROUTES.projects[GRIDLY_PROJECT_SLUGS.COMMUNITY_SOLAR],
  },
  [GRIDLY_PROJECT_SLUGS.RENEWABLE_ENERGY]: {
    title: 'Purchase Renewable Energy from Supplier',
    description: 'Convert to 100% renewable electricity.',
    pros: 'Just sign up and get started. No installation or equipment required.',
    cons: 'Renewable percentage is likely based on carbon credits rather than renewable sourcing.',
    exploreLink: ROUTES.projects[GRIDLY_PROJECT_SLUGS.RENEWABLE_ENERGY],
  },
}

export const CLEAN_ENERGY_PROJECT_NAMES = Object.keys(CLEAN_ENERGY_PROJECTS)

export const CLEAN_ENERGY_OPTIONS = constantToOptions(
  CLEAN_ENERGY_PROJECT_NAMES
)
