import React from 'react'
import { Field, Form, Formik, FormikHelpers } from 'formik'

import { Address } from '../../../types'
import { SubmitButton, GoogleAutocomplete } from '../../formElements'
import './GoogleAddressForm.scss'

type Props = {
  onSubmit: (address: Address) => Promise<void>
}

export interface GoogleAddressFormValues {
  google_address: Address
}

export const GoogleAddressForm: React.FC<Props> = (props) => {
  const defaultAddress = {
    street_number: '',
    street_name: '',
    city: '',
    state: '',
    postal_code: '',
  }

  const validateAddress = ({
    street_number,
    street_name,
    city,
    state,
    postal_code,
  }: Address) => {
    let error
    if (!street_number || !street_name || !city || !state || !postal_code) {
      error = 'Incomplete address'
    }
    return error
  }

  const handleSubmit: (
    v: GoogleAddressFormValues,
    formikHelpers: FormikHelpers<GoogleAddressFormValues>
  ) => Promise<void> = async ({ google_address }, formikHelpers) => {
    try {
      await props.onSubmit(google_address)
    } catch (_e) {
      formikHelpers.setFieldValue('google_address', '')
      formikHelpers.setFieldError('google_address', 'invalid address')
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={
        { google_address: defaultAddress } as GoogleAddressFormValues
      }
      validateOnMount={true}
    >
      <Form className="google-address-form">
        <div className="google-address-form__input-wrapper">
          <Field
            name="google_address"
            as={GoogleAutocomplete}
            validate={validateAddress}
          />
        </div>

        <SubmitButton
          className="google-address-form__submit-button body-1 body-1--bold"
          type="submit"
          variant="green"
        >
          Go
        </SubmitButton>
      </Form>
    </Formik>
  )
}
