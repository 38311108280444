import React from 'react'
import classnames from 'classnames'

import './MaxWidth.scss'

enum BGColor {
  BLUE = 'blue',
  GREEN = 'green',
  WHITE = 'white',
}

interface Props extends React.PropsWithChildren {
  wide?: boolean
  backgroundColor?: `${BGColor}`
}

export const MaxWidth: React.FC<Props> = ({
  backgroundColor,
  children,
  wide,
}) => (
  <div
    className={classnames('bg-color', {
      'bg-color--blue': backgroundColor === BGColor.BLUE,
      'bg-color--green': backgroundColor === BGColor.GREEN,
      'bg-color--white': backgroundColor === BGColor.WHITE,
    })}
  >
    <div className={classnames('max-width', { 'max-width--wide': wide })}>
      {children}
    </div>
  </div>
)
