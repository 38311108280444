import useSWR from 'swr'

import { Solution } from '../../types'
import { routes } from '../constants'

type SolutionApiLoading = {
  solution: undefined
  isLoading: true
  error: undefined
}

type SolutionApiError = {
  solution: undefined
  isLoading: false
  error: Error
}

type SolutionApiResolved = {
  solution: Solution
  isLoading: false
  error: undefined
}

type SolutionApi = SolutionApiLoading | SolutionApiError | SolutionApiResolved

export const useSolution = (solutionId: number): SolutionApi => {
  const { data, error } = useSWR<Solution>(routes.solution(solutionId))

  if (data) {
    return {
      solution: data,
      isLoading: false,
      error: undefined,
    }
  } else if (error) {
    return {
      solution: undefined,
      isLoading: false,
      error: error,
    }
  } else {
    return {
      solution: undefined,
      isLoading: true,
      error: undefined,
    }
  }
}
