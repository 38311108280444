import { RenewableEnergyPlan } from '../../types'
import { PropertyDataModel } from '../PropertyDataModel'
import { formatDollars } from '../../helpers'

export class RenewableEnergyPlanForPropertyModel {
  static DELIVERY_CENTS_PER_KWH = 0.15

  constructor(
    public plan: RenewableEnergyPlan,
    public property: PropertyDataModel
  ) {}

  get monthlyUsage() {
    return parseFloat(this.property.electricUtilityMonthlyUsage)
  }

  get monthlySpend() {
    return parseFloat(this.property.electricUtilityMonthlyExpenditure) - (this.monthlyUsage * RenewableEnergyPlanForPropertyModel.DELIVERY_CENTS_PER_KWH)
  }

  get unitPriceInDollars() {
    let priceInCents

    if (this.monthlyUsage < 1000) {
      priceInCents = this.plan.pricing_kwh_500
    } else if (this.monthlyUsage < 2000) {
      priceInCents = this.plan.pricing_kwh_1000
    } else {
      priceInCents = this.plan.pricing_kwh_2000
    }

    return parseFloat(priceInCents) / 100
  }

  get estimatedMonthlyPlanCost() {
    return this.unitPriceInDollars * this.monthlyUsage
  }

  get formattedCurrentMonthlyUsage() {
    return `${this.monthlyUsage.toFixed(0)} kWh/mo`
  }

  get formattedCurrentMonthlySpend() {
    return `${formatDollars(this.monthlySpend, { fractionDigits: 0 })}/mo`
  }

  get formattedPlanMonthlySpend() {
    return `${formatDollars(this.estimatedMonthlyPlanCost, {
      fractionDigits: 0,
    })}/mo`
  }

  get formattedDeltaMonthlySpend() {
    return `${formatDollars(this.monthlySpend - this.estimatedMonthlyPlanCost, {
      fractionDigits: 0,
    })}/mo`
  }
}
