import useSWR from 'swr'

import { ProjectSolution } from '../../types'
import { routes } from '../constants'

type ProjectSolutionApiLoading = {
  projectSolution: undefined
  isLoading: true
  error: undefined
}

type ProjectSolutionApiError = {
  projectSolution: undefined
  isLoading: false
  error: Error
}

type ProjectSolutionApiResolved = {
  projectSolution: ProjectSolution
  isLoading: false
  error: undefined
}

type ProjectSolutionApi =
  | ProjectSolutionApiLoading
  | ProjectSolutionApiError
  | ProjectSolutionApiResolved

export const useProjectSolution = (
  projectSolutionId: number
): ProjectSolutionApi => {
  const { data, error } = useSWR<ProjectSolution>(
    routes.projectSolutions(projectSolutionId)
  )

  if (data) {
    return {
      projectSolution: data,
      isLoading: false,
      error: undefined,
    }
  } else if (error) {
    return {
      projectSolution: undefined,
      isLoading: false,
      error: error,
    }
  } else {
    return {
      projectSolution: undefined,
      isLoading: true,
      error: undefined,
    }
  }
}
