import React from 'react'
import { Link } from 'react-router-dom'

import { GRIDLY_PROJECT_SLUGS } from '../../constants'
import { ROUTES } from '../../routes'
import { MaxWidth } from '../MaxWidth'
import { ProjectRecommendationImage } from '../media'

import './NextStepsList.scss'

interface Props {
  children: React.ReactNode[]
  title: string
  projectSlug: GRIDLY_PROJECT_SLUGS
  imgAltText: string
}

export const NextStepsList: React.FC<Props> = ({
  children,
  title,
  projectSlug,
  imgAltText,
}) => {
  return (
    <div className="next-steps-list">
      <MaxWidth backgroundColor="blue">
        <div className="next-steps-list__header">
          <h3>{title}</h3>

          <ProjectRecommendationImage
            className="next-steps-list__image"
            alt={imgAltText}
            projectSlug={projectSlug}
          />
        </div>
      </MaxWidth>

      <MaxWidth>
        <div className="next-steps-list">
          <h5 className="next-steps-list__subtitle-top">
            Our service network launches soon!!
          </h5>

          <h6 className="next-steps-list__subtitle-bottom">
            In the meantime, here&apos;s some steps you can take now…
          </h6>

          <ol className="next-steps-list__list">{children}</ol>

          <Link
            className="next-steps-list__back"
            to={ROUTES.projects[projectSlug]}
          >
            Back
          </Link>
        </div>
      </MaxWidth>
    </div>
  )
}
