import {
  GRIDLY_HVAC_PRODUCT_NAMES,
  GRIDLY_INSULATION_PRODUCT_NAMES,
} from './GridlyProductNames'

export const GRIDLY_HVAC_PRODUCT_TITLES: Record<
  GRIDLY_HVAC_PRODUCT_NAMES,
  string
> = {
  [GRIDLY_HVAC_PRODUCT_NAMES.DUCTLESS_MINI]:
    'Single-Zone Electric Heat Pump Heating & Cooling System',
  [GRIDLY_HVAC_PRODUCT_NAMES.DUCTLESS_MULTI]:
    'Multi-Zone Electric Heat Pump Heating & Cooling System',
  [GRIDLY_HVAC_PRODUCT_NAMES.CENTRAL]:
    'Central ASHP Electric Heat Pump Heating & Cooling System',
}

const HOME_INSPECTION_PRODUCT_TITLE = 'Insulation & Air Leak Home Inspection'

export const GRIDLY_INSULATION_PRODUCT_TITLES: Record<
  GRIDLY_INSULATION_PRODUCT_NAMES,
  string
> = {
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_NON_MASS_SAVE]:
    HOME_INSPECTION_PRODUCT_TITLE,
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_MASS_SAVE]:
    HOME_INSPECTION_PRODUCT_TITLE,
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_NEEECO]:
    HOME_INSPECTION_PRODUCT_TITLE,
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_CAPE_COD]:
    HOME_INSPECTION_PRODUCT_TITLE,
  [GRIDLY_INSULATION_PRODUCT_NAMES.WEATHERIZATION]: 'Get Your Home Weatherized',
}

export const HOME_INSPECTION_PRODUCT_NAMES: string[] = [
  GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_NON_MASS_SAVE,
  GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_MASS_SAVE,
  GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_NEEECO,
  GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_CAPE_COD,
]
