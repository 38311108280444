import React from 'react'
import { Link } from 'react-router-dom'

import { EXTERNAL_ROUTES, ROUTES } from '../../routes'
import { PrimaryLogo } from '../media'
import { MailToGridlyLink } from '../MailToGridlyLink'
import { SocialNav } from '../navElements'
import './Footer.scss'

interface Props {
  linkLogoToHomePage?: boolean
}

export const Footer: React.FC<Props> = ({ linkLogoToHomePage }) => (
  <footer className="footer">
    {linkLogoToHomePage ? (
      <a className="footer__logo-wrapper" href={EXTERNAL_ROUTES.home}>
        <PrimaryLogo className="footer__logo" mode="light" />
      </a>
    ) : (
      <div className="footer__logo-wrapper">
        <PrimaryLogo className="footer__logo" mode="light" />
      </div>
    )}
    <nav className="footer__internal-nav">
      <MailToGridlyLink to="hello@gogridly.com" subject="Contact Gridly">
        Contact Us
      </MailToGridlyLink>
      <Link to={ROUTES.privacyPolicy}>Privacy Policy</Link>
      <Link to={ROUTES.terms}>Terms & Conditions</Link>
    </nav>
    <SocialNav />
    <span className="footer__copyright">
      &copy;2022 Gridly. All rights reserved.
    </span>
  </footer>
)
