import React, { useMemo } from 'react'
import classnames from 'classnames'
import { useFormikContext } from 'formik'

interface Props extends React.ComponentPropsWithRef<'input'> {
  label: string
  name: string
}

export function withInputWrapper<T extends Props>(
  Komponent: React.FC<T>,
  classNamePrefix: string
) {
  const WrappedKomponent = React.forwardRef<HTMLInputElement, T>(
    (props, ref) => {
      const { values, setFieldValue } =
        useFormikContext<Record<string, unknown>>()
      const value = props.value || values[props.name]

      const hasValue = useMemo(
        () => value !== null && value !== undefined && value !== '',
        [value]
      )

      const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget

        setFieldValue(name, value)

        props.onChange?.(event)
      }

      WrappedKomponent.displayName = 'WrappedKomponent'

      return (
        <label
          className={classnames(
            `${classNamePrefix}-input`,
            {
              [`${classNamePrefix}-input--disabled`]:
                props.disabled || props.readOnly,
            },
            props.className
          )}
        >
          <span
            className={classnames([
              `${classNamePrefix}-input__label`,
              { 'visually-hidden': !hasValue },
            ])}
          >
            {props.label}
          </span>

          <Komponent {...props} ref={ref} onChange={onChange} />
        </label>
      )
    }
  )

  return WrappedKomponent
}
