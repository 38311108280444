import React from 'react'
import classnames from 'classnames'

import { MaxWidth } from '../../MaxWidth'

import './Sandbox.scss'

interface Props extends React.PropsWithChildren {
  subtitle?: string
  title: string
}

export const Sandbox: React.FC<Props> = ({ children, subtitle, title }) => {
  return (
    <div className="project-sandbox">
      <MaxWidth>
        <div className="project-sandbox__content">
          <h5
            className={classnames('project-sandbox__title', {
              'project-sandbox__title-with-subtitle': !subtitle,
            })}
          >
            {title}
          </h5>

          {subtitle && (
            <h6 className="project-sandbox__subtitle">{subtitle}</h6>
          )}

          {children}
        </div>

        <div className="project-sandbox__triangle"></div>
      </MaxWidth>
    </div>
  )
}
