import React from 'react'
import classnames from 'classnames'

import { ROUTES } from '../../routes/constants'
import { Project } from '../../types'
import { createEstimateRangeFromValue, formatDollars } from '../../helpers'
import { ProjectTileImage, RightArrowInBlueCircle } from '../media'
import { TileLink } from '../TileLink'

import './ProjectTileLink.scss'
import { useDisplaySettings } from '../../context'

const getCostTextForProject = (project: Project, showFinanced: boolean) => {
  const { net_cost, net_cost_text, financing, financed_monthly_cost } = project

  if (showFinanced) {
    return financing
      ? `${formatDollars(parseFloat(financed_monthly_cost || '0'), {
          fractionDigits: 0,
        })}/mo`
      : 'Financing Not Available'
  } else {
    return net_cost_text
      ? net_cost_text
      : `$${createEstimateRangeFromValue(parseFloat(net_cost || '0'))}`
  }
}

type Props = { className?: string; project: Project }

export const ProjectTileLink: React.VFC<Props> = ({ className, project }) => {
  const { showFinanced } = useDisplaySettings()

  const {
    slug,
    annual_savings,
    description,
    name,
    rebate,
    carbon_impact,
    estimated_project_time,
    recommended,
    status,
    formatted_status,
  } = project

  const costText = getCostTextForProject(project, showFinanced)

  return (
    <li className="project-tile-link">
      <TileLink
        aria-label={`${name}${status ? `: ${formatted_status}` : ''}`}
        className={classnames(
          'project-tile-link__tile',
          {
            ['project-tile-link__tile--disabled']: !recommended,
          },
          className
        )}
        to={ROUTES.projects[slug]}
        disabled={!recommended}
      >
        <div className="project-tile-link__info">
          <ProjectTileImage
            className="project-tile-link__image"
            projectSlug={slug}
            projectStatus={status}
            projectStatusFormatted={formatted_status}
          />

          <p className="project-tile-link__name">{name}</p>

          <p className="project-tile-link__description">{description}</p>
        </div>

        <div className="project-tile-link__attribute">
          <p className="project-tile-link__attribute-descriptor">
            Yearly Energy Cost Savings
          </p>

          <p className="project-tile-link__attribute-datum">
            {formatDollars(parseFloat(annual_savings || '0'), {
              fractionDigits: 0,
            })}
          </p>
        </div>

        <div className="project-tile-link__attribute">
          <p className="project-tile-link__attribute-descriptor">
            Yearly Carbon Reduction
          </p>

          <p className="project-tile-link__attribute-datum">{carbon_impact}</p>
        </div>

        <div className="project-tile-link__attribute">
          <p className="project-tile-link__attribute-descriptor">
            Total Project Lead Time
          </p>

          <p className="project-tile-link__attribute-datum">
            {estimated_project_time}
          </p>
        </div>

        <div className="project-tile-link__attribute">
          <p className="project-tile-link__attribute-descriptor">
            Quick Cost After <br aria-hidden />
            Rebates (
            {formatDollars(parseFloat(rebate || '0'), { fractionDigits: 0 })})
          </p>
          <p className="project-tile-link__attribute-datum">{costText}</p>
        </div>

        {recommended && (
          <RightArrowInBlueCircle className="project-tile-link__tile-arrow" />
        )}
      </TileLink>
    </li>
  )
}
