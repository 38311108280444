import React from 'react'

import { useDisplaySettings } from '../../context/DisplaySettings'

import './ToggleFinancedButton.scss'

export const ToggleFinancedButton: React.FC = () => {
  const { showFinanced, toggleShowFinanced } = useDisplaySettings()

  return (
    <div className="toggle-financed-button__wrapper">
      <button
        type="button"
        className="toggle-financed-button__button"
        onClick={toggleShowFinanced}
        disabled={!showFinanced}
      >
        Buy
      </button>
      <button
        type="button"
        className="toggle-financed-button__button"
        disabled={showFinanced}
        onClick={toggleShowFinanced}
      >
        Finance
      </button>
    </div>
  )
}
