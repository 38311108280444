import React from 'react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

import {
  DisplaySettingsProvider,
  PropertyDataProvider,
  ToastProvider,
} from './context'
import { SWRConfig } from './swr'
import { Router } from './routes'

const LD_CLIENT_SIDE_ID = window.env?.LAUNCH_DARKLY_CLIENT_SIDE_ID

const _App: React.FC = () => (
  <SWRConfig>
    <ToastProvider>
      <PropertyDataProvider>
        <DisplaySettingsProvider>
          <Router />
        </DisplaySettingsProvider>
      </PropertyDataProvider>
    </ToastProvider>
  </SWRConfig>
)

export const App = withLDProvider({ clientSideID: LD_CLIENT_SIDE_ID })(_App)
