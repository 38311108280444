import { GRIDLY_QUALITIES } from '../../../constants/GridlyQualities'

enum SOLAR_KWH_NEED {
  CURRENT_USAGE = 'current',
  FUTURE_USAGE = 'future',
}

export const constants = {
  ROOF_CONDITION_OPTIONS: [
    { value: GRIDLY_QUALITIES.POOR, label: 'Poor' },
    { value: GRIDLY_QUALITIES.AVERAGE, label: 'Average' },
    { value: GRIDLY_QUALITIES.EXCELLENT, label: 'Excellent' },
  ],
  ROOF_EXPOSURE_OPTIONS: [
    { value: GRIDLY_QUALITIES.POOR, label: 'More than 50% shaded' },
    { value: GRIDLY_QUALITIES.AVERAGE, label: '25 - 50% shaded' },
    { value: GRIDLY_QUALITIES.EXCELLENT, label: 'Full Sun' },
  ],
  SOLAR_KWH_NEED_OPTIONS: [
    {
      value: SOLAR_KWH_NEED.CURRENT_USAGE,
      label: 'My current electricity usage',
    },
    { value: SOLAR_KWH_NEED.FUTURE_USAGE, label: '100% Electrified Home' },
  ],
}
