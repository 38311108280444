interface BaseOpts extends Intl.NumberFormatOptions {
  fractionDigits?: number
  format?: 'shorthand' | 'longhand'
}
interface ShorthandOpts extends BaseOpts {
  fractionDigits?: 0 | 1
  format: 'shorthand'
}
interface LonghandOpts extends BaseOpts {
  format: 'longhand'
}

const TEN_THOUSAND = 10000
const ONE_THOUSAND = 1000
const shortHandShiftByOffset = 1e-3

export function formatDollars(dollars: number, opts?: ShorthandOpts): string
export function formatDollars(dollars: number, opts?: LonghandOpts): string
export function formatDollars(dollars: number, opts?: BaseOpts): string
export function formatDollars(dollars: number, opts: BaseOpts = {}) {
  const moreThanTenThousand = dollars >= TEN_THOUSAND
  const lessThanOneThousand = dollars < ONE_THOUSAND
  const formatShorthand = opts.format === 'shorthand'
  const formatLonghand = opts.format === 'longhand'
  const fractionDigits =
    formatShorthand && (lessThanOneThousand || moreThanTenThousand)
      ? 0
      : opts.fractionDigits ?? 0
  const compactNotation =
    formatShorthand || (moreThanTenThousand && !formatLonghand)

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: compactNotation ? 'compact' : 'standard',
    compactDisplay: 'short',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })

  const baseShiftBy = parseFloat(`1e${fractionDigits}`)
  const shiftBy =
    formatShorthand && !lessThanOneThousand
      ? baseShiftBy * shortHandShiftByOffset
      : baseShiftBy

  const floored = Math.floor(dollars * shiftBy) / shiftBy

  return formatter.format(floored)
}
