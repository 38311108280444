import React from 'react'

import { useProperty, useUser } from '../../api'
import {
  AddressStreetViewImage,
  Button,
  GreenLinkIcon,
  MailToGridlyLink,
  MaxWidth,
  MyAccountProfileForm,
  ReadOnlyField,
  Tile,
} from '../../components'
import { User } from '../../types'
import { useToast } from '../../context'

import './MyAccountPage.scss'

export const MyAccountPage: React.FC = () => {
  const { setSuccessToast, setErrorToast } = useToast()

  const {
    user,
    isLoading: isLoadingUser,
    error: errorLoadingUser,
    signOut,
    updateUser,
  } = useUser()

  const {
    property,
    isLoading: isLoadingProperty,
    error: errorLoadingProperty,
  } = useProperty()

  if (errorLoadingUser || errorLoadingProperty) {
    return (
      <div>
        Error: {errorLoadingUser?.message || errorLoadingProperty?.message}
      </div>
    )
  }

  if (isLoadingUser || isLoadingProperty) {
    return <div>Loading...</div>
  }

  const {
    first_name,
    last_name,
    phone_number,
    email,
    subscribe_to_email_updates,
  } = user

  const onSubmit = async (values: Partial<User>) => {
    try {
      await updateUser({ ...user, ...values })

      setSuccessToast()
    } catch (e) {
      setErrorToast()

      throw e
    }
  }

  return (
    <MaxWidth>
      <div className="my-account-page">
        <div className="my-account-page__header-row">
          <h1>Account</h1>

          <Button onClick={signOut} variant="wire" small>
            Sign Out
          </Button>
        </div>

        <Tile className="my-account-page__tile" shadow={false}>
          <div className="my-account-page__property">
            <span className="my-account-page__property-street-view-image">
              <AddressStreetViewImage propertyData={property.data} fov="90" />
            </span>

            <div className="my-account-page__property-address">
              <p className="my-account-page__property-street-address">
                {property.formattedStreetAddress}
              </p>

              <p className="my-account-page__property-city-state-zip">
                {property.formattedCityStateZip}
              </p>
            </div>
          </div>

          <GreenLinkIcon className="my-account-page__green-link-icon" />

          <ReadOnlyField
            className="my-account-page__read-only-field"
            label="Email Address"
            value={email}
          />

          <MailToGridlyLink
            className="my-account-page__email-change-request-link"
            to="hello@gogridly.com"
            subject="Account Email Address Change Request"
          >
            Change Email
          </MailToGridlyLink>
        </Tile>

        <MyAccountProfileForm
          onSubmit={onSubmit}
          initialValues={{
            first_name,
            last_name,
            phone_number,
            subscribe_to_email_updates,
          }}
        />
      </div>
    </MaxWidth>
  )
}
