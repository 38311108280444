import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios, { AxiosResponse } from 'axios'
import useSWR from 'swr'

import { User } from '../../types'
import { signOut as signOutApi } from '../signOut'
import { updateUser as updateUserApi, UpdateUser } from '../updateUser'
import { routes } from '../constants'
import { ROUTES } from '../../routes/constants'

type UserApiLoading = {
  user: undefined
  isLoading: true
  error: undefined
  signOut: () => Promise<AxiosResponse>
  updateUser: UpdateUser
}

type UserApiError = {
  user: undefined
  isLoading: false
  error: Error
  signOut: () => Promise<AxiosResponse>
  updateUser: UpdateUser
}

type UserApiResolved = {
  user: User
  isLoading: false
  error: undefined
  signOut: () => Promise<AxiosResponse>
  updateUser: UpdateUser
}

type UserApi = UserApiLoading | UserApiResolved | UserApiError

export const useUser = (): UserApi => {
  const navigate = useNavigate()
  const { data, error, mutate } = useSWR<User>(routes.currentUser)

  useEffect(() => {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      navigate(ROUTES.signIn)
    }
  }, [error, navigate])

  const signOut = async () => {
    const response = await signOutApi()
    await mutate()

    return response
  }

  const updateUser = async (user: User) => {
    const response = await updateUserApi(user)
    await mutate()

    return response
  }

  if (data) {
    return {
      user: data,
      isLoading: false,
      error: undefined,
      signOut,
      updateUser,
    }
  } else if (error) {
    return {
      user: undefined,
      isLoading: false,
      error,
      signOut,
      updateUser,
    }
  } else {
    return {
      user: undefined,
      isLoading: true,
      error: undefined,
      signOut,
      updateUser,
    }
  }
}
