import React from 'react'

import { Link } from 'react-router-dom'
import { getCookie } from '../../../cookies'
import { EXTERNAL_ROUTES, ROUTES } from '../../../routes'
import { MaxWidth } from '../../MaxWidth'

import { PrimaryLogo } from '../../media'
import './LandingPageHeader.scss'

export const LandingPageHeader: React.FC = () => {
  const jwt = getCookie('jwt')
  const signInUrl = jwt ? ROUTES.actionPlan : ROUTES.signIn

  return (
    <MaxWidth wide>
      <div className="landing-page-header">
        <a
          className="landing-page-header__logo-wrapper"
          href={EXTERNAL_ROUTES.home}
        >
          <PrimaryLogo className="landing-page-header__logo" mode="light" />
        </a>

        <span className="landing-page-header__link">
          Already have an account? <Link to={signInUrl}>Sign In</Link>
        </span>
      </div>
    </MaxWidth>
  )
}
