import React from 'react'

import { MaxWidth } from '../../MaxWidth'

import './Header.scss'

interface Props extends React.PropsWithChildren {
  recommendationText: string
  recommendationCTA?: JSX.Element
  title: string
  whyText: string
}
export const Header: React.FC<Props> = ({
  children,
  recommendationCTA,
  recommendationText,
  title,
  whyText,
}) => {
  return (
    <MaxWidth backgroundColor="blue">
      <div className="project-header">
        <h1 className="project-header__title">{title}</h1>

        <div className="project-header__recommendation">
          <h5 className="project-header__titled-paragraph-title">
            Our Recommendation
          </h5>

          <p className="project-header__titled-paragraph-body">
            {recommendationText}
          </p>

          {!!recommendationCTA && (
            <div className="project-header__recommendation-cta">
              {recommendationCTA}
            </div>
          )}
        </div>

        <div className="project-header__why">
          <h5 className="project-header__titled-paragraph-title">Why?</h5>
          <p className="project-header__titled-paragraph-body">{whyText}</p>
        </div>

        <div className="project-header__highlights">{children}</div>
      </div>
    </MaxWidth>
  )
}
