import React from 'react'

import { Project } from '../../../types'
import { useDisplaySettings } from '../../../context'
import { MaxWidth } from '../../MaxWidth'
import { Markdown } from '../../Markdown'
import { ProjectRecommendationImage, GridlyRecommends } from '../../media/'
import { FinancedCostEstimates } from './FinancedCostEstimates'
import { UpfrontCostEstimates } from './UpfrontCostEstimates'
import { SavingsEstimates } from './SavingsEstimates'

import './Recommendation.scss'
import {
  GRIDLY_FINANCING_DISCLAIMER,
  GRIDLY_NON_FINANCED_PROJECT_DISCLAIMERS,
} from '../../../constants'

export type Props = {
  title: string
  propertyIsEligible: boolean
  project: Project
  exploreLink?: React.ReactElement
  hideIncentiveLink?: boolean
}

export const Recommendation: React.FC<Props> = ({
  title,
  propertyIsEligible,
  project,
  exploreLink,
  hideIncentiveLink,
}) => {
  const { showFinanced } = useDisplaySettings()

  const footnotes = showFinanced
    ? [GRIDLY_FINANCING_DISCLAIMER]
    : GRIDLY_NON_FINANCED_PROJECT_DISCLAIMERS[project.slug]

  const hasFootnotes = Boolean(footnotes.length)

  if (!propertyIsEligible) {
    return (
      <div className="project-recommendation">
        <MaxWidth backgroundColor="white" wide>
          Let&apos;s find you another project
        </MaxWidth>
      </div>
    )
  }

  return (
    <div className="project-recommendation">
      <MaxWidth backgroundColor="white" wide>
        <div className="project-recommendation__project">
          <div className="project-recommendation__project-info">
            <div className="project-recommendation__image">
              <ProjectRecommendationImage projectSlug={project.slug} />
            </div>

            <div className="project-recommendation__main">
              <GridlyRecommends className="project-recommendation__gridly-recommends" />

              <h4 className="project-recommendation__title">{title}</h4>

              <Markdown>{project.recommendation_description}</Markdown>
            </div>

            <div className="product-recommendation__sidebar">
              {showFinanced ? (
                <FinancedCostEstimates
                  {...project}
                  hasFootnotes={hasFootnotes}
                />
              ) : (
                <UpfrontCostEstimates
                  {...project}
                  hasFootnotes={hasFootnotes}
                  hideIncentiveLink={Boolean(hideIncentiveLink)}
                />
              )}

              <SavingsEstimates {...project} showFinanced={showFinanced} />

              {exploreLink && (
                <div className="project-recommendation__sidebar-explore-link-wrapper">
                  {exploreLink}
                </div>
              )}

              {footnotes?.map((note) => (
                <p
                  key={note}
                  className="product-recommendation__sidebar-footnote"
                >
                  {note}
                </p>
              ))}
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  )
}
