import React from 'react'

import StepOne from './HotStepper_step1.svg'
import StepTwo from './HotStepper_step2.svg'
import StepThree from './HotStepper_step3.svg'
import './HotStepper.scss'

interface Props extends React.ComponentPropsWithoutRef<'img'> {
  currentStep: 1 | 2 | 3
}

export const HotStepper: React.FC<Props> = ({ currentStep, ...props }) => {
  switch (currentStep) {
    case 1:
      return (
        <img
          {...props}
          alt="step one of three"
          className="hot-stepper"
          src={StepOne}
        />
      )
    case 2:
      return (
        <img
          {...props}
          alt="step two of three"
          className="hot-stepper"
          src={StepTwo}
        />
      )
    case 3:
      return (
        <img
          {...props}
          alt="step three of three"
          className="hot-stepper"
          src={StepThree}
        />
      )
  }
}
