import React from 'react'

import './SmartThermostatProduct.scss'

type Props = {
  imageSrc: string
  manufacturer: string
  name: string
  description: string
  buyNowHref: string
}

export const SmartThermostatProduct: React.VFC<Props> = ({
  imageSrc,
  manufacturer,
  name,
  description,
  buyNowHref,
}) => (
  <div className="smart-thermostat-product">
    <img alt={`image of ${name} from vendor`} src={imageSrc} />
    <div className="smart-thermostat-product__headings">
      <h6 className="smart-thermostat-product__manufacturer">{manufacturer}</h6>
      <h4 className="smart-thermostat-product__name">{name}</h4>
    </div>

    <p className="smart-thermostat-product__description">{description}</p>

    <a
      className="smart-thermostat-product__link"
      href={buyNowHref}
      target="_blank"
    >
      Buy Now on Amazon
    </a>
  </div>
)
