import React from 'react'
import classnames from 'classnames'

import { Property } from '../../../types'
import { PropertyDataModel } from '../../../models'
import streetViewFallback from '../../../images/streetViewFallback.png'
import './AddressStreetViewImage.scss'

interface Props extends React.ComponentPropsWithoutRef<'img'> {
  propertyData: Property
  fov?: `${number}`
}

export const AddressStreetViewImage: React.FC<Props> = ({
  propertyData,
  fov = '80',
  ...props
}) => {
  const propertyDataModel = new PropertyDataModel(propertyData)
  const streetViewQueryParams = {
    location: propertyDataModel.formattedSingleLineAddress,
    key: env.REACT_APP_GOOGLE_API_KEY,
    size: '600x400',
    fov,
    return_error_code: 'true',
  }

  const streetViewQueryString = new URLSearchParams(
    streetViewQueryParams
  ).toString()

  return (
    <img
      alt={`${propertyDataModel.formattedSingleLineAddress} viewed from the street`}
      {...props}
      className={classnames('address-street-view-image', props.className)}
      src={`https://maps.googleapis.com/maps/api/streetview?${streetViewQueryString}`}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null
        currentTarget.src = streetViewFallback
      }}
    />
  )
}
