import React from 'react'
import { Link } from 'react-router-dom'
import { LinkModel } from '../../../models/LinkModel'

interface Props extends React.PropsWithChildren {
  href: string
}

export const MarkdownLink: React.FC<Props> = ({ children, href }) =>
  LinkModel.isExternalLink(href) ? (
    <a href={href} rel="noreferrer" target="_blank">
      {children}
    </a>
  ) : (
    <Link to={href ?? '#'}>{children}</Link>
  )
