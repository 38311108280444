enum GRIDLY_SHARED_FUEL_CODES {
  ELECTRICITY = 'Electricity',
  NONE = 'None',
}

enum GRIDLY_HEATING_ONLY_FUEL_CODES {
  COAL = 'Coal',
  HEATING_OIL = 'Home Heating Oil',
  NATURAL_GAS = 'Natural Gas',
  PROPANE = 'Propane/LPG',
  SOLAR = 'Solar Heating',
  WOOD = 'Wood',
}

export const GRIDLY_HEATING_FUEL_CODES = {
  ...GRIDLY_SHARED_FUEL_CODES,
  ...GRIDLY_HEATING_ONLY_FUEL_CODES,
}
export const GRIDLY_COOLING_FUEL_CODES = {
  ...GRIDLY_SHARED_FUEL_CODES,
}

export enum GRIDLY_HOT_WATER_FUEL_CODES {
  ELECTRICITY = 'Electricity',
  HEATING_OIL = 'Home Heating Oil',
  NATURAL_GAS = 'Natural Gas',
  PROPANE = 'Propane/LPG',
}
