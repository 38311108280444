import React from 'react'

import { PropertyFormValues } from '../../../types'
import { useProject, useProperty } from '../../../api'
import { useToast } from '../../../context'
import { CommunitySolarForm, Project, RenewablePie } from '../../../components'
import {
  GRIDLY_CARBON_IMPACT_DESCRIPTIONS,
  GRIDLY_PROJECT_SLUGS,
} from '../../../constants'
import { ROUTES } from '../../../routes'
import { LinkButton } from '../../../components/LinkButton'

export const CommunitySolarPage: React.FC = () => {
  const { setSuccessToast, setErrorToast } = useToast()
  const {
    error: errorLoadingProperty,
    isLoading: isLoadingProperty,
    property,
    updateProperty,
  } = useProperty()
  const {
    error: errorLoadingProject,
    isLoading: isLoadingProject,
    project,
  } = useProject(GRIDLY_PROJECT_SLUGS.COMMUNITY_SOLAR)

  if (errorLoadingProperty || errorLoadingProject) {
    return (
      <>Error {errorLoadingProject?.message || errorLoadingProject?.message}</>
    )
  }

  if (isLoadingProperty || isLoadingProject) {
    return <>Loading...</>
  }

  const handleSubmit = async (propertyData: PropertyFormValues) => {
    if (property.id) {
      try {
        await updateProperty(property.id, propertyData)

        setSuccessToast()
      } catch (e) {
        setErrorToast()

        throw e
      }
    }
  }

  return (
    <>
      <Project.Header
        title="Sign Up for Community Solar"
        recommendationText="Source your electricity from a local, subscriber-based, community solar facility."
        whyText="100% renewable solar energy with no panel installation."
      >
        <Project.Highlight
          description="Average discounts available from 5% to 15%*"
          takeAway="15%"
          takeAwayStyle="solid"
        />

        <Project.Highlight
          description={`Save up to $${project.annual_savings} in energy costs per year.*`}
          takeAway={`$${project.annual_savings}`}
          takeAwayStyle="outline"
        />

        {project.carbon_impact && (
          <Project.Highlight
            description={
              GRIDLY_CARBON_IMPACT_DESCRIPTIONS[project.carbon_impact]
            }
            takeAway={project.carbon_impact}
            takeAwayStyle="outline"
          />
        )}

        <Project.Highlight
          description={`Increase your renewable energy from ${property.data.total_utility_percent_renewable}% to ${project.future_total_utility_percent_renewable}%`}
          takeAway={
            <RenewablePie
              percentRenewable={parseInt(
                project.future_total_utility_percent_renewable || '0'
              )}
            />
          }
        />
      </Project.Header>

      <Project.Sandbox title="We'll help you choose the perfect community solar plan">
        <CommunitySolarForm
          initialValues={property.toFormValues()}
          onSubmit={handleSubmit}
        />
      </Project.Sandbox>

      <Project.Recommendation
        title="Community Solar Marketplace"
        project={project}
        propertyIsEligible={property.isEligibleForCommunitySolar}
        hideIncentiveLink={true}
        exploreLink={
          <LinkButton
            to={
              ROUTES.projectExplorePages[GRIDLY_PROJECT_SLUGS.COMMUNITY_SOLAR]
            }
            variant="blue"
          >
            Explore Your Options
          </LinkButton>
        }
      />

      <Project.Education project={project} />
    </>
  )
}
