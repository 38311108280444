const TEN_THOUSAND = 10000
const ONE_THOUSAND = 1000

export const createEstimateRangeFromValue = (value: number) => {
  const { format } = new Intl.NumberFormat()
  const low = Math.max(0, Math.round(value * 0.9))
  const high = Math.max(0, Math.round(value * 1.1))
  const lowInThousands = Math.round(low / ONE_THOUSAND)
  const highInThousands = Math.round(high / ONE_THOUSAND)

  if (low === high) {
    return format(low)
  }

  if (low < TEN_THOUSAND) {
    return `${format(low)} - ${format(high)}`
  } else {
    return `${format(lowInThousands)} - ${format(highInThousands)}K`
  }
}
