import React from 'react'
import classnames from 'classnames'
import { useMatch, Link, LinkProps } from 'react-router-dom'

import './HeaderLink.scss'

export const HeaderLink: React.FC<LinkProps> = (props) => {
  const match = useMatch(props.to.toString())

  return (
    <Link
      {...props}
      className={classnames(
        'header-link',
        {
          'header-link--active': match,
        },
        props.className
      )}
    />
  )
}
