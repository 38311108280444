import { createContext } from 'react'

type ToastSetter = (message?: string) => void

export type ToastContextType = {
  setSuccessToast: ToastSetter
  setErrorToast: ToastSetter
}

export const ToastContext = createContext<ToastContextType | undefined>(
  undefined
)
