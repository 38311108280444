import {
  GRIDLY_INSULATION_TYPES,
  GRIDLY_WINDOW_TYPES,
  GRIDLY_INSULATION_LEVELS,
} from '../../../constants'
import { GRIDLY_INSULATION_LEVEL_LABELS } from '../../../constants/GridlyInsulationLevelLabels'
import { constantToOptions } from '../../../helpers'

export const insulationTypeOptions = constantToOptions(GRIDLY_INSULATION_TYPES)

export const windowTypeOptions = constantToOptions(GRIDLY_WINDOW_TYPES)

export const insulationLevelOptions = constantToOptions(
  GRIDLY_INSULATION_LEVELS,
  GRIDLY_INSULATION_LEVEL_LABELS
)

export const homeAssessmentOptions = [
  { value: false, label: 'Get a Home Energy Audit' },
  { value: true, label: 'How much will an insulation project cost?' },
]
