import React from 'react'
import classnames from 'classnames'
import { useFormikContext } from 'formik'

import { Checkmark } from '../../media'
import './Checkbox.scss'

interface Props extends React.ComponentPropsWithRef<'input'> {
  label: string
  name: string
}

const Checkbox: React.FC<Props> = React.forwardRef(
  ({ label, ...props }, ref) => {
    const { setFieldValue, setFieldTouched, setStatus } = useFormikContext()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFieldTouched(props.name)
      setStatus({ dirty: props.value !== e.target.value })
      setFieldValue(props.name, e.target.value, true)

      props.onChange?.(e)
    }

    return (
      <label
        className={classnames(
          'checkbox__label',
          {
            'checkbox__label--checked': props.value,
          },
          props.className
        )}
      >
        <span aria-hidden className="checkbox__custom-input">
          {props.value && <Checkmark variant="blue" />}
        </span>
        {label}
        <input
          {...props}
          onChange={handleChange}
          type="checkbox"
          checked={Boolean(props.value)}
          className="checkbox__actual-input"
          ref={ref}
        />
      </label>
    )
  }
)

Checkbox.displayName = 'Checkbox'

export { Checkbox }
