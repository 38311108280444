import React from 'react'

import { GRIDLY_PROJECT_SLUGS } from '../../../../../constants'
import {
  VendorListLayout,
  VendorInfoTile,
  VendorTileLink,
} from '../../../../../components'
import { ROUTES } from '../../../../../routes/constants'
import { useProject } from '../../../../../api'

export const SolarPanelsExplorePageV2: React.FC = () => {
  const { error, isLoading, project } = useProject(
    GRIDLY_PROJECT_SLUGS.SOLAR_PANELS
  )

  if (error) {
    return <>Error {error.message}</>
  }

  if (isLoading) {
    return <>Loading...</>
  }

  return (
    <VendorListLayout
      heading="Choose the Solar Installation approach that works best for you"
      subheading="Gridly has partnered with these installers to provide superior quality solar panel installations in your area."
      projectSlug={GRIDLY_PROJECT_SLUGS.SOLAR_PANELS}
      InfoTile={() => (
        <VendorInfoTile
          Title={() => (
            <h4>
              <span className="green-text">
                Get a proposal & quote <br aria-hidden />
                from an experienced <br aria-hidden />
              </span>
              Rooftop Solar company
            </h4>
          )}
        >
          <p className="body-2 body-2--bold">What you should know</p>

          <p className="body-2">
            The solar installer will provide you with the final design for the
            set of solar panels optimal for your roof shape and exposure.
          </p>

          <p className="body-2">
            The final design will also contain an estimate of the amount of
            electricity the proposed system can generate, based on industry sun
            and cloud cover databases.
          </p>
        </VendorInfoTile>
      )}
    >
      {project.solutions.map((solution) => (
        <VendorTileLink
          key={solution.id}
          solution={solution}
          to={ROUTES.requestForProposal(`${solution.id}`)}
          disabled={Boolean(
            project.request_for_proposals.find(
              ({ solution_id }) => solution_id === solution.id
            )
          )}
        />
      ))}
    </VendorListLayout>
  )
}
