import { GRIDLY_PROJECT_SLUGS } from './GridlyProjectSlugs'

export const GRIDLY_FINANCING_DISCLAIMER =
  '* The monthly payment amounts shown are for illustration purposes. Actual payments for your project will be determined by the final cost and the terms provided by the financing provider that you choose.'
const GRIDLY_NON_INSTALLER_PROJECT_DISCLAIMER =
  '* Projected cost and saving amounts and energy usage & needs are estimates based on the information available about the residence. The data used in these estimates may be verified and updated on this site.'
const GRIDLY_INSTALLER_PROJECT_DISCLAIMER =
  '* Projected cost and saving amounts and expected applicable rebates and incentives are estimates based on the information available about the residence. Final costs and benefits should be verified by a qualified professional before installation.'

export const GRIDLY_NON_FINANCED_PROJECT_DISCLAIMERS: Record<
  GRIDLY_PROJECT_SLUGS,
  string[]
> = {
  [GRIDLY_PROJECT_SLUGS.COMMUNITY_SOLAR]: [
    GRIDLY_NON_INSTALLER_PROJECT_DISCLAIMER,
  ],
  [GRIDLY_PROJECT_SLUGS.CONVERT_WATER_HEATER]: [
    GRIDLY_INSTALLER_PROJECT_DISCLAIMER,
  ],
  [GRIDLY_PROJECT_SLUGS.WHOLE_HOME_MONITORING]: [
    "* Look for the 'GridlyHomeEnergy' discount code at checkout for a 5% discount available only to Gridly customers.",
  ],
  [GRIDLY_PROJECT_SLUGS.HVAC]: [GRIDLY_INSTALLER_PROJECT_DISCLAIMER],
  [GRIDLY_PROJECT_SLUGS.INSULATION]: [
    '* Savings estimates assume completion of all recommended weatherization upgrades. Projected cost and saving amounts and expected applicable rebates and incentives are estimates based on the information available about the residence. Final costs and benefits should be verified by a qualified professional before installation.',
  ],
  [GRIDLY_PROJECT_SLUGS.RENEWABLE_ENERGY]: [
    GRIDLY_NON_INSTALLER_PROJECT_DISCLAIMER,
  ],
  [GRIDLY_PROJECT_SLUGS.SMART_THERMOSTAT]: [
    GRIDLY_NON_INSTALLER_PROJECT_DISCLAIMER,
  ],
  [GRIDLY_PROJECT_SLUGS.SOLAR_PANELS]: [GRIDLY_INSTALLER_PROJECT_DISCLAIMER],
}
