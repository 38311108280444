import React from 'react'
import { Outlet } from 'react-router-dom'
import classnames from 'classnames'

import { withPropertyDataGuard } from '../../../hoc'
import { useScrollReset, useTheme } from '../../../hooks'
import { Footer } from '../..'
import './Layout.scss'

type Props = {
  background?: 'house--light' | 'house--dark' | 'house--graphic'
  Header?: React.FC
  linkFooterLogoToHomePage?: boolean
  width?: 'standard' | 'wide' | 'full-width'
}

export const Layout: React.FC<Props> = ({
  background,
  Header,
  linkFooterLogoToHomePage,
  width = 'standard',
}) => {
  useScrollReset()

  const isDarkTheme = background === 'house--dark'

  useTheme(isDarkTheme ? 'dark' : 'light')

  return (
    <div className="layout">
      <div
        className={classnames('layout__background', {
          'layout__background--house-light': background === 'house--light',
          'layout__background--house-dark': background === 'house--dark',
          'layout__background--house-graphic': background === 'house--graphic',
        })}
      >
        {Header && <Header />}

        <main
          className={classnames('layout__content', {
            'layout__content--wide': width === 'wide',
            'layout__content--full-width': width === 'full-width',
          })}
        >
          <Outlet />
        </main>
      </div>

      <Footer linkLogoToHomePage={linkFooterLogoToHomePage} />
    </div>
  )
}

export const LayoutWithPropertyDataGuard = withPropertyDataGuard(Layout)
