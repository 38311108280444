import useSWR from 'swr'

import { ElectricUtility } from '../../types'
import { routes } from '../constants'

type ElectricUtilitiesApiLoading = {
  electricUtilities: undefined
  isLoading: true
  error: undefined
}

type ElectricUtilitiesApiError = {
  electricUtilities: undefined
  isLoading: false
  error: Error
}

type ElectricUtilitiesApiResolved = {
  electricUtilities: ElectricUtility[]
  isLoading: false
  error: undefined
}

type ElectricUtilitiesApi =
  | ElectricUtilitiesApiLoading
  | ElectricUtilitiesApiError
  | ElectricUtilitiesApiResolved

export const useElectricUtilities = (
  postalCode: string | undefined | null
): ElectricUtilitiesApi => {
  const { data, error } = useSWR<ElectricUtility[]>(
    () => postalCode && `${routes.electricUtilities}?postal_code=${postalCode}`
  )

  if (data) {
    return {
      electricUtilities: data,
      isLoading: false,
      error: undefined,
    }
  } else if (error) {
    return {
      electricUtilities: undefined,
      isLoading: false,
      error: error,
    }
  } else {
    return {
      electricUtilities: undefined,
      isLoading: true,
      error: undefined,
    }
  }
}
