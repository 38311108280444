import React from 'react'
import { NextStepsItem, NextStepsList } from '../../../../../components'
import { GRIDLY_PROJECT_SLUGS } from '../../../../../constants'

export const HVACExplorePageV1: React.FC = () => {
  return (
    <NextStepsList
      projectSlug={GRIDLY_PROJECT_SLUGS.HVAC}
      imgAltText="Picture of an electric heat pump"
      title="Stop burning dirty fossil fuels in your home and convert to an
    air-sourced Heat Pump system."
    >
      <NextStepsItem
        step="Do a 'check up' on weatherization"
        explainer="Heat Pumps perform better when insulation is up to date and air leaks are sealed. Check out the Insulate Your Home project on your action plan for our recommendations."
      />

      <NextStepsItem
        step="Compare HVAC system choices and sizing"
        explainer="Splits or Central…learn the differences. Understand what a load calculation means."
      />

      <NextStepsItem
        step="Request a detailed proposal"
        explainer="You'll want a licensed HVAC pro to visit, virtually or in-person, to do a room by room assessment of requirements. Always ask that the  proposal includes Manual J load calculations - that's the industry  standard to insure proper sizing."
      />
    </NextStepsList>
  )
}
