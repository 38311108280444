import { Education } from './Education'
import { Header } from './Header'
import { Highlight } from './Highlight'
import { Sandbox } from './Sandbox'
import { Recommendation } from './Recommendation'

export const Project = {
  Education,
  Header,
  Highlight,
  Sandbox,
  Recommendation,
}
