import React from 'react'

import { usePropertyData } from '../../context'
import { Button } from '../Button'
import { RestartIcon } from '../media'
import './StartOverButton.scss'

type Props = React.ComponentPropsWithoutRef<'button'>

export const StartOverButton: React.FC<Props> = ({ ...props }) => {
  const { resetPropertyData } = usePropertyData()

  const handleStartOver = () => {
    if (
      window.confirm(
        'Are you sure you want to start over? Your current data will be lost.'
      )
    ) {
      resetPropertyData()
    }
  }

  return (
    <Button
      {...props}
      className="start-over-button"
      onClick={handleStartOver}
      variant="wire"
    >
      <span className="start-over-button__desktop-content">
        Cancel & Start Over
      </span>
      <RestartIcon alt="" className="start-over-button__mobile-content" />
    </Button>
  )
}
