import { Address, GeocoderAddressComponent } from '../../../types'

const addressComponentTypesToParams: Record<string, string> = {
  street_number: 'street_number',
  route: 'street_name',
  locality: 'city',
  administrative_area_level_1: 'state',
  postal_code: 'postal_code',
}

const relevantTypes = Object.keys(addressComponentTypesToParams)

export const getAddressFromSelection = (
  selectedAddressComponents: GeocoderAddressComponent[]
): Address =>
  selectedAddressComponents
    .filter((ac) => relevantTypes.includes(ac.types[0]))
    .reduce(
      (acc, cur) => ({
        ...acc,
        [addressComponentTypesToParams[cur.types[0]]]: cur.short_name,
      }),
      {
        street_number: '',
        street_name: '',
        city: '',
        state: '',
        postal_code: '',
      }
    )
