import React from 'react'
import classnames from 'classnames'

import { Solution } from '../../types'
import { GridlyRecommends } from '../media'
import { TileLink } from '../TileLink'

import './VendorTileLink.scss'

interface Props {
  disabled?: boolean
  solution: Solution
  to: string
}

export const VendorTileLink: React.FC<Props> = ({ disabled, solution, to }) => {
  const { gridly_recommended, vendor } = solution

  return (
    <TileLink
      aria-label={vendor.name.toLowerCase()}
      className={classnames('vendor-tile-link', {
        'vendor-tile-link--disabled': disabled,
      })}
      to={to}
      disabled={disabled || !vendor.active}
      allowDisabledStyles={!vendor.active}
    >
      <div className="vendor-tile-link__logo-container">
        <img
          alt={`${vendor.name} logo`}
          className="vendor-tile-link__logo vendor-tile-link__logo--desktop"
          src={`/vendor-logos/${vendor.name}/desktop.svg`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = `/vendor-logos/${vendor.name}/desktop.png`
          }}
        />

        <img
          alt={`${vendor.name} logo`}
          className="vendor-tile-link__logo vendor-tile-link__logo--mobile"
          src={`/vendor-logos/${vendor.name}/mobile.svg`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = `/vendor-logos/${vendor.name}/desktop.png`
          }}
        />
      </div>

      <h5 className="vendor-tile-link__heading">{vendor.name}</h5>

      {gridly_recommended && (
        <span className="vendor-tile-link__endorsement">
          <GridlyRecommends />
        </span>
      )}

      <p className="vendor-tile-link__description">{vendor.description}</p>

      <div
        className={classnames('vendor-tile-link__cta', {
          'vendor-tile-link__cta--disabled': disabled,
        })}
      >
        {disabled ? 'Proposal Requested' : 'Request Proposal'}
      </div>
    </TileLink>
  )
}
