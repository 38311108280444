import React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import { LinkModel } from '../../models/LinkModel'
import './LinkButton.scss'

export interface ButtonProps extends React.ComponentPropsWithoutRef<'a'> {
  to: string
  variant: 'blue' | 'green'
  small?: boolean
}

export const LinkButton: React.FC<ButtonProps> = (props) => {
  const { className, small, to, variant, ...rest } = props
  const linkClass = classnames('link-button', className, {
    'link-button--green': variant === 'green',
    'link-button--blue': variant === 'blue',
    'link-button--small': small,
  })

  if (LinkModel.isExternalLink(to)) {
    return (
      <a
        className={linkClass}
        href={to}
        target="_blank"
        rel="noreferrer"
        {...rest}
      >
        {props.children}
      </a>
    )
  }

  return (
    <Link className={linkClass} to={to} {...rest}>
      {props.children}
    </Link>
  )
}
