import Percentage00 from './Percentage00.svg'
import Percentage05 from './Percentage05.svg'
import Percentage10 from './Percentage10.svg'
import Percentage15 from './Percentage15.svg'
import Percentage20 from './Percentage20.svg'
import Percentage25 from './Percentage25.svg'
import Percentage30 from './Percentage30.svg'
import Percentage35 from './Percentage35.svg'
import Percentage40 from './Percentage40.svg'
import Percentage45 from './Percentage45.svg'
import Percentage50 from './Percentage50.svg'
import Percentage55 from './Percentage55.svg'
import Percentage60 from './Percentage60.svg'
import Percentage65 from './Percentage65.svg'
import Percentage70 from './Percentage70.svg'
import Percentage75 from './Percentage75.svg'
import Percentage80 from './Percentage80.svg'
import Percentage85 from './Percentage85.svg'
import Percentage90 from './Percentage90.svg'
import Percentage95 from './Percentage95.svg'

export const SVGByIndex = [
  Percentage00,
  Percentage05,
  Percentage10,
  Percentage15,
  Percentage20,
  Percentage25,
  Percentage30,
  Percentage35,
  Percentage40,
  Percentage45,
  Percentage50,
  Percentage55,
  Percentage60,
  Percentage65,
  Percentage70,
  Percentage75,
  Percentage80,
  Percentage85,
  Percentage90,
  Percentage95,
]
