export const ceilingHeightOptions = [
  { value: "7.0", label: "7 ft."},
  { value: "7.5", label: "7.5 ft."},
  { value: "8.0", label: "8 ft."},
  { value: "8.5", label: "8.5 ft."},
  { value: "9.0", label: "9 ft."},
  { value: "9.5", label: "9.5 ft."},
  { value: "10.0", label: "10 ft."},
  { value: "11.0", label: "11 ft."},
  { value: "12.0", label: "12 ft."},
]
