import React from 'react'

import { percentToSVG } from '../../../helpers'
import { SVGByIndex } from './constants'

interface Props extends React.ComponentProps<'img'> {
  percentRenewable: number
}

export const RenewablePie: React.VFC<Props> = ({
  percentRenewable,
  ...props
}) => <img alt="" {...props} src={percentToSVG(percentRenewable, SVGByIndex)} />
