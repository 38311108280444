import React from 'react'
import { Field, Form, Formik, FormikHelpers } from 'formik'

import { SubmitButton, SelectInput } from '../../formElements'
import { homeAssessmentOptions, insulationLevelOptions } from './constants'

import './InsulationForm.scss'
import { PropertyFormValues } from '../../../types'

interface Props {
  initialValues: PropertyFormValues
  onSubmit: (values: PropertyFormValues) => Promise<void>
}

export const InsulationForm: React.VFC<Props> = ({
  initialValues,
  onSubmit,
}) => {
  const handleSubmit = async (
    values: PropertyFormValues,
    helpers: FormikHelpers<PropertyFormValues>
  ) => {
    try {
      await onSubmit(values)
    } catch (e) {
      helpers.resetForm()
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form className="insulation-form" role="form">
        <div className="insulation-form__fields">
          <Field
            as={SelectInput}
            label="Home Assessment"
            name="home_assessment"
            options={homeAssessmentOptions}
          />

          <Field
            as={SelectInput}
            label="Insulation Quality"
            name="wall_insulation_level"
            options={insulationLevelOptions}
          />
        </div>

        <SubmitButton className="insulation-form__submit" variant="wire" small>
          Refine Your Recommendation
        </SubmitButton>
      </Form>
    </Formik>
  )
}
