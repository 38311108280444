import React from 'react'
import classnames from 'classnames'
import { formatDollars } from '../../helpers'

const paymentFrequencyLabels = {
  once: '',
  monthly: 'per month',
}

interface Props {
  className?: string
  price: string | null
  paymentFrequency?: 'once' | 'monthly'
  forceSavings?: boolean
  shorthand?: boolean
}

export const PriceDetail: React.VFC<Props> = ({
  className,
  price,
  paymentFrequency = 'once',
  forceSavings = false,
  shorthand = false,
}) => {
  const dollars = parseFloat(price || '0').toFixed(0)
  const isSavings = forceSavings || dollars.startsWith('-')
  const absoluteDollars = dollars.replace('-', '')

  return (
    <span
      className={classnames(className, {
        'green-text--dark': isSavings,
      })}
    >
      {formatDollars(parseFloat(absoluteDollars), {
        format: shorthand ? 'shorthand' : 'longhand',
        fractionDigits: 0,
      })}{' '}
      {paymentFrequencyLabels[paymentFrequency]}
    </span>
  )
}
