import React from 'react'

import { Project } from '../../../../types'
import { PriceDetail } from '../../../PriceDetail'
import { ToggleFinancedButton } from '../../../ToggleFinancedButton'
import { EstimatesTable } from '../EstimatesTable'

type Props = Pick<
  Project,
  | 'financing'
  | 'financing_term_months'
  | 'financed_monthly_cost'
  | 'formatted_financing_term_rate'
> & {
  hasFootnotes: boolean
}

export const FinancedCostEstimates: React.FC<Props> = ({
  financing,
  financed_monthly_cost,
  financing_term_months,
  formatted_financing_term_rate,
  hasFootnotes,
}) => (
  <EstimatesTable
    heading={`Cost Estimates${hasFootnotes && '*'}`}
    headerRightContent={<ToggleFinancedButton />}
  >
    {financing ? (
      <>
        <tr>
          <td className="body-3">Project Cost After Incentives</td>
          <td>
            <PriceDetail
              className="body-2 body-2--bold"
              price={financed_monthly_cost}
              paymentFrequency="monthly"
            />
          </td>
        </tr>

        <tr>
          <td className="body-3">Assumed Term</td>
          <td className="body-3">{financing_term_months} months</td>
        </tr>

        <tr>
          <td className="body-3">Assumed Rate</td>
          <td className="body-3">{formatted_financing_term_rate}</td>
        </tr>
      </>
    ) : (
      <tr>
        <td className="body-2 body-2--bold" colSpan={2}>
          Financing Not Applicable
        </td>
      </tr>
    )}
  </EstimatesTable>
)
