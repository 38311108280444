import useSWR from 'swr'

import { routes } from '../constants'
import { RenewableEnergyPlan } from '../../types'
import { RenewableEnergyPlanForPropertyModel } from '../../models'
import { useProperty } from '../useProperty'

type RenewableEnergyPlanApiLoading = {
  renewableEnergyPlans: undefined
  isLoading: true
  error: undefined
}

type RenewableEnergyPlanApiError = {
  renewableEnergyPlans: undefined
  isLoading: false
  error: Error
}

type RenewableEnergyPlanApiResolved = {
  renewableEnergyPlans: RenewableEnergyPlanForPropertyModel[]
  isLoading: false
  error: undefined
}

type RenewableEnergyPlanApi =
  | RenewableEnergyPlanApiLoading
  | RenewableEnergyPlanApiError
  | RenewableEnergyPlanApiResolved

export const useRenewableEnergyPlans = (): RenewableEnergyPlanApi => {
  const { property, error: errorLoadingProperty } = useProperty()

  const { data, error } = useSWR<RenewableEnergyPlan[]>(
    property?.id ? routes.renewableEnergyPlans(property?.id) : null
  )

  if (data && property) {
    const renewableEnergyPlans = data.map(
      (plan) => new RenewableEnergyPlanForPropertyModel(plan, property)
    )

    return {
      renewableEnergyPlans,
      isLoading: false,
      error: undefined,
    }
  } else if (error || errorLoadingProperty) {
    return {
      renewableEnergyPlans: undefined,
      isLoading: false,
      error: error || errorLoadingProperty,
    }
  } else {
    return {
      renewableEnergyPlans: undefined,
      isLoading: true,
      error: undefined,
    }
  }
}
