import React from 'react'
import { Link } from 'react-router-dom'

import { ROUTES } from '../../../routes'
import { MaxWidth } from '../../MaxWidth'
import { PrimaryLogo } from '../../media'

import './ContentPageHeader.scss'

export const ContentPageHeader: React.FC = () => {
  return (
    <div className="content-page-header">
      <MaxWidth>
        <Link to={ROUTES.root}>
          <PrimaryLogo className="content-page-header__logo" mode="dark" />
        </Link>
      </MaxWidth>
    </div>
  )
}
