import React from 'react'
import { Field, Form, Formik, FormikHelpers } from 'formik'

import { AddressSatelliteImage, AddressStreetViewImage } from '../../media'
import { PropertyFormValues } from '../../../types'
import {
  SubmitButton,
  Fieldset,
  NumberInput,
  SelectInput,
  ThousandsMaskedInput,
  AutoSave,
} from '../../formElements'
import {
  basementTypeOptions,
  hotWaterDeviceTypeOptions,
  hotWaterFuelOptions,
  coolingDistributionOptions,
  heatingDeviceTypeOptions,
  heatingFuelOptions,
  coolingFuelOptions,
  electricHeatingDeviceTypeOptions,
} from './constants'
import { yesNoSelectOptions } from '../constants'

import './HomeDetailsForm.scss'
import { homeDetailsFormSchema } from './schema'
import { GRIDLY_HEATING_FUEL_CODES } from '../../../constants'

interface Props {
  initialValues: PropertyFormValues
  onSubmit: (values: PropertyFormValues) => Promise<void> | void
  autoSave?: boolean
  submitButtonText?: string
}

export const HomeDetailsForm: React.VFC<Props> = ({
  initialValues,
  onSubmit,
  autoSave = false,
  submitButtonText = "Let's Keep Going!",
}) => {
  const handleSubmit = async (
    values: PropertyFormValues,
    helpers: FormikHelpers<PropertyFormValues>
  ) => {
    try {
      await onSubmit(values)
    } catch (e) {
      helpers.resetForm()
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={homeDetailsFormSchema}
    >
      {({ values }) => (
        <Form className="home-details-form">
          <div className="home-details-form__images">
            <AddressStreetViewImage
              className="home-details-form__image"
              propertyData={initialValues}
            />

            <AddressSatelliteImage
              className="home-details-form__image"
              propertyData={initialValues}
            />
          </div>

          <div className="home-details-form__fields">
            <Fieldset
              legend="Basics"
              mobileColumnCount={2}
              desktopColumnCount={3}
            >
              <Field as={NumberInput} name="year_built" label="Year Built" />

              <Field
                as={ThousandsMaskedInput}
                name="total_sqft"
                label="Square Footage"
              />

              <Field as={NumberInput} name="num_stories" label="Stories" />

              <Field as={NumberInput} name="num_bedrooms" label="Bedrooms" />

              <Field
                as={NumberInput}
                name="num_full_bathrooms"
                label="Full Baths"
              />

              <Field
                as={NumberInput}
                name="num_partial_bathrooms"
                label="Partial Baths"
              />

              <Field
                as={SelectInput}
                name="basement_type"
                label="Basement"
                options={basementTypeOptions}
              />

              <Field
                as={SelectInput}
                name="smart_thermostat"
                label="Smart Thermostat"
                options={yesNoSelectOptions}
              />

              <Field
                as={SelectInput}
                name="solar_panels"
                label="Solar Panels"
                options={yesNoSelectOptions}
              />
            </Fieldset>

            <Fieldset
              legend="Home Heating"
              mobileColumnCount={1}
              desktopColumnCount={2}
            >
              <Field
                as={SelectInput}
                name="heating_fuel"
                label="Heating Fuel"
                options={heatingFuelOptions}
              />

              <Field
                as={SelectInput}
                name="heating_method"
                label="Heating Method"
                options={
                  values.heating_fuel === GRIDLY_HEATING_FUEL_CODES.ELECTRICITY
                    ? electricHeatingDeviceTypeOptions
                    : heatingDeviceTypeOptions
                }
              />
            </Fieldset>

            <Fieldset
              legend="Home Cooling"
              mobileColumnCount={1}
              desktopColumnCount={2}
            >
              <Field
                as={SelectInput}
                name="cooling_fuel"
                label="Cooling Fuel"
                options={coolingFuelOptions}
              />

              <Field
                as={SelectInput}
                name="cooling_distribution"
                label="Cooling Type"
                options={coolingDistributionOptions}
              />
            </Fieldset>

            <Fieldset
              legend="Hot Water"
              mobileColumnCount={1}
              desktopColumnCount={2}
            >
              <Field
                as={SelectInput}
                name="hot_water_fuel"
                label="Hot Water Fuel"
                options={hotWaterFuelOptions}
              />

              <Field
                as={SelectInput}
                name="hot_water_type"
                label="Device Type"
                options={hotWaterDeviceTypeOptions}
              />
            </Fieldset>
          </div>

          {autoSave ? (
            <AutoSave />
          ) : (
            <div className="home-details-form__buttons">
              <SubmitButton className="home-details-form__submit-button">
                {submitButtonText}
              </SubmitButton>
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}
