import React from 'react'

import { PropertyFormValues } from '../../types'
import { useElectricUtilities, useProperty, useUtilityData } from '../../api'
import { UtilityUsageForm } from '../../components'
import { useToast } from '../../context'

export const EditPropertyUtilitiesPage: React.VFC = () => {
  const { setSuccessToast, setErrorToast } = useToast()
  const {
    error: errorLoadingProperty,
    isLoading: isLoadingProperty,
    property,
    updateProperty,
  } = useProperty()

  const {
    error: errorLoadingElectricUtilities,
    isLoading: isLoadingElectricUtilities,
    electricUtilities,
  } = useElectricUtilities(property?.data.postal_code)

  const {
    error: errorLoadingUtilityData,
    isLoading: isLoadingUtilityData,
    utilityData,
  } = useUtilityData(property?.data.postal_code)

  if (errorLoadingProperty) return <>Error: {errorLoadingProperty}</>
  if (errorLoadingElectricUtilities)
    return <>Error: {errorLoadingElectricUtilities}</>
  if (errorLoadingUtilityData) return <>Error: {errorLoadingUtilityData}</>

  if (isLoadingProperty || isLoadingElectricUtilities || isLoadingUtilityData)
    return <>Loading...</>

  const onSubmit = async (values: PropertyFormValues) => {
    if (property.id) {
      try {
        await updateProperty(property.id, values)

        setSuccessToast()
      } catch (e) {
        setErrorToast()

        throw e
      }
    }
  }

  return (
    <UtilityUsageForm
      initialValues={property.toFormValues()}
      electricUtilities={electricUtilities}
      utilityData={utilityData}
      onSubmit={onSubmit}
      autoSave
    />
  )
}
