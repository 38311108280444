import React from 'react'
import { useFormikContext } from 'formik'

import { Button, ButtonProps } from '../../Button'

export const SubmitButton: React.FC<ButtonProps> = (props) => {
  const formState = useFormikContext()

  if (!formState)
    throw new Error('SubmitButton must be a descendant of Formik Context')

  const { isSubmitting, isValid, isValidating } = formState

  const disabled = props.disabled || !isValid || isValidating || isSubmitting

  return <Button {...props} type="submit" disabled={disabled} />
}
