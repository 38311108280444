import React from 'react'

import './InsulationExplorePageNeeeco.scss'

export const InsulationExplorePageNeeeco: React.FC = () => {
  return (
    <div className="insulation-explore-neeeco">
      <iframe
        src="https://clients.vcita.com/portal/s3qsgdpb37cfgkto#/schedule?&amp;s=https%3A%2F%2Flive.vcita.com%2Fsite%2Fneeeco%2Fonline-scheduling&amp;o=ZGlyZWN0&amp;service_id=ncurviot8b0oejr4&amp;isWidget=false"
        width="100%"
        height="710"
        scrolling="no"
        allowFullScreen
        data-testid="Neeco Scheduling Widget"
      ></iframe>
    </div>
  )
}
