import React from 'react'
import { Link, useSearchParams } from 'react-router-dom'

import { ROUTES } from '../../routes'
import { useActionPlan, useProperty } from '../../api'
import { useDisplaySettings } from '../../context'
import {
  AddressStreetViewImage,
  LeafIcon,
  FactoryIcon,
  HerePiggyPiggyIcon,
  DollarIcon,
  ProjectTileLink,
  MaxWidth,
  ActionPlanControlForm,
} from '../../components'

import './ActionPlanPage.scss'
import {
  GRIDLY_INSULATION_PRODUCT_NAMES,
  GRIDLY_PROJECT_SLUGS,
} from '../../constants'
import { PRODUCT_SCHEDULE_LINKS } from '../projects/InsulationPage/constants'

export const ActionPlanPage: React.VFC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { showFinanced } = useDisplaySettings()

  const {
    actionPlan,
    isLoading: isLoadingActionPlan,
    error: errorLoadingActionPlan,
  } = useActionPlan()
  const {
    property,
    isLoading: isLoadingProperty,
    error: errorLoadingProperty,
  } = useProperty()

  if (errorLoadingActionPlan || errorLoadingProperty)
    return (
      <>
        Error:{' '}
        {errorLoadingActionPlan?.message || errorLoadingProperty?.message}
      </>
    )
  if (isLoadingActionPlan || isLoadingProperty) return <>Loading...</>

  const sortBy = searchParams.get('sortBy') ?? undefined
  const handleViewControlSubmit = (
    controlValues: React.ComponentProps<
      typeof ActionPlanControlForm
    >['initialValues']
  ) => setSearchParams(controlValues)
  const insulationProject = actionPlan.getProjectBySlug(
    GRIDLY_PROJECT_SLUGS.INSULATION
  )
  const scheduleLink =
    PRODUCT_SCHEDULE_LINKS[
      insulationProject.product_recommendation as GRIDLY_INSULATION_PRODUCT_NAMES
    ]

  return (
    <div className="action-plan-page">
      <MaxWidth backgroundColor="blue" wide>
        <div className="action-plan-page__page-header">
          <span className="action-plan-page__street-view-image">
            <AddressStreetViewImage propertyData={property.data} fov="90" />
          </span>
          <h2 className="action-plan-page__title">Action Plan</h2>

          <div className="action-plan-page__page-header-property-info">
            <span className="action-plan-page__page-header-address-text">
              {property.formattedSingleLineAddress}
            </span>

            <div className="action-plan-page__page-header-property-details">
              <span>{property.formattedYearBuilt}</span>
              <span className="action-plan-page__page-header-property-detail-divider">
                |
              </span>
              <span>{property.formattedSqft}</span>
              <Link to={ROUTES.myHome}>Update</Link>
            </div>
          </div>

          <div className="action-plan-page__page-header-description">
            <p>
              <strong>
                Your Plan contains a detailed roadmap for clean energy
                transition,
              </strong>{' '}
              specifically aligned to your unique home profile. Gridly organizes
              your plan into individual &apos;projects&apos; so you can easily
              identify and select each step of the journey.
            </p>

            {!!scheduleLink && <div>{scheduleLink}</div>}
          </div>
        </div>
      </MaxWidth>

      <MaxWidth backgroundColor="green" wide>
        <div className="action-plan-page__property-goal-bar">
          <div className="action-plan-page__property-goal-bar-section">
            <div className="action-plan-page__property-goal-bar-section-label">
              Estimated Impact*
            </div>

            <div className="action-plan-page__property-goal-bar-section-content-impacts">
              <div className="action-plan-page__property-goal-bar-impact">
                <div className="action-plan-page__property-goal-bar-impact-image">
                  <HerePiggyPiggyIcon />
                </div>

                <div className="action-plan-page__property-goal-bar-impact-highlight">
                  {actionPlan.formattedTotalAnnualSavings}
                </div>

                <div className="action-plan-page__property-goal-bar-impact-label">
                  {' '}
                  Utility Savings Per Year
                </div>
              </div>

              <div className="action-plan-page__property-goal-bar-impact">
                <div className="action-plan-page__property-goal-bar-impact-image">
                  <FactoryIcon />
                </div>

                <div className="action-plan-page__property-goal-bar-impact-highlight">
                  Best
                </div>

                <div className="action-plan-page__property-goal-bar-impact-label">
                  {' '}
                  Carbon Reduction
                </div>
              </div>

              <div className="action-plan-page__property-goal-bar-impact">
                <div className="action-plan-page__property-goal-bar-impact-image">
                  <LeafIcon />
                </div>

                <div className="action-plan-page__property-goal-bar-impact-highlight">
                  100%
                </div>

                <div className="action-plan-page__property-goal-bar-impact-label">
                  {' '}
                  Renewable Energy
                </div>
              </div>

              <div className="action-plan-page__property-goal-bar-impact">
                <div className="action-plan-page__property-goal-bar-impact-image">
                  <DollarIcon />
                </div>

                <div className="action-plan-page__property-goal-bar-impact-highlight">
                  {showFinanced
                    ? actionPlan.formattedTotalFinancedMonthlyCost
                    : actionPlan.formattedTotalNetCost}
                </div>

                <div className="action-plan-page__property-goal-bar-impact-label">
                  {' '}
                  Total Cost After Rebates
                </div>
              </div>
            </div>
          </div>
        </div>
      </MaxWidth>

      <MaxWidth wide>
        <div className="action-plan-page__page-content">
          <div className="action-plan-page__page-content-header">
            <h3>Your Projects</h3>
            <ActionPlanControlForm
              initialValues={{ sortBy }}
              onSubmit={handleViewControlSubmit}
            />
          </div>

          <ul className="action-plan-page__project-list">
            {actionPlan
              .projectsSortedBy(sortBy)
              .map(
                (project) =>
                  ROUTES.projects[project.slug] && (
                    <ProjectTileLink key={project.slug} project={project} />
                  )
              )}
          </ul>

          <p className="action-plan-page__disclaimer">
            * Projected costs are estimates only and not quotes nor an offer for
            work to be done at that price. System recommendations and specific
            pricing should be finalized by the provider of your choice prior to
            installation. Savings amounts and energy usage & needs are estimates
            derived from the information available about the residence and
            information you may have added or edited. Available rebates,
            incentives and credits are estimated from publicly available
            information about each program and the likely eligibility of your
            home. You should verify this information to be sure you are
            qualified and eligible.
          </p>
        </div>
      </MaxWidth>
    </div>
  )
}
