import React from 'react'
import classnames from 'classnames'
import { useFormikContext } from 'formik'
import Select, {
  components,
  ControlProps,
  DropdownIndicatorProps,
  Props as ReactSelectProps,
} from 'react-select'

import { ChevronIcon } from '../../media'

import './SelectInput.scss'

type Option = { label: string; value: string | boolean }

export interface Props extends ReactSelectProps {
  label: string
  options: Option[]
  name: string
  disabled?: boolean
  onChangeEffect?: (value: Option) => void | undefined
}

const Control: React.FC<ControlProps> = ({ children, ...props }) => (
  <components.Control {...props}>
    <label
      className={classnames('select-input__label', {
        'select-input__label--disabled': props.isDisabled,
      })}
    >
      <span className="select-input__label-text">
        {props.selectProps.label}
      </span>
      {children}
    </label>
  </components.Control>
)

const DropdownIndicator: React.FC<DropdownIndicatorProps> = ({
  selectProps,
}) => {
  const { menuIsOpen } = selectProps

  return <ChevronIcon pointUp={menuIsOpen} />
}

export const SelectInput: React.FC<Props> = ({
  disabled,
  onChangeEffect,
  options,
  name,
  value,
  ...props
}) => {
  const currentValue = options.find((o) => o.value === value)
  const { setFieldValue, setFieldTouched, setStatus } = useFormikContext()
  const handleChange = (newValue: unknown) => {
    const selected = newValue as Option
    setFieldTouched(name)
    setStatus({ dirty: value !== selected.value })
    setFieldValue(name, selected.value, true)

    onChangeEffect?.(selected)
  }

  return (
    <Select
      blurInputOnSelect={false}
      isSearchable={false}
      defaultValue={value}
      {...props}
      value={
        currentValue || props.defaultValue || null /* needed for form reset */
      }
      options={options}
      name={name}
      className={classnames('select-input', props.className)}
      classNamePrefix="react-select"
      components={{
        Control,
        DropdownIndicator,
        IndicatorSeparator: null,
      }}
      onChange={handleChange}
      isDisabled={disabled}
      openMenuOnClick
      placeholder="-- Select --"
    />
  )
}
