import React from 'react'
import { Field, Form, Formik, FormikHelpers } from 'formik'

import { PropertyFormValues } from '../../../types'
import { yesNoSelectOptions } from '../constants'
import { AutoSave, SelectInput } from '../../formElements'

import './RenewableEnergyForm.scss'

interface Props {
  initialValues: PropertyFormValues
  onSubmit: (values: PropertyFormValues) => Promise<void>
}

export const RenewableEnergyForm: React.VFC<Props> = ({
  initialValues,
  onSubmit,
}) => {
  const handleSubmit = async (
    values: PropertyFormValues,
    helpers: FormikHelpers<PropertyFormValues>
  ) => {
    try {
      await onSubmit(values)
    } catch (e) {
      helpers.resetForm()
    }
  }

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Form className="renewable-energy-form" role="form">
        <Field
          as={SelectInput}
          name="wattbuy_customer"
          label="Are you a Wattbuy customer?"
          options={yesNoSelectOptions}
        />

        <AutoSave />
      </Form>
    </Formik>
  )
}
