import React from 'react'

import ComingSoonMapSVG from './coming-soon-map.svg'

export const ComingSoonMap: React.FC<React.ComponentPropsWithoutRef<'img'>> = (
  props
) => {
  return (
    <img
      alt="map of New England with Massachusetts highlighted as an active service area"
      {...props}
      src={ComingSoonMapSVG}
    />
  )
}
