import axios from 'axios'
import { Property } from '../../types'
import { routes } from '../constants'

type UpdateProperty = (id: number, propertyData: Property, recalculate_expenditures?: boolean) => Promise<Property>

export const updateProperty: UpdateProperty = async (
  id: number,
  propertyData,
  recalculate_expenditures = false
) => {
  const resp = await axios.patch<Property>(routes.property(id), {recalculate_expenditures, ...propertyData})
  return resp.data
}
