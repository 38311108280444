import React from 'react'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { PropertyFormValues } from '../../../types'
import {
  Fieldset,
  NumberInput,
  SelectInput,
  SubmitButton,
} from '../../formElements'
import { yesNoSelectOptions } from '../constants'
import {
  coolingDistributionOptions,
  heatingDeviceTypeOptions,
} from '../HomeDetailsForm/constants'
import { insulationLevelOptions } from '../InsulationForm/constants'
import { ceilingHeightOptions } from './constants'

import './HvacForm.scss'

interface Props {
  initialValues: PropertyFormValues
  onSubmit: (values: PropertyFormValues) => Promise<void>
}

export const HvacForm: React.VFC<Props> = ({ initialValues, onSubmit }) => {
  const handleSubmit = async (
    values: PropertyFormValues,
    helpers: FormikHelpers<PropertyFormValues>
  ) => {
    try {
      await onSubmit(values)
    } catch (e) {
      helpers.resetForm()
    }
  }

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Form className="hvac-form" role="form">
        <div className="hvac-form__fieldset-container">
          <Fieldset legend="GENERAL">
            <Field
              as={SelectInput}
              name="heating_method"
              label="Current Heating System"
              options={heatingDeviceTypeOptions}
            />

            <Field as={NumberInput} name="num_rooms" label="Total Room Count" />

            <Field
              as={SelectInput}
              name="cooling_distribution"
              label="Current Cooling System"
              options={coolingDistributionOptions}
            />

            <Field
              as={SelectInput}
              name="wall_insulation_level"
              label="Quality of Insulation"
              options={insulationLevelOptions}
            />
          </Fieldset>

          <Fieldset legend="ROOMS &amp; FLOOR PLAN">
            <Field
              as={NumberInput}
              name="total_sqft"
              label="Total Square Feet"
            />

            <Field
              as={SelectInput}
              name="ceiling_height"
              label="Ceiling Height"
              options={ceilingHeightOptions}
            />

            <Field
              as={SelectInput}
              name="ducting"
              label="Existing Ductwork"
              options={yesNoSelectOptions}
            />

            <Field
              as={NumberInput}
              name="num_thermostats"
              label="Zones (How many thermostats?)"
            />
          </Fieldset>
        </div>

        <SubmitButton className="hvac-form__submit-button" variant="wire" small>
          Refine Your Recommendation
        </SubmitButton>
      </Form>
    </Formik>
  )
}
