import { object, string } from 'yup'
import { PropertyDataModel } from '../../../models'

export const buildSchema = (property: PropertyDataModel) =>
  object({
    electric_utility_external_id: string().required(
      'Please select an electricity vendor'
    ),
    gas_utility_vendor: property.hasNaturalGas
      ? string().required('Please select a natural gas vendor')
      : string(),
    electric_utility_annual_expenditure: string().required(),
    electric_utility_annual_usage: string().required(),
    oil_utility_annual_expenditure: string().required(),
    oil_utility_annual_usage: string().required(),
    gas_utility_annual_expenditure: string().required(),
    gas_utility_annual_usage: string().required(),
    propane_utility_annual_expenditure: string().required(),
    propane_utility_annual_usage: string().required(),
  })
