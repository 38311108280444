import React from 'react'
import classnames from 'classnames'

import { Markdown } from '../Markdown'

import './LabeledList.scss'

const OrderedList: React.FC<React.HTMLProps<HTMLOListElement>> = ({
  children,
  className,
}) => <ol className={className}>{children}</ol>

const UnorderedList: React.FC<React.HTMLProps<HTMLUListElement>> = ({
  children,
  className,
}) => <ul className={className}>{children}</ul>

type Props = {
  label: string
  noMarkers?: boolean
  ordered?: boolean
} & React.HTMLProps<HTMLUListElement | HTMLOListElement>

export const LabeledList: React.FC<Props> = ({
  id,
  children,
  className,
  label,
  noMarkers,
  ordered,
}) => {
  const List = ordered ? OrderedList : UnorderedList

  return (
    <section id={id} className={classnames('labeled-list', className)}>
      <h6 className="labeled-list__label">
        <Markdown>{label}</Markdown>
      </h6>

      <List
        className={classnames('labeled-list__list', {
          'labeled-list__list--ordered': ordered,
          'labeled-list__list--no-markers': noMarkers,
        })}
      >
        {children}
      </List>
    </section>
  )
}
