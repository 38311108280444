import useSWR from 'swr'
import { GRIDLY_PROJECT_SLUGS } from '../../constants'

import { Project } from '../../types'
import { routes } from '../constants'

type ProjectApiLoading = {
  project: undefined
  isLoading: true
  error: undefined
}

type ProjectApiError = {
  project: undefined
  isLoading: false
  error: Error
}

type ProjectApiResolved = {
  project: Project
  isLoading: false
  error: undefined
}

type ProjectApi = ProjectApiLoading | ProjectApiError | ProjectApiResolved

export const useProject = (slug: GRIDLY_PROJECT_SLUGS): ProjectApi => {
  const { data, error } = useSWR<Project>(routes.projects(slug))

  if (data) {
    return {
      project: data,
      isLoading: false,
      error: undefined,
    }
  } else if (error) {
    return {
      project: undefined,
      isLoading: false,
      error: error,
    }
  } else {
    return {
      project: undefined,
      isLoading: true,
      error: undefined,
    }
  }
}
