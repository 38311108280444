import React from 'react'
import classnames from 'classnames'
import { Link, LinkProps } from 'react-router-dom'

import { Tile } from '../Tile'

import './TileLink.scss'

const disabledLinkProps: LinkProps = {
  ['aria-disabled']: true,
  to: '#',
  tabIndex: -1,
}

interface Props extends LinkProps {
  ['aria-label']: string
  disabled?: boolean
  allowDisabledStyles?: boolean
}

export const TileLink: React.FC<Props> = ({
  children,
  className,
  disabled,
  allowDisabledStyles = true,
  ...props
}) => (
  <Link
    className="tile-link__link-actual"
    {...props}
    {...(disabled && disabledLinkProps)}
  >
    <Tile
      className={classnames(className, 'tile-link', {
        'tile-link--disabled': disabled && allowDisabledStyles,
      })}
    >
      {children}
    </Tile>
  </Link>
)
