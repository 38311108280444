import React from 'react'
import { useNavigate } from 'react-router-dom'

import { PropertyFormValues } from '../../types'
import { PropertyDataModel } from '../../models'
import { ROUTES } from '../../routes'
import { usePropertyData, useToast } from '../../context'
import {
  getUpdatedPropertyEstimates,
  useElectricUtilities,
  useUtilityData,
} from '../../api'
import { MaxWidth, UtilityUsageForm, HotStepper } from '../../components'

import './UtilityUsagePage.scss'

export const UtilityUsagePage: React.VFC = () => {
  const { setErrorToast } = useToast()
  const navigate = useNavigate()
  const { propertyData, setPropertyData } = usePropertyData()
  const {
    isLoading: isLoadingUtilityData,
    error: errorLoadingUtilityData,
    utilityData,
  } = useUtilityData(propertyData.postal_code)
  const {
    isLoading: isLoadingElectricUtilities,
    error: errorLoadingElectricUtilities,
    electricUtilities,
  } = useElectricUtilities(propertyData.postal_code)

  const propertyDataModel = new PropertyDataModel(propertyData)

  const onSubmit = async (params: PropertyFormValues) => {
    try {
      const property = await getUpdatedPropertyEstimates(params)
      setPropertyData(property)
      navigate(ROUTES.requestActionPlan)
    } catch (e) {
      setErrorToast()

      throw e
    }
  }

  return (
    <MaxWidth>
      <div className="utility-usage-page__page-heading-wrapper">
        <div className="utility-usage-page__hot-stepper">
          <HotStepper currentStep={2} />
        </div>

        <p className="utility-usage-page__address-line">
          {propertyDataModel.formattedSingleLineAddress}
        </p>

        <h2 className="utility-usage-page__page-heading">
          Let&apos;s get some insight into your utility bills.
        </h2>

        <p className="utility-usage-page__sub-heading">
          It&apos;s okay if it&apos;s not exact, but let&apos;s try to get as
          close as possible to ensure your best energy transition options.
        </p>
      </div>

      {errorLoadingUtilityData || errorLoadingElectricUtilities ? (
        <>Error Loading Utility Data</>
      ) : isLoadingUtilityData || isLoadingElectricUtilities ? (
        <>Loading Utility Data</>
      ) : (
        <UtilityUsageForm
          initialValues={propertyDataModel.toFormValues()}
          onSubmit={onSubmit}
          electricUtilities={electricUtilities}
          utilityData={utilityData}
        />
      )}
    </MaxWidth>
  )
}
