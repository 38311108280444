import React from 'react'
import { NextStepsItem, NextStepsList } from '../../../../../components'
import { GRIDLY_PROJECT_SLUGS } from '../../../../../constants'

export const ConvertWaterHeaterExplorePageV1: React.FC = () => {
  return (
    <NextStepsList
      projectSlug={GRIDLY_PROJECT_SLUGS.CONVERT_WATER_HEATER}
      imgAltText="Picture of a heat pump hot water heater"
      title="Convert to a super-efficient Heat Pump Hot Water Heater"
    >
      <NextStepsItem
        step="Check for adequate space"
        explainer="A Heat Pump HWH generally needs 7 ft of clearance and 700 cubic ft of available air."
      />

      <NextStepsItem
        step="Connect with a service pro"
        explainer="You'll want a licensed plumber and electrician to complete the install. Usually just a one-day job.  If you prefer, the pro can assess and advise about required space and product recommendations."
      />
    </NextStepsList>
  )
}
