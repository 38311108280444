import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import {
  ContentLayout,
  ContentPageHeader,
  HomeDetailsHeader,
  LandingPageHeader,
  Layout,
  LayoutWithPropertyDataGuard,
  MemberExperienceHeader,
} from '../components'
import {
  NotFoundPage,
  ActionPlanPage,
  ActionPlanRequestConfirmationPage,
  ComingSoonPage,
  CommunitySolarPage,
  CompareCleanEnergyPage,
  ConvertWaterHeaterPage,
  EditPropertyTabNavigator,
  EditPropertyBasicsPage,
  EditPropertyUtilitiesPage,
  HomeDetailsPage,
  LandingPage,
  MyAccountPage,
  RenewableEnergyPage,
  RequestActionPlanPage,
  SignInPage,
  SmartThermostatPage,
  UtilityUsagePage,
  InsulationPage,
  SolarPanelsPage,
  HomeMonitoringPage,
  HVACPage,
  RenewableEnergyExplorePage,
  PrivacyPolicyPage,
  SmartThermostatExplorePage,
  TermsAndConditionsPage,
  ConvertWaterHeaterExplorePage,
  SolarPanelsExplorePage,
  InsulationExplorePage,
  RequestForProposalPage,
} from '../pages'
import { ROUTES } from './constants'
import { GRIDLY_PROJECT_SLUGS } from '../constants'
import { HVACExplorePage } from '../pages/projects/explore/HVACExplorePage'
import { RequestForProposalConfirmationPage } from '../pages/RequestForProposalConfirmationPage'
import { CommunitySolarExplorePage } from '../pages/projects/explore/CommunitySolarExplorePage'

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={ROUTES.root}
          element={
            <Layout
              linkFooterLogoToHomePage
              Header={LandingPageHeader}
              background="house--dark"
            />
          }
        >
          <Route index element={<LandingPage />} />
        </Route>

        <Route
          path={ROUTES.signIn}
          element={<Layout background="house--dark" width="wide" />}
        >
          <Route index element={<SignInPage />} />
        </Route>

        <Route
          path={ROUTES.comingSoon}
          element={<Layout background="house--dark" width="wide" />}
        >
          <Route index element={<ComingSoonPage />} />
        </Route>

        <Route
          path={ROUTES.terms}
          element={<ContentLayout Header={ContentPageHeader} />}
        >
          <Route index element={<TermsAndConditionsPage />} />
          <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicyPage />} />
        </Route>

        <Route
          path={ROUTES.homeDetails}
          element={
            <LayoutWithPropertyDataGuard
              Header={HomeDetailsHeader}
              background="house--light"
            />
          }
        >
          <Route index element={<HomeDetailsPage />} />

          <Route path={ROUTES.utilityUsage} element={<UtilityUsagePage />} />

          <Route
            path={ROUTES.requestActionPlan}
            element={<RequestActionPlanPage />}
          />
        </Route>

        <Route
          path={ROUTES.actionPlanRequestConfirmation}
          element={<Layout background="house--dark" width="wide" />}
        >
          <Route index element={<ActionPlanRequestConfirmationPage />} />
        </Route>

        <Route
          path={ROUTES.actionPlan}
          element={
            <Layout Header={MemberExperienceHeader} width="full-width" />
          }
        >
          <Route index element={<ActionPlanPage />} />
        </Route>

        <Route
          path={ROUTES.projects.index}
          element={
            <Layout Header={MemberExperienceHeader} width="full-width" />
          }
        >
          <Route index element={<Navigate to={ROUTES.actionPlan} replace />} />
          <Route
            path={ROUTES.projects[GRIDLY_PROJECT_SLUGS.COMMUNITY_SOLAR]}
            element={<CommunitySolarPage />}
          />

          <Route
            path={ROUTES.projects[GRIDLY_PROJECT_SLUGS.SMART_THERMOSTAT]}
            element={<SmartThermostatPage />}
          />

          <Route
            path={
              ROUTES.projectExplorePages[GRIDLY_PROJECT_SLUGS.SMART_THERMOSTAT]
            }
            element={<SmartThermostatExplorePage />}
          />

          <Route
            path={ROUTES.projects[GRIDLY_PROJECT_SLUGS.CONVERT_WATER_HEATER]}
            element={<ConvertWaterHeaterPage />}
          />

          <Route
            path={
              ROUTES.projectExplorePages[
                GRIDLY_PROJECT_SLUGS.CONVERT_WATER_HEATER
              ]
            }
            element={<ConvertWaterHeaterExplorePage />}
          />

          <Route
            path={
              ROUTES.projectExplorePages[GRIDLY_PROJECT_SLUGS.COMMUNITY_SOLAR]
            }
            element={<CommunitySolarExplorePage />}
          />

          <Route
            path={ROUTES.projects[GRIDLY_PROJECT_SLUGS.RENEWABLE_ENERGY]}
            element={<RenewableEnergyPage />}
          />

          <Route
            path={
              ROUTES.projectExplorePages[GRIDLY_PROJECT_SLUGS.RENEWABLE_ENERGY]
            }
            element={<RenewableEnergyExplorePage />}
          />

          <Route
            path={ROUTES.projects[GRIDLY_PROJECT_SLUGS.INSULATION]}
            element={<InsulationPage />}
          />

          <Route
            path={ROUTES.projectExplorePages[GRIDLY_PROJECT_SLUGS.INSULATION]}
            element={<InsulationExplorePage />}
          />

          <Route
            path={ROUTES.projects[GRIDLY_PROJECT_SLUGS.SOLAR_PANELS]}
            element={<SolarPanelsPage />}
          />

          <Route
            path={ROUTES.projectExplorePages[GRIDLY_PROJECT_SLUGS.SOLAR_PANELS]}
            element={<SolarPanelsExplorePage />}
          />

          <Route
            path={ROUTES.projects[GRIDLY_PROJECT_SLUGS.WHOLE_HOME_MONITORING]}
            element={<HomeMonitoringPage />}
          />

          <Route
            path={ROUTES.projects[GRIDLY_PROJECT_SLUGS.HVAC]}
            element={<HVACPage />}
          />

          <Route
            path={ROUTES.projectExplorePages[GRIDLY_PROJECT_SLUGS.HVAC]}
            element={<HVACExplorePage />}
          />
        </Route>

        <Route
          path={ROUTES.requestForProposal()}
          element={
            <Layout Header={MemberExperienceHeader} width="full-width" />
          }
        >
          <Route index element={<RequestForProposalPage />} />
        </Route>

        <Route
          path={ROUTES.compareCleanEnergy}
          element={
            <Layout Header={MemberExperienceHeader} width="full-width" />
          }
        >
          <Route index element={<CompareCleanEnergyPage />} />
        </Route>

        <Route
          path={ROUTES.requestForProposalConfirmation()}
          element={
            <Layout
              Header={MemberExperienceHeader}
              background="house--graphic"
            />
          }
        >
          <Route index element={<RequestForProposalConfirmationPage />} />
        </Route>

        <Route
          path={ROUTES.myHome}
          element={<Layout Header={MemberExperienceHeader} />}
        >
          <Route element={<EditPropertyTabNavigator />}>
            <Route index element={<EditPropertyBasicsPage />} />

            <Route
              path={ROUTES.myHomeUtilities}
              element={<EditPropertyUtilitiesPage />}
            />
          </Route>
        </Route>

        <Route
          path={ROUTES.myAccount}
          element={<Layout Header={MemberExperienceHeader} />}
        >
          <Route index element={<MyAccountPage />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  )
}
