import React from 'react'
import { Formik, Form, Field, FormikHelpers } from 'formik'

import { TextInput, SubmitButton, Checkbox } from '../../formElements'
import { Tile } from '../../Tile'
import { requestActionPlanSchema } from './schema'
import './RequestActionPlanForm.scss'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../routes/constants'

export interface RequestActionPlanFormValues {
  email: string
  subscribe_to_email_updates: boolean
}

interface Props {
  onSubmit: (data: RequestActionPlanFormValues) => Promise<void>
}

export const RequestActionPlanForm: React.VFC<Props> = ({ onSubmit }) => {
  const handleSubmit = async (
    values: RequestActionPlanFormValues,
    { resetForm }: FormikHelpers<RequestActionPlanFormValues>
  ) => {
    try {
      await onSubmit(values)
    } catch (e) {
      resetForm()
    }
  }

  return (
    <Formik
      initialValues={{ email: '', subscribe_to_email_updates: true }}
      onSubmit={handleSubmit}
      validateOnMount
      validationSchema={requestActionPlanSchema}
    >
      <Form className="request-action-plan-form" role="form">
        <div className="request-action-plan-form__directions">
          <h6 className="request-action-plan-form__directions-header">
            Enter Your Email
          </h6>

          <span className="request-action-plan-form__directions-subheader">
            We&apos;ll send you access to your Gridly Action Plan.
          </span>
        </div>

        <Field
          as={TextInput}
          className="request-action-plan-form__email-field"
          label="Email Address"
          name="email"
        />

        <p className="request-action-plan-form__privacy-statement">
          We take privacy seriously. Don&apos;t worry as we will not share your
          personal or home information with any third parties without your
          consent. See our <Link to={ROUTES.privacyPolicy}>Privacy Policy</Link>{' '}
          for more details.
        </p>

        <Field
          as={Checkbox}
          className="request-action-plan-form__email-field"
          label="Email Updates. We occasionally send out product updates, new services, and Gridly news."
          name="subscribe_to_email_updates"
        />

        <Tile className="request-action-plan-form__benefits-tile">
          <h6 className="request-action-plan-form__benefits-tile-title">
            Your Action Plan Includes
          </h6>

          <ul>
            <li>
              Details on how to{' '}
              <span className="request-action-plan-form__highlighted-text">
                RePower
              </span>{' '}
              your home,{' '}
              <span className="request-action-plan-form__highlighted-text">
                Convert
              </span>{' '}
              your systems, and{' '}
              <span className="request-action-plan-form__highlighted-text">
                Solidify
              </span>{' '}
              your home&apos;s energy envelope
            </li>

            <li>
              A curated group of projects designed to get your home to{' '}
              <span className="request-action-plan-form__highlighted-text">
                100% renewable energy
              </span>
            </li>

            <li>
              Ways to leverage{' '}
              <span className="request-action-plan-form__highlighted-text">
                up to $30,000
              </span>{' '}
              in state and federal incentives and rebates
            </li>
          </ul>
        </Tile>

        <SubmitButton className="request-action-plan-form__submit-button">
          Get Your Action Plan
        </SubmitButton>
      </Form>
    </Formik>
  )
}
