import CommunitySolarImage from './CommunitySolar.png'
import ConvertWaterHeaterImage from './ConvertWaterHeater.png'
import HVACImage from './HVAC.png'
import InsulationImage from './Insulation.png'
import RenewableEnergyImage from './RenewableEnergy.png'
import SolarPanelsImage from './SolarPanels.png'
import SmartThermostatImage from './SmartThermostat.png'
import WholeHomeMonitoringImage from './WholeHomeMonitoring.png'
import { GRIDLY_PROJECT_SLUGS } from '../../../constants'

export const ProjectImageByProjectSlug: Record<GRIDLY_PROJECT_SLUGS, string> = {
  [GRIDLY_PROJECT_SLUGS.COMMUNITY_SOLAR]: CommunitySolarImage,
  [GRIDLY_PROJECT_SLUGS.CONVERT_WATER_HEATER]: ConvertWaterHeaterImage,
  [GRIDLY_PROJECT_SLUGS.HVAC]: HVACImage,
  [GRIDLY_PROJECT_SLUGS.INSULATION]: InsulationImage,
  [GRIDLY_PROJECT_SLUGS.RENEWABLE_ENERGY]: RenewableEnergyImage,
  [GRIDLY_PROJECT_SLUGS.SOLAR_PANELS]: SolarPanelsImage,
  [GRIDLY_PROJECT_SLUGS.SMART_THERMOSTAT]: SmartThermostatImage,
  [GRIDLY_PROJECT_SLUGS.WHOLE_HOME_MONITORING]: WholeHomeMonitoringImage,
}
