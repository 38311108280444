import axios from 'axios'
import { Address, Property } from '../../types'
import { routes } from '../constants'

type GetProperty = (params: Address) => Promise<Property>

export const getPropertyData: GetProperty = async (address) => {
  let property: keyof Address
  const addressParams: Record<string, string> = {}

  for (property in address) {
    addressParams[`address[${property}]`] = address[property]
  }

  const resp = await axios.get<Property>(routes.newProperty, {
    params: addressParams,
  })

  return resp.data
}
