export const API_KEY = window.env?.REACT_APP_GOOGLE_API_KEY

/* PLACES_API_CONFIG:
 *
 * "types" - "address" scopes results to 'precise addresses' (vs. regions, businesses, etc.)
 *
 * "componentRestrictions" - "country: us" scopes results to US; unfortunately 'state' is not supported :(
 *
 * "fields" - API usage is billed per field, this is the minimum for our use.
 *    - "address_components" the data we need to send to our BE (and from there to Attom)
 *    - "geometry" allows us to ensure field value is a valid address on "place_changed"
 */
export const PLACES_API_CONFIG = {
  types: ['address'],
  componentRestrictions: {
    country: 'us',
  },
  fields: ['address_components', 'geometry'],
}
