import React from 'react'

import { GRIDLY_PROJECT_SLUGS } from '../../../../../constants'
import { ROUTES } from '../../../../../routes'
import { useProject } from '../../../../../api'
import {
  VendorInfoTile,
  VendorTileLink,
  VendorListLayout,
} from '../../../../../components'
import { ProjectModel } from '../../../../../models/ProjectModel'

export const HVACExplorePageV2: React.FC = () => {
  const { error, isLoading, project } = useProject(GRIDLY_PROJECT_SLUGS.HVAC)

  if (error) {
    return <>Error {error.message}</>
  }

  if (isLoading) {
    return <>Loading...</>
  }

  return (
    <VendorListLayout
      heading="Choose the Heat Pump installation service that works best for you"
      subheading="Gridly has partnered with these installers to provide superior quality Heat Pump installations in your area."
      projectSlug={project.slug}
      InfoTile={() => (
        <VendorInfoTile
          Title={() => (
            <h4>
              <span className="green-text">
                Get a proposal & quote <br aria-hidden />
                from an experienced <br aria-hidden />
              </span>
              Heat Pump installer
            </h4>
          )}
        >
          <p className="body-2 body-2--bold">What you should know</p>

          <p className="body-2">
            Start to think about your preferred location for the heat
            pump&apos;s outdoor unit. You will want to find a location that
            provides plenty of clearance for air circulation while minimizing
            the distance between either the ductless room units or the air
            handler for a central system
          </p>

          <p className="body-2  body-2--bold">
            Expect the installer to confirm the following information
          </p>

          <ol className="body-2">
            <li>The dimensions of each room to be heated and cooled</li>
            <li>The quality of your current insulation, windows and doors</li>
            <li>Your typical thermostat settings</li>
          </ol>
        </VendorInfoTile>
      )}
    >
      {ProjectModel.activeSolutions(project).map((solution) => (
        <VendorTileLink
          key={solution.id}
          solution={solution}
          to={ROUTES.requestForProposal(`${solution.id}`)}
          disabled={Boolean(
            project.request_for_proposals.find(
              ({ solution_id }) => solution_id === solution.id
            )
          )}
        />
      ))}
    </VendorListLayout>
  )
}
