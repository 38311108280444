import React from 'react'
import ReactMarkdown, { Options } from 'react-markdown'

import { MarkdownLink } from './MarkdownLink'

// Please fix this prop type when the below issue is resolved
// https://github.com/remarkjs/react-markdown/issues/711
type TemporaryAndWrongProps = {
  children: React.ReactNode & React.ReactNode[]
  href?: string
}
const defaultProps: Partial<Options> = {
  components: {
    a: MarkdownLink as React.FC<TemporaryAndWrongProps>,
  },
}

export const Markdown: React.FC<Options> = (props) => (
  <ReactMarkdown {...defaultProps} {...props} />
)
