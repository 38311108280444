import React from 'react'

import { withInputWrapper } from '../../../hoc/withInputWrapper'
import './TextInput.scss'

interface Props extends React.ComponentPropsWithRef<'input'> {
  label: string
  name: string
}

const BaseTextInput: React.FC<Props> = React.forwardRef(
  ({ label, placeholder, ...props }, ref) => {
    return (
      <input
        {...props}
        className="text-input__input"
        placeholder={placeholder || label}
        ref={ref}
      />
    )
  }
)

BaseTextInput.displayName = 'BaseTextInput'

const TextInput = withInputWrapper(BaseTextInput, 'text')

TextInput.displayName = 'TextInput'

export { TextInput }
