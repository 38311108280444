import React from 'react'
import {
  AccordionItemProps,
  useAccordionItem,
  useAccordionItemEffect,
  useHeightTransition,
} from '@szhsin/react-accordion'
import classNames from 'classnames'

import { Markdown } from '../../Markdown'
import { MinusIcon, PlusIcon } from '../../media'

import './Item.scss'

interface Props extends React.PropsWithChildren<AccordionItemProps> {
  header: string
  subheader?: string
}

export const Item: React.FC<Props> = ({
  children,
  header,
  subheader,
  itemKey,
  initialEntered,
  disabled,
}) => {
  const { itemRef, state, toggle } = useAccordionItemEffect<HTMLDivElement>({
    itemKey,
    initialEntered,
    disabled,
  })
  const { buttonProps, panelProps } = useAccordionItem({
    state,
    toggle,
    disabled,
  })
  const [transitionStyle, panelRef] = useHeightTransition<HTMLDivElement>(state)

  const { status, isMounted, isEnter: isExpanded } = state
  const PlusOrMinusIcon = isExpanded ? MinusIcon : PlusIcon

  return (
    <div className="accordion-item" ref={itemRef}>
      <button
        {...buttonProps}
        className={classNames('accordion-item__header', {
          'accordion-item__header--expanded': isExpanded,
        })}
      >
        <PlusOrMinusIcon className="accordion-item__header-icon" />

        <h6 className="accordion-item__header-header">{header}</h6>

        {subheader && (
          <Markdown className="accordion-item__header-subheader">
            {subheader}
          </Markdown>
        )}
      </button>

      {isMounted && (
        <div
          className="accordion-item__content"
          style={{
            display: status === 'exited' ? 'none' : undefined,
            ...transitionStyle,
          }}
        >
          <div {...panelProps} ref={panelRef} className="accordion-item__panel">
            {children}
          </div>
        </div>
      )}
    </div>
  )
}
