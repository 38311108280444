import React from 'react'
import { Formik, Form, Field, FormikHelpers } from 'formik'

import { User } from '../../../types'
import { MailToGridlyLink } from '../../MailToGridlyLink'
import { AutoSave, PhoneNumberMaskedInput, TextInput } from '../../formElements'

import { schema } from './schema'
import './MyAccountProfileForm.scss'

type Props = {
  initialValues: Partial<User>
  onSubmit: (values: Partial<User>) => Promise<void>
}

export const MyAccountProfileForm: React.VFC<Props> = (props) => {
  const handleSubmit = async (
    values: Partial<User>,
    helpers: FormikHelpers<Partial<User>>
  ) => {
    try {
      await props.onSubmit(values)
    } catch (e) {
      helpers.resetForm()
    }
  }

  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <Form className="my-account-form" role="form">
        <h6 className="my-account-form__heading">Your Profile</h6>

        <div className="my-account-form__contact-info-fields">
          <Field
            as={TextInput}
            name="first_name"
            label="First Name"
            placeholder="First Name (optional)"
          />

          <Field
            as={TextInput}
            name="last_name"
            label="Last Name"
            placeholder="Last Name (optional)"
          />

          <Field
            as={PhoneNumberMaskedInput}
            name="phone_number"
            label="Phone"
            placeholder="Phone (optional)"
          />
        </div>

        <div className="my-account-form__settings">
          <p className="body-3">
            We occasionally send out product updates, new services, and Gridly
            news. If you wish to opt out of such messages, use the
            &quot;Unsubscribe&quot; link at the bottom of any email.
          </p>

          <p className="body-3 body-3--bold">
            Don&apos;t worry -- we won&apos;t share your information.
          </p>

          <p className="body-3">
            If you&apos;d like to delete your account, please email{' '}
            <MailToGridlyLink
              className="body-3 body-3--bold"
              to="privacy@gogridly.com"
              subject="Account Deletion Request"
            />
          </p>
        </div>

        <AutoSave />
      </Form>
    </Formik>
  )
}
