import React from 'react'

import { Property } from '../../../types'
import { PropertyDataContext } from '../PropertyDataContext'

export const defaultPropertyDataContextState: Property = {
  street_number: null,
  street_name: null,
  city: null,
  state: null,
  postal_code: null,
  external_year_built: null,
  year_built: null,
  external_num_full_bathrooms: null,
  num_full_bathrooms: null,
  external_num_partial_bathrooms: null,
  num_partial_bathrooms: null,
  external_num_bedrooms: null,
  num_bedrooms: null,
  num_residents: null,
  external_num_stories: null,
  num_stories: null,
  external_total_sqft: null,
  total_sqft: null,
  external_basement_type: null,
  basement_type: null,
  external_basement_sqft: null,
  basement_sqft: null,
  external_heating_type: null,
  heating_method: null,
  heating_space: null,
  heating_distribution: null,
  external_heating_fuel: null,
  heating_fuel: null,
  external_cooling_fuel: null,
  cooling_fuel: null,
  external_cooling_type: null,
  cooling_method: null,
  cooling_distribution: null,
  external_energy_type: null,
  energy_type: null,
  external_hot_water_fuel: null,
  hot_water_fuel: null,
  external_hot_water_type: null,
  hot_water_type: null,
  smart_thermostat: false,
  solar_panels: false,
  electric_utility_external_id: '',
  electric_utility_vendor: '',
  electric_utility_external_data_source: '',
  electric_utility_annual_expenditure: null,
  electric_utility_annual_usage: null,
  gas_utility_annual_expenditure: null,
  gas_utility_vendor: null,
  gas_utility_annual_usage: null,
  oil_utility_annual_expenditure: null,
  oil_utility_vendor: null,
  oil_utility_annual_usage: null,
  propane_utility_annual_expenditure: null,
  propane_utility_vendor: null,
  propane_utility_annual_usage: null,
  attic_insulation_level: null,
  wall_insulation_level: null,
  basement_insulation_level: null,
  insulation_type: null,
  window_type: null,
  community_solar_customer: false,
  wattbuy_customer: false,
  c_wire: true,
  num_thermostats: null,
  hot_water_tank_size: null,
  back_to_back_showers: null,
  washing_machine_style: null,
  dishwasher: null,
  hot_water_tank_location: null,
  total_utility_percent_renewable: null,
  ceiling_height: '0',
  ducting: false,
  num_rooms: null,
  home_assessment: false,
  roof_condition: null,
  roof_exposure: null,
  solar_kwh_need: null,
}

export const PropertyDataProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [propertyDataHasBeenSet, _setPropertyDataHasBeenSet] =
    React.useState(false)

  const [propertyData, _setPropertyData] = React.useState<Property>(
    defaultPropertyDataContextState
  )

  const setPropertyData = (propertyData: Property) => {
    _setPropertyData(propertyData)
    _setPropertyDataHasBeenSet(true)
  }

  const resetPropertyData = () => {
    _setPropertyData(defaultPropertyDataContextState)
    _setPropertyDataHasBeenSet(false)
  }

  return (
    <PropertyDataContext.Provider
      value={{
        propertyData,
        propertyDataHasBeenSet,
        setPropertyData,
        resetPropertyData,
      }}
    >
      {children}
    </PropertyDataContext.Provider>
  )
}
