import {
  GRIDLY_HOT_WATER_LOCATION_CODES,
  GRIDLY_HOT_WATER_SIZE_CODES,
  GRIDLY_WASHING_MACHINE_STYLE_CODES,
} from '../../../constants'
import { constantToOptions } from '../../../helpers'

export const hotWaterTankLocationOptions = constantToOptions(
  GRIDLY_HOT_WATER_LOCATION_CODES
)

export const hotWaterTankSizeOptions = constantToOptions(
  GRIDLY_HOT_WATER_SIZE_CODES
)

export const washingMachineStyleOptions = constantToOptions(
  GRIDLY_WASHING_MACHINE_STYLE_CODES
)
