import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'

import { ConvertWaterHeaterExplorePageV1 } from './V1'
import { ConvertWaterHeaterExplorePageV2 } from './V2'

export const ConvertWaterHeaterExplorePage: React.FC = () => {
  const { exploreHotWaterHeaterV2 } = useFlags()

  return exploreHotWaterHeaterV2 ? (
    <ConvertWaterHeaterExplorePageV2 />
  ) : (
    <ConvertWaterHeaterExplorePageV1 />
  )
}
