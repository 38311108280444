import { object, string } from 'yup'

const ReadOnlyFields = object({
  street_address: string(),
  city: string().required(),
  state: string().required(),
  postal_code: string().required(),
  email: string().required(),
})

export const RequestForProposalSchema = object({
  street_address_2: string(),
  first_name: string().required(),
  last_name: string().required(),
  phone_number: string(),
}).concat(ReadOnlyFields)
