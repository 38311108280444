import React from 'react'
import classnames from 'classnames'

import { Property } from '../../../types'
import { PropertyDataModel } from '../../../models'
import './AddressSatelliteImage.scss'

interface Props extends React.ComponentPropsWithoutRef<'img'> {
  propertyData: Property
}

export const AddressSatelliteImage: React.FC<Props> = ({
  propertyData,
  ...props
}) => {
  const propertyDataModel = new PropertyDataModel(propertyData)
  const satelliteViewQueryParams = {
    center: propertyDataModel.formattedSingleLineAddress,
    key: env.REACT_APP_GOOGLE_API_KEY,
    size: '600x400',
    maptype: 'satellite',
    zoom: '20',
  }
  const satelliteViewQueryString = new URLSearchParams(
    satelliteViewQueryParams
  ).toString()

  return (
    <img
      alt={`${propertyDataModel.formattedSingleLineAddress} viewed from above`}
      {...props}
      className={classnames('address-satellite-image', props.className)}
      src={`https://maps.googleapis.com/maps/api/staticmap?${satelliteViewQueryString}`}
    />
  )
}
