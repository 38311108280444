import axios from 'axios'
import { User } from '../../types'
import { routes } from '../constants'

export type UpdateUser = (user: User) => Promise<User>

export const updateUser: UpdateUser = async (user: User) => {
  const resp = await axios.patch<User>(routes.currentUser, user)
  return resp.data
}
