import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { Tile } from '../../components'
import { GRIDLY_PROJECT_SLUGS } from '../../constants'
import { ROUTES, PROJECTS_WITH_EXPLORE_PAGES } from '../../routes/constants'
import { useProjectSolution } from '../../api'

import './RequestForProposalConfirmationPage.scss'

export const RequestForProposalConfirmationPage: React.FC = () => {
  const { rfp_id } = useParams()

  const { projectSolution, error, isLoading } = useProjectSolution(
    parseInt(rfp_id || '')
  )
  if (isLoading) {
    return <>Loading...</>
  }

  if (error) {
    return <>Error: {error.message}</>
  }
  const showExploreLink =
    projectSolution.project_slug !== GRIDLY_PROJECT_SLUGS.INSULATION

  return (
    <Tile className="request-for-proposal-confirmation">
      <p>Your proposal and request has been sent to:</p>
      <h3>{projectSolution.vendor.name}</h3>
      <p>A service advisor will contact you shortly.</p>

      <Link
        to={ROUTES.actionPlan}
        aria-label="Return to Your Action Plan"
        className="request-for-proposal-confirmation__action-plan-link"
      >
        Return to Your Action Plan
      </Link>
      {showExploreLink && (
        <p>
          Request another proposal?{' '}
          <Link
            to={
              ROUTES.projectExplorePages[
                projectSolution.project_slug as PROJECTS_WITH_EXPLORE_PAGES
              ]
            }
          >
            Explore more options
          </Link>
        </p>
      )}
    </Tile>
  )
}
