import React from 'react'
import { Field, Form, Formik, ErrorMessage, FormikHelpers } from 'formik'

import { SubmitButton, TextInput } from '../../formElements'
import { signInFormSchema } from './schema'

import './SignInForm.scss'

interface FormValues {
  email: string
}

interface Props {
  initialValues: FormValues
  onSubmit: (values: FormValues) => Promise<void>
}

export const SignInForm: React.FC<Props> = ({ initialValues, onSubmit }) => {
  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) => {
    try {
      await onSubmit(values)
    } catch (e) {
      helpers.resetForm()
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={signInFormSchema}
      validateOnMount
    >
      <Form className="sign-in-form" role="form">
        <Field as={TextInput} name="email" label="Your Email Address" />
        <ErrorMessage
          name="email"
          className="sign-in-form__error-message"
          component="div"
        />

        <SubmitButton className="sign-in-form__submit-button">
          Sign In
        </SubmitButton>
      </Form>
    </Formik>
  )
}
