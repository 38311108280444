import React from 'react'

import { PropertyFormValues } from '../../../types'
import {
  GRIDLY_CARBON_IMPACT_DESCRIPTIONS,
  GRIDLY_PROJECT_SLUGS,
} from '../../../constants'
import { ROUTES } from '../../../routes'
import { formatDollars } from '../../../helpers'
import { useProject, useProperty } from '../../../api'
import { useToast } from '../../../context'
import { Project, SmartThermostatForm } from '../../../components'
import { LinkButton } from '../../../components/LinkButton'

export const SmartThermostatPage: React.VFC = () => {
  const { setSuccessToast, setErrorToast } = useToast()
  const {
    error: errorLoadingProperty,
    isLoading: isLoadingProperty,
    property,
    updateProperty,
  } = useProperty()
  const {
    error: errorLoadingProject,
    isLoading: isLoadingProject,
    project,
  } = useProject(GRIDLY_PROJECT_SLUGS.SMART_THERMOSTAT)

  if (errorLoadingProperty || errorLoadingProject) {
    return (
      <>Error {errorLoadingProject?.message || errorLoadingProject?.message}</>
    )
  }

  if (isLoadingProperty || isLoadingProject) {
    return <>Loading...</>
  }

  const handleSubmit = async (propertyData: PropertyFormValues) => {
    if (property.id) {
      try {
        await updateProperty(property.id, propertyData)

        setSuccessToast()
      } catch (e) {
        setErrorToast()

        throw e
      }
    }
  }

  return (
    <>
      <Project.Header
        title="Add Smart Thermostats"
        recommendationText="Replace your standard thermostat with a smart, networkable app-driven solution."
        whyText="Decrease fuel usage by up to 12%. Increase rebate eligibility for HVAC projects."
      >
        <>
          <Project.Highlight
            description="Reduce your heating/cooling fuel usage by up to 12%.*"
            takeAway="12%"
            takeAwayStyle="solid"
          />

          <Project.Highlight
            description={`Save up to ${formatDollars(
              parseFloat(project.annual_savings ?? '0'),
              { fractionDigits: 0, format: 'longhand' }
            )} in energy costs per year.*`}
            takeAway={formatDollars(parseFloat(project.annual_savings ?? '0'), {
              fractionDigits: 1,
              format: 'shorthand',
            })}
            takeAwayStyle="outline"
          />

          {project.carbon_impact && (
            <Project.Highlight
              description={
                GRIDLY_CARBON_IMPACT_DESCRIPTIONS[project.carbon_impact]
              }
              takeAway={project.carbon_impact}
              takeAwayStyle="outline"
            />
          )}
        </>
      </Project.Header>

      <Project.Sandbox title="We'll help you choose the best programmable smart thermostat.">
        <SmartThermostatForm
          initialValues={property.toFormValues()}
          onSubmit={handleSubmit}
        />
      </Project.Sandbox>

      <Project.Recommendation
        title="Purchase Top-Brand Smart Thermostat"
        propertyIsEligible={property.isEligibleForSmartThermostats}
        project={project}
        exploreLink={
          <LinkButton
            to={
              ROUTES.projectExplorePages[GRIDLY_PROJECT_SLUGS.SMART_THERMOSTAT]
            }
            variant="blue"
          >
            Explore Your Options
          </LinkButton>
        }
      />

      <Project.Education project={project} />
    </>
  )
}
