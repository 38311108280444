import { Project } from "../../types";
import { CLEAN_ENERGY_PROJECT_NAMES } from "./constants";

export const compareTowerOptionType = (project: Project, projectName: string): 'recommended'|'default'|'ineligible' => {
  if (projectName === CLEAN_ENERGY_PROJECT_NAMES[0]) {
    return 'recommended'
  } else if (project.recommended) {
    return 'default'
  } else {
    return 'ineligible'
  }
}
