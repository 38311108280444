import React from 'react'

import gridlyLogo from '../../../images/gridlyLogo.svg'
import gridlyLogoLight from '../../../images/gridlyLogoLight.svg'

interface Props extends React.ComponentPropsWithoutRef<'img'> {
  mode: 'light' | 'dark'
}
export const PrimaryLogo: React.FC<Props> = ({ mode, ...props }) => {
  return (
    <>
      {mode === 'light' && (
        <img {...props} alt="Gridly, Power your way" src={gridlyLogoLight} />
      )}
      {mode === 'dark' && (
        <img {...props} alt="Gridly, Power your way" src={gridlyLogo} />
      )}
    </>
  )
}
