import React from 'react'
import { ComingSoonMap, MaxWidth, PrimaryLogo } from '../../components'

import './ComingSoonPage.scss'

export const ComingSoonPage: React.FC = () => {
  return (
    <MaxWidth wide>
      <div className="coming-soon-page">
        <PrimaryLogo className="coming-soon-page__logo" mode="light" />

        <div className="coming-soon-page__title-text">
          <h2 className="coming-soon-page__text">
            <span className="coming-soon-page__text coming-soon-page__text--green">
              We&apos;ll Be{' '}
            </span>
            Right There
          </h2>

          <p className="coming-soon-page__text">
            Gridly isn&apos;t available in your area. Yet. But we&apos;re
            heading your way.
          </p>

          <p className="coming-soon-page__text">
            In the meantime, you can check the map to see where we are and where
            we&apos;ll be in the very near future.
          </p>
        </div>

        <ComingSoonMap className="coming-soon-page__map" />

        <a className="coming-soon-page__link" href="http://gogridly.com">
          Return to Home Page
        </a>
      </div>
    </MaxWidth>
  )
}
