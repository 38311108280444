import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import classnames from 'classnames'

import { ROUTES } from '../../../routes'
import { PrimaryLogo, WhiteXIcon } from '../../media'
import { HeaderLink } from '../../navElements'
import { Button } from '../../Button'
import { MaxWidth } from '../../MaxWidth'

type Props = { isMenuOpen: boolean; toggleMenu: () => void }
export const MobileMenu: React.FC<Props> = ({ isMenuOpen, toggleMenu }) => {
  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }

    return () => document.body.classList.remove('no-scroll')
  }, [isMenuOpen])

  return createPortal(
    <div
      aria-hidden={!isMenuOpen}
      className={classnames('member-experience-mobile-menu', {
        'member-experience-mobile-menu--open': isMenuOpen,
      })}
    >
      <MaxWidth>
        <div className="member-experience-header member-experience-header--dark">
          <div className="member-experience-header__mobile-content member-experience-header__mobile-content--left">
            <Button variant="transparent" onClick={toggleMenu}>
              <WhiteXIcon />
            </Button>

            <a href="https://gogridly.com" rel="noreferrer" target="_blank">
              <PrimaryLogo
                className="member-experience-header__logo"
                mode="light"
              />
            </a>
          </div>
        </div>

        <div className="member-experience-mobile-menu__content">
          <HeaderLink
            className="member-experience-mobile-menu__link"
            onClick={toggleMenu}
            to={ROUTES.actionPlan}
          >
            Action Plan
          </HeaderLink>

          <HeaderLink
            className="member-experience-mobile-menu__link"
            onClick={toggleMenu}
            to={ROUTES.myAccount}
          >
            My Account
          </HeaderLink>
        </div>
      </MaxWidth>
    </div>,
    document.body
  )
}
