import React from 'react'

import { PropertyFormValues } from '../../types'
import { useProperty } from '../../api'
import { useToast } from '../../context'
import { HomeDetailsForm } from '../../components'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes/constants'

export const EditPropertyBasicsPage: React.VFC = () => {
  const { setErrorToast } = useToast()
  const navigate = useNavigate()
  const { error, isLoading, property, updateProperty } = useProperty()

  if (error) return <>Error: {error}</>
  if (isLoading) return <>Loading...</>

  const onSubmit = async (values: PropertyFormValues) => {
    if (property.id) {
      try {
        await updateProperty(property.id, values, true)

        navigate(ROUTES.myHomeUtilities)
      } catch (e) {
        setErrorToast()

        throw e
      }
    }
  }

  return (
    <HomeDetailsForm
      initialValues={property.toFormValues()}
      onSubmit={onSubmit}
      autoSave={false}
      submitButtonText="Recalculate Energy Usage Estimate"
    />
  )
}
