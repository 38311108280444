import useSWR, { KeyedMutator } from 'swr'

import { ActionPlanModel } from '../../models/ActionPlanModel'
import { ActionPlan } from '../../types'
import { routes } from '../constants'
import { useUser } from '../useUser'

type ActionPlanApiLoading = {
  actionPlan: undefined
  isLoading: true
  error: undefined
  mutateActionPlan: undefined
}

type ActionPlanApiError = {
  actionPlan: undefined
  isLoading: false
  error: Error
  mutateActionPlan: undefined
}

type ActionPlanApiResolved = {
  actionPlan: ActionPlanModel
  isLoading: false
  error: undefined
  mutateActionPlan: KeyedMutator<ActionPlan>
}

type ActionPlanApi =
  | ActionPlanApiLoading
  | ActionPlanApiError
  | ActionPlanApiResolved

export const useActionPlan = (): ActionPlanApi => {
  const { user, error: errorLoadingUser } = useUser()

  const { data, error, mutate } = useSWR<ActionPlan>(
    user?.property_id ? routes.actionPlan(user.property_id) : null
  )

  if (data) {
    return {
      actionPlan: new ActionPlanModel(data),
      isLoading: false,
      error: undefined,
      mutateActionPlan: mutate,
    }
  } else if (error || errorLoadingUser) {
    return {
      actionPlan: undefined,
      isLoading: false,
      error: error || errorLoadingUser,
      mutateActionPlan: undefined,
    }
  } else {
    return {
      actionPlan: undefined,
      isLoading: true,
      error: undefined,
      mutateActionPlan: undefined,
    }
  }
}
