import React from 'react'

import './UtilityUsageFieldset.scss'

interface Props extends React.PropsWithChildren {
  legend: string
  explainer?: string
}

export const UtilityUsageFieldset: React.FC<Props> = ({
  children,
  legend,
  explainer,
}) => {
  return (
    <fieldset className="utility-fieldset">
      <legend className="visually-hidden">{legend}</legend>
      <div className="utility-fieldset__grid">
        <span className="utility-fieldset__legend" aria-hidden>
          {legend}
        </span>
        <span className="utility-fieldset__explainer">
          {explainer && explainer}
        </span>
        {children}
      </div>
    </fieldset>
  )
}
