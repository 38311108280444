import axios from 'axios'
import { Property } from '../../types'
import { routes } from '../constants'


type GetUpdatedPropertyEstimates = (
  property: Property,
  recalculate_expenditures?: boolean
) => Promise<Property>

export const getUpdatedPropertyEstimates: GetUpdatedPropertyEstimates = async (
  property,
  recalculate_expenditures = false,
) => {
  const res = await axios.post<Property>(routes.newProperty, { recalculate_expenditures, ...property })
  return res.data
}
