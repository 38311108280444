import React, { ComponentPropsWithoutRef, useEffect } from 'react'
import { Wrapper, Status } from '@googlemaps/react-wrapper'
import { useFormikContext } from 'formik'

import { Address } from '../../../types'
import { TextInput } from '../TextInput'
import { getAddressFromSelection } from '../../forms/GoogleAddressForm/helpers'
import { API_KEY, PLACES_API_CONFIG } from './constants'
import './GoogleAutocomplete.scss'

interface Props extends ComponentPropsWithoutRef<'input'> {
  label?: string
  name: string
}

const AutocompleteInput: React.FC<Props> = ({
  label = 'Enter your address',
  name,
  value: _value,
  ...props
}) => {
  const ref = React.useRef<HTMLInputElement>(null)
  const mapRef = React.useRef<HTMLDivElement>(null)

  const { setFieldValue, values, validateField } =
    useFormikContext<Record<string, object>>()

  useEffect(() => {
    if (ref.current && typeof google !== 'undefined') {
      const autocomplete = new google.maps.places.Autocomplete(
        ref.current,
        PLACES_API_CONFIG
      )

      const listener = autocomplete.addListener('place_changed', () => {
        // "place_changed" event is fired only when an address is selected from the list, via keyboard(enter key) or mouse.

        const place = autocomplete.getPlace()

        // if a place does not have geometry, it is not a valid address
        if (place.geometry && place.address_components) {
          setFieldValue(name, getAddressFromSelection(place.address_components))
          ref.current?.dispatchEvent(new Event('change', { bubbles: true }))
        }
      })

      if (
        Object.values(values[name]).reduce((acc, v) => acc && Boolean(v), true)
      ) {
        const { street_number, street_name, city, state } = values[
          name
        ] as Address
        const addressString = `${street_number} ${street_name}, ${city}, ${state}`
        const autocompleteService = new google.maps.places.AutocompleteService()
        autocompleteService.getPlacePredictions(
          { input: addressString },
          (autocompleteResult) => {
            if (
              mapRef.current &&
              autocompleteResult &&
              autocompleteResult.length
            ) {
              const placeRequest = { placeId: autocompleteResult[0].place_id }
              const placeService = new google.maps.places.PlacesService(
                mapRef.current
              )
              placeService.getDetails(placeRequest, (detailsResult) => {
                if (
                  detailsResult.geometry &&
                  detailsResult.address_components
                ) {
                  setFieldValue(
                    name,
                    getAddressFromSelection(detailsResult.address_components)
                  )
                  validateField(name)
                  if (ref.current) {
                    ref.current.value = addressString
                  }
                }
              })
            }
          }
        )
      }

      return () => {
        listener.remove()
      }
    }
  }, [ref.current, mapRef.current, typeof google])

  return (
    <>
      <TextInput
        {...props}
        className="google-autocomplete__text-input"
        name={`${name}-address-search`}
        label={label}
        ref={ref}
      />
      <div ref={mapRef}></div>
    </>
  )
}

export const GoogleAutocomplete: React.FC<Props> = (props) => {
  const render = (status: Status) => (
    <AutocompleteInput
      {...props}
      disabled={props.disabled || status !== Status.SUCCESS}
    />
  )

  return <Wrapper apiKey={API_KEY} libraries={['places']} render={render} />
}
