import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { HVACExplorePageV1 } from './V1'
import { HVACExplorePageV2 } from './V2'

export const HVACExplorePage: React.FC = () => {
  const { exploreHvacV2 } = useFlags()

  return exploreHvacV2 ? <HVACExplorePageV2 /> : <HVACExplorePageV1 />
}
