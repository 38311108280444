import React from 'react'

import { GRIDLY_INSULATION_PRODUCT_NAMES } from '../../../../constants'
import { InsulationExplorePageMassSaveHPC } from './InsulationExplorePageMassSaveHPC'
import { InsulationExplorePageNeeeco } from './InsulationExplorePageNeeeco'
import { InsulationExplorePageCapeCod } from './InsulationExplorePageCapeCod'

export const PRODUCT_EXPLORE_TITLES: Record<
  GRIDLY_INSULATION_PRODUCT_NAMES,
  string
> = {
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_NON_MASS_SAVE]:
    'Get a Qualified Home Energy Assessment',
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_MASS_SAVE]:
    'Get a Qualified Home Energy Assessment',
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_NEEECO]:
    'Get a Qualified Home Energy Assessment',
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_CAPE_COD]:
    'Get a Qualified Home Energy Assessment',
  [GRIDLY_INSULATION_PRODUCT_NAMES.WEATHERIZATION]: '',
}

export const PRODUCT_EXPLORE_COMPONENTS: Record<
  GRIDLY_INSULATION_PRODUCT_NAMES,
  React.FC
> = {
  // This route will not be shown because the Explore link goes to an external page
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_NON_MASS_SAVE]: () => <></>,
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_MASS_SAVE]:
    InsulationExplorePageMassSaveHPC,
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_NEEECO]:
    InsulationExplorePageNeeeco,
  [GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_CAPE_COD]:
    InsulationExplorePageCapeCod,
  // This route will not be shown because the Explore link is not displayed
  [GRIDLY_INSULATION_PRODUCT_NAMES.WEATHERIZATION]: () => <></>,
}

export const PRODUCTS_WITHOUT_EXPLORE_COMPONENT = [
  GRIDLY_INSULATION_PRODUCT_NAMES.HOME_INSPECTION_NON_MASS_SAVE,
  GRIDLY_INSULATION_PRODUCT_NAMES.WEATHERIZATION,
]
