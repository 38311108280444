import React from 'react'
import classnames from 'classnames'

import './Highlight.scss'

type HighlightProps = {
  description: string
  takeAway: string | React.ReactNode
  takeAwayStyle?: 'solid' | 'outline' | 'none'
}

export const Highlight: React.VFC<HighlightProps> = ({
  takeAway,
  description,
  takeAwayStyle = 'none',
}) => (
  <div className="project-highlight">
    <div
      className={classnames([
        'project-highlight__takeaway',
        {
          'project-highlight__takeaway--solid': takeAwayStyle === 'solid',
          'project-highlight__takeaway--outline': takeAwayStyle === 'outline',
        },
      ])}
    >
      {takeAway}
    </div>

    <div className="project-highlight__description">{description}</div>
  </div>
)
