import { GRIDLY_PROJECT_SLUGS } from '../constants'

export const EXTERNAL_ROUTES = {
  home: 'https://www.gogridly.com',
}

export const ROUTES = {
  root: '/',
  homeDetails: '/home-details',
  utilityUsage: '/home-details/utility-usage',
  requestActionPlan: '/home-details/request-action-plan',
  actionPlanRequestConfirmation: '/action-plan-request-confirmation',
  actionPlan: '/action-plan',
  projects: {
    index: '/projects',
    [GRIDLY_PROJECT_SLUGS.COMMUNITY_SOLAR]: '/projects/community-solar',
    [GRIDLY_PROJECT_SLUGS.SMART_THERMOSTAT]: '/projects/smart-thermostat',
    [GRIDLY_PROJECT_SLUGS.CONVERT_WATER_HEATER]:
      '/projects/convert-water-heater',
    [GRIDLY_PROJECT_SLUGS.RENEWABLE_ENERGY]: '/projects/renewable-energy',
    [GRIDLY_PROJECT_SLUGS.INSULATION]: '/projects/insulation',
    [GRIDLY_PROJECT_SLUGS.SOLAR_PANELS]: '/projects/solar-panels',
    [GRIDLY_PROJECT_SLUGS.WHOLE_HOME_MONITORING]: '/projects/home-monitoring',
    [GRIDLY_PROJECT_SLUGS.HVAC]: '/projects/hvac',
  },
  projectExplorePages: {
    [GRIDLY_PROJECT_SLUGS.RENEWABLE_ENERGY]:
      '/projects/renewable-energy/explore',
    [GRIDLY_PROJECT_SLUGS.SMART_THERMOSTAT]:
      '/projects/smart-thermostat/explore',
    [GRIDLY_PROJECT_SLUGS.HVAC]: '/projects/hvac/explore',
    [GRIDLY_PROJECT_SLUGS.CONVERT_WATER_HEATER]:
      '/projects/convert-water-heater/explore',
    [GRIDLY_PROJECT_SLUGS.SOLAR_PANELS]: '/projects/solar-panels/explore',
    [GRIDLY_PROJECT_SLUGS.INSULATION]: '/projects/insulation/explore',
    [GRIDLY_PROJECT_SLUGS.COMMUNITY_SOLAR]: `/projects/community-solar/explore`,
  },
  requestForProposal: (solution_id = ':solution_id') =>
    `/solution/${solution_id}/request-for-proposal`,
  requestForProposalConfirmation: (rfp_id = ':rfp_id') =>
    `/request-for-proposal/${rfp_id}`,
  compareCleanEnergy: '/compare-clean-energy',
  myHome: '/my-home',
  myHomeUtilities: '/my-home/utilities',
  myAccount: '/my-account',
  signIn: '/sign-in',
  comingSoon: '/coming-soon',
  terms: '/terms',
  privacyPolicy: '/terms/privacy-policy',
}

export type PROJECTS_WITH_EXPLORE_PAGES =
  keyof typeof ROUTES.projectExplorePages
