import classnames from 'classnames'
import React from 'react'

import { Project } from '../../types'
import { ProjectTileLink } from '../ProjectTileLink'

import './ProjectTileLinkGroup.scss'

type Props = {
  className?: string
  projects: Project[]
}

export const ProjectTileLinkGroup: React.VFC<Props> = ({
  className,
  projects,
}) => (
  <li className={classnames('project-tile-link-group', className)}>
    <ul className="project-tile-link-group__projects">
      {projects.map((project) => (
        <ProjectTileLink
          key={project.name}
          className="project-tile-link-group__project-tile"
          project={project}
        />
      ))}
    </ul>
  </li>
)
